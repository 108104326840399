/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
import React, { useState, useEffect, useRef } from 'react';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  message,
  InputNumber,
  Select,
  Modal,
  Tooltip,
  Input,
  Row,
  Col,
  Divider,
  Form,
} from 'antd';
import chandas from '../../utils/chandas';
import {
  formVariables,
  requiredMessages,
  validateMemberCode,
} from '../../utils/form';
import jamaatList from '../../utils/jamaat';
import { formatNumber } from '../../utils/formats';

const { Option } = Select;
const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 14 },
  lg: { span: 12 },
  xl: { span: 10 },
  xxl: { span: 8 },
};
const chandasStyle = {
  style: {
    padding: '0px 12px',
  },
};
const chandasLabelCol = {
  xs: { span: 24 },
  style: {
    textAlign: 'center',
    whiteSpace: 'inherit',
    textOverflow: 'ellipsis',
  },
};
const chandasWrapperCol = {
  xs: { span: 24 },
  style: { textAlign: 'center' },
};

/**
 * - Returns the whole donations made for all chandas by all chanda donors
 *
 * @param {Object} formValues Object containing the form properties and values
 *
 * @return {number} Total donations made for all chandas by all chanda donors
 */
const getTotalAmount = (formValues) => {
  let totalAmount = 0;
  for (let i = 0; i < Object.keys(formValues).length; i++) {
    const charity = Object.keys(formValues)[i];
    const isChanda = chandas.find((x) => charity.includes(x.name));
    if (formValues[charity] * 1 > 0 && isChanda) {
      totalAmount += formValues[charity];
    }
  }

  return Math.round(totalAmount * 100) / 100;
};

/**
 * - orders the automatic form output values to ease a better verbose
 *
 * @param {Object} formValues Object containing the form properties and values
 *
 * @return {Object} Ordered form output
 */
const getDonorsDetails_asArray = (formValues) => {
  const donors = [];
  const detailedDonors = {
    payer_0: {},
    payer_1: {},
    payer_2: {},
    payer_3: {},
    payer_4: {},
    payer_5: {},
    payer_6: {},
    payer_7: {},
    payer_8: {},
    payer_9: {},
  };
  for (let i = 0; i < Object.keys(formValues).length; i++) {
    const key = Object.keys(formValues)[i];
    if (formValues[key] && key !== formVariables.comment.property) {
      const chandaPayerIndex = key.slice(-1);
      detailedDonors[`payer_${chandaPayerIndex}`][key.slice(0, -2)] =
        formValues[key];
    }
  }

  for (let i = 0; i < Object.keys(detailedDonors).length; i++) {
    const key = Object.keys(detailedDonors)[i];
    if (Object.keys(detailedDonors[key]).length > 0) {
      donors.push(detailedDonors[key]);
    }
  }

  return donors;
};

const validateEachChandaPayerDonation = (chandaPayersArray) => {
  // Here, we need to check if the donor has entered at least one chanda.
  for (let i = 0; i < chandaPayersArray.length; i++) {
    // Each element is an object which properties are:
    // 'payer_name', 'jamaat', 'member_code', and all the chanda properties
    // We will check if, besides the first three, there are some amounts entered;
    const otherChandasSelected = Object.keys(chandaPayersArray[i]).filter(
      (x) => ['payer_name', 'jamaat', 'member_code'].indexOf(x) === -1,
    );
    let amount = 0;
    for (let j = 0; j < otherChandasSelected.length; j++) {
      const chandaName = otherChandasSelected[j];
      if (chandaPayersArray[i][chandaName] > 0) {
        amount += chandaPayersArray[i][chandaName];
      }
    }
    if (amount === 0) {
      return false;
    }
  }
  return true;
};

const MIN_DONATION = 1;

function ChandasForm({ handleSubmit, chandas_form }) {
  const [chandaPayers, setChandaPayers] = useState([
    { dateAdded: Date.now(), otherCharities: [] },
  ]);
  const [form] = Form.useForm();
  const inputsRef = useRef([]);

  useEffect(() => {
    if (
      chandas_form.chandaPayersState &&
      chandas_form.chandaPayersState.length > 0
    ) {
      setChandaPayers(chandas_form.chandaPayersState);
    }
    return () => {};
  }, [chandas_form]);

  const handleCreate = (newChandaPayersState) => {
    form
      .validateFields()
      .then((values) => {
        const formValuesObj = {
          // Main Chanda Donor (Chanda Donor 1)
          payer_name: values.payer_name_0,
          jamaat: values.jamaat_0,
          member_code: values.member_code_0,
          comment: values.comment,
          // Total donations
          // Donors details (information and donations per chanda)
          all_chanda_payers: getDonorsDetails_asArray(values),
        };

        const totalAmount = getTotalAmount(values);

        console.log('Ordered values of form:', formValuesObj);

        if (totalAmount > 0) {
          if (MIN_DONATION > 0 && totalAmount < MIN_DONATION) {
            message.destroy();
            message.error(
              `The minimum total donation amount is US$ ${MIN_DONATION}`,
            );
            return;
          }
          if (totalAmount > 75000) {
            message.destroy();
            message.error(
              'The maximum total donation amount is US$ 75,000.00 ',
            );
            return;
          }
          if (
            !validateEachChandaPayerDonation(formValuesObj.all_chanda_payers)
          ) {
            message.destroy();
            message.error(
              'At least one chanda needs to be entered for each donor.',
            );
            return;
          }
          handleSubmit(
            formValuesObj,
            totalAmount,
            values,
            newChandaPayersState,
          );
        } else {
          message.destroy();
          message.error('You have not entered any donation');
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const getFields = (payerIndex) => {
    const children = [];
    for (let i = 0; i < chandaPayers[payerIndex].otherCharities.length; i++) {
      const otherCharity = chandas.find(
        (x) => x.name === chandaPayers[payerIndex].otherCharities[i].charity,
      );
      children.push(
        <Col
          xlg={4}
          lg={6}
          md={8}
          sm={24}
          xs={24}
          key={i}
          style={{ textAlign: 'center', display: 'block' }}
        >
          <Form.Item
            style={chandasStyle}
            labelCol={chandasLabelCol}
            wrapperCol={chandasWrapperCol}
            colon={false}
            label={
              <span>
                {otherCharity.label}{' '}
                <Tooltip title={otherCharity.description}>
                  <InfoCircleOutlined style={{ color: '#8a8888' }} />
                </Tooltip>
              </span>
            }
            name={`${otherCharity.name}_${payerIndex}`}
            initialValue={chandas_form[`${otherCharity.name}_${payerIndex}`]}
            extra={otherCharity.special_warning}
            rules={
              otherCharity.custom_validator
                ? [
                    {
                      validator: (rules, values, callback) =>
                        callback(otherCharity.custom_validator(values)),
                    },
                  ]
                : []
            }
          >
            <InputNumber
              ref={(node) =>
                (inputsRef.current[`${otherCharity.name}_${payerIndex}`] = node)
              }
              autoComplete="off"
              min={0}
              step={1}
            />
          </Form.Item>
        </Col>,
      );
    }
    return children;
  };

  const addNewCharity = (charityName, payerIndex) => {
    const newChandaPayers = [].concat(chandaPayers);
    newChandaPayers[payerIndex].otherCharities.push({
      charity: charityName,
    });
    setChandaPayers(newChandaPayers);
    setTimeout(() => {
      try {
        inputsRef.current[`${charityName}_${payerIndex}`].focus();
      } catch (error) {
        console.log('focus to new input failed');
      }
    }, 300);
  };

  const handleAddChandaPayer = () => {
    if (chandaPayers.length === 10) {
      message.destroy();
      message.error('Maximum of 10 donors per transaction');
      return;
    }
    const newChandaPayers = [].concat(chandaPayers);
    newChandaPayers.push({
      dateAdded: Date.now(), // Just to prevent mapping arrays with index;
      otherCharities: [],
    });
    setChandaPayers(newChandaPayers);
  };

  const handleRemoveChandaPayer = (payerIndex) => {
    Modal.confirm({
      title: 'Do you want to delete this donor?',
      content: 'All the information of this donor will be lost.',
      okText: 'Yes, delete this donor',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        setChandaPayers(chandaPayers.filter((x, i) => i !== payerIndex));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const disabledSpecialCharities = (currentCharity, payerIndex) => {
    if (currentCharity === `wasiyyat_${payerIndex}`) {
      const aam_value = form.getFieldValue(`aam_${payerIndex}`);
      return aam_value * 1 > 0 && aam_value !== null;
    }
    if (currentCharity === `aam_${payerIndex}`) {
      const wasiyyat_value = form.getFieldValue(`wasiyyat_${payerIndex}`);
      return wasiyyat_value * 1 > 0 && wasiyyat_value !== null;
    }
    return false;
  };

  const removePayer = (payerIndex) => (
    <div style={{ height: 0, display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        type="text"
        danger
        onClick={() => handleRemoveChandaPayer(payerIndex)}
        style={{
          cursor: 'pointer',
          top: -10,
          right: -10,
        }}
      >
        Delete donor <DeleteOutlined style={{ color: '#ff4d4f' }} />
      </Button>
    </div>
  );

  return (
    <Form form={form} className="chandas-form" scrollToFirstError>
      {chandaPayers.map((chandaPayer, payerIndex) => (
        <div key={chandaPayer.dateAdded}>
          <Row
            style={{
              padding: 24,
              background: '#fbfbfb',
              border: '1px solid #d9d9d9',
              borderRadius: '6px',
              marginBottom: 12,
            }}
          >
            <Col xs={24}>
              {payerIndex === 0 ? null : removePayer(payerIndex)}
              <h3 style={{ textAlign: 'center' }}>
                {`Chanda Donor ${payerIndex + 1}`}
              </h3>
              <Row
                type="flex"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Col lg={14} md={18} sm={22} xs={24}>
                  <Form.Item
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    label={formVariables.payer_name.label}
                    name={`${formVariables.payer_name.property}_${payerIndex}`}
                    initialValue={
                      chandas_form[
                        `${formVariables.payer_name.property}_${payerIndex}`
                      ]
                    }
                    rules={[
                      {
                        required: true,
                        message: requiredMessages(
                          formVariables.payer_name.property,
                        ),
                      },
                      { max: 64, message: 'Maximum characters allowed are 64' },
                    ]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                  {payerIndex === 0 && (
                    <Form.Item
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                      label={formVariables.jamaat.label}
                      name={`${formVariables.jamaat.property}_${payerIndex}`}
                      extra={
                        chandaPayers.length > 1 ? 'Applies to all donors' : ''
                      }
                      initialValue={
                        chandas_form[
                          `${formVariables.jamaat.property}_${payerIndex}`
                        ]
                      }
                      rules={[
                        {
                          required: true,
                          message: requiredMessages(
                            formVariables.jamaat.property,
                          ),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        autoComplete="none"
                        optionFilterProp="children"
                        showAction={['focus', 'click']}
                      >
                        {jamaatList.map((x) => (
                          <Option key={x.value} value={x.value}>
                            {x.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    label={formVariables.member_code.label}
                    name={`${formVariables.member_code.property}_${payerIndex}`}
                    initialValue={
                      chandas_form[
                        `${formVariables.member_code.property}_${payerIndex}`
                      ]
                    }
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        required: true,
                        message: requiredMessages(
                          formVariables.member_code.property,
                        ),
                      },
                      {
                        validator: (rules, values, callback) =>
                          callback(validateMemberCode(values)),
                      },
                    ]}
                  >
                    <InputNumber
                      className="input-number-no-stepper"
                      autoComplete="off"
                      style={{ width: 90 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider />
            <Row type="flex" justify="center" style={{ width: '100%' }}>
              {chandas
                .filter((x) => x.default)
                .map((x) => (
                  <Col
                    xlg={4}
                    lg={6}
                    md={8}
                    sm={24}
                    xs={24}
                    key={x.name}
                    style={{ textAlign: 'center' }}
                  >
                    <Form.Item noStyle shouldUpdate>
                      {() => (
                        <Form.Item
                          style={chandasStyle}
                          labelCol={chandasLabelCol}
                          wrapperCol={chandasWrapperCol}
                          key={x.name}
                          name={`${x.name}_${payerIndex}`}
                          rules={[{ required: false }]}
                          colon={false}
                          initialValue={chandas_form[`${x.name}_${payerIndex}`]}
                          label={
                            <span>
                              {x.label}{' '}
                              <Tooltip title={x.description}>
                                <InfoCircleOutlined
                                  style={{ color: '#8a8888' }}
                                />
                              </Tooltip>
                            </span>
                          }
                          shouldUpdate
                        >
                          <InputNumber
                            autoComplete="off"
                            min={0}
                            step={1}
                            disabled={disabledSpecialCharities(
                              `${x.name}_${payerIndex}`,
                              payerIndex,
                            )}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                ))}
              {getFields(payerIndex)}
            </Row>
            {chandas.filter((x) => {
              const payerOtherCharities = chandaPayers[
                payerIndex
              ].otherCharities.find((y) => y.charity === x.name);
              if (!x.default && !payerOtherCharities) {
                return true;
              }
              return false;
            }).length > 0 && (
              <Col xs={24} style={{ textAlign: 'center' }}>
                <Select
                  style={{ width: 250 }}
                  onChange={(charityName) =>
                    addNewCharity(charityName, payerIndex)
                  }
                  placeholder={
                    <div align="center">
                      <PlusOutlined style={{ color: '#8a8888' }} /> Pick other
                      chanda
                    </div>
                  }
                  value={null}
                  showSearch
                  showArrow={false}
                  optionFilterProp="children"
                >
                  {chandas
                    .filter((x) => {
                      const payerOtherCharities = chandaPayers[
                        payerIndex
                      ].otherCharities.find((y) => y.charity === x.name);
                      if (!x.default && !payerOtherCharities) {
                        return true;
                      }
                      return false;
                    })
                    .map((x) => (
                      <Option key={x.name} value={x.name}>
                        {x.label}
                      </Option>
                    ))}
                </Select>
              </Col>
            )}
          </Row>
        </div>
      ))}
      <div align="center">
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={handleAddChandaPayer}
        >
          Add another chanda donor
        </Button>
      </div>
      <Row justify="center" style={{ paddingTop: 24 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={8}>
          <Form.Item
            labelCol={chandasLabelCol}
            wrapperCol={chandasWrapperCol}
            label="Leave a comment (optional)"
            name={formVariables.comment.property}
            initialValue={chandas_form[formVariables.comment.property]}
            rules={[
              { max: 150, message: 'Maximum characters allowed are 150' },
            ]}
            extra={
              <Form.Item
                noStyle
                shouldUpdate={(prev, cur) =>
                  prev[formVariables.comment.property] !==
                  cur[formVariables.comment.property]
                }
              >
                {() => {
                  const commentText = form.getFieldValue(
                    formVariables.comment.property,
                  );
                  return (
                    <div align="end">
                      {`${commentText ? commentText.length : 0} / 150`}
                    </div>
                  );
                }}
              </Form.Item>
            }
          >
            <Input.TextArea
              maxLength={150}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ padding: '12px 24px 0px', textAlign: 'right' }}>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              <div>Total donations:</div>
              <div
                style={{ padding: '0px 4px', fontWeight: 'bold', fontSize: 16 }}
              >
                {`US$ ${formatNumber(
                  Number(getTotalAmount(form.getFieldsValue())).toFixed(2),
                )}`}
              </div>
            </>
          )}
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          onClick={() => handleCreate(chandaPayers)}
        >
          Next
        </Button>
      </div>
    </Form>
  );
}

export default ChandasForm;
