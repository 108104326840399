import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import transaction_rates, {
  getTransactionFee,
} from '../utils/transaction_rates';

export default function PaymentCostModal({ paymentType, totalAmount }) {
  const [visible, setVisible] = useState(false);

  const showPaymentTypeInfo = () => {
    setVisible(true);
  };

  const closePaymentTypeInfo = () => {
    setVisible(false);
  };

  const payment_type_label = {
    ach: 'Bank Account (ACH)',
    credit: 'Credit Card',
    debit: 'Debit Card',
  }[paymentType];
  return (
    <>
      <Button
        onClick={showPaymentTypeInfo}
        type="link"
        style={{ padding: 0 }}
        size="small"
      >
        See how much each method costs
      </Button>
      <Modal
        title="Cost of accepting online donations"
        visible={visible}
        onOk={showPaymentTypeInfo}
        onCancel={closePaymentTypeInfo}
        centered
        footer={[
          <Button key="close" type="primary" onClick={closePaymentTypeInfo}>
            Ok
          </Button>,
        ]}
      >
        <div>
          {payment_type_label && (
            <h4>
              Your payment method selection:
              {payment_type_label}
            </h4>
          )}
          The current rates are:
          <ul style={{ paddingTop: 14 }}>
            <li>
              <strong>{'Bank Account (ACH): '}</strong>
              {transaction_rates.ach.description}
            </li>
            <li>
              <strong>{'Debit Card: '}</strong>
              {transaction_rates.debit.description}
            </li>
            <li>
              <strong>{'Credit Card: '}</strong>
              {transaction_rates.credit.description}
            </li>
          </ul>
          <span>{`For your current US$ ${totalAmount} donation,`}</span>
          <span>
            it will cost the following according to the payment type selection:
          </span>
          <ul style={{ paddingTop: 14 }}>
            <li>
              <strong>{'Bank Account (ACH): US$ '}</strong>
              {getTransactionFee(totalAmount, 'ach')}
            </li>
            <li>
              <strong>{'Debit Card: US$ '}</strong>
              {getTransactionFee(totalAmount, 'debit')}
            </li>
            <li>
              <strong>Credit Card: Varies</strong>
              <ul>
                <li>
                  Master Card: US$
                  {getTransactionFee(totalAmount, 'credit', 'mastercard')}
                </li>
                <li>
                  Discover: US$
                  {getTransactionFee(totalAmount, 'credit', 'discover')}
                </li>
                <li>
                  AMEX: US$
                  {getTransactionFee(totalAmount, 'credit', 'amex')}
                </li>
                <li>
                  Visa: US$
                  {getTransactionFee(totalAmount, 'credit', 'visa')}
                </li>
              </ul>
            </li>
          </ul>
          {/* eslint-disable max-len */}
          Also, please note that even giving money by cash or check is not free
          because a physical receipt has to be issued and it takes time and
          effort to enter, deposit and reconcile all cash/check payments. So,{' '}
          <strong>Bank Transfer (ACH)</strong> is generally the cheapest way to
          pay unless you are paying a very large amount.
          <br />
          <br />
          The calculations outside for the fee will also change accordingly.
        </div>
      </Modal>
    </>
  );
}
