import React, { useState } from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Resources from '../../utils/resources';
import routes from '../../routes';
import './ResourceDisplay.css';

function ResourceDisplay() {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { resource_id } = useParams();

  const resource = [
    ...Resources.holy_quran,
    ...Resources.articles,
    ...Resources.videos,
    ...Resources.howToVideos,
  ].find((x) => x.key === resource_id);

  if (!resource) {
    return <Redirect to={routes.resources} />;
  }

  return (
    <div className="resource_main">
      <div className="resource_back">
        <Button
          onClick={() => history.push(routes.resources)}
          type="link"
          style={{ padding: 0 }}
        >
          Go back
        </Button>
      </div>
      <h1>{resource.title}</h1>
      <h3>{resource.subtitle}</h3>
      {resource.article && resource.content}
      {resource.video && (
        <div className="resource_video">
          {loading && (
            <div>
              <LoadingOutlined style={{ fontSize: 32 }} />
            </div>
          )}
          <iframe
            title={resource.title}
            width="700"
            height="385"
            src={resource.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onLoad={() => setLoading(false)}
            style={{ visibility: loading ? 'hidden' : 'visible' }}
          />
        </div>
      )}
    </div>
  );
}

export default ResourceDisplay;
