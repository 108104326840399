/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import mainLogo from '../../assets/images/main_logo.png';
import styles from './WelcomeModal.module.css';

const WelcomeModal = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const welcome = window.localStorage.getItem('welcome');
      if (!welcome) {
        setVisible(true);
      }
    }, 500);
    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  const handleDismiss = () => {
    window.localStorage.setItem('welcome', 1);
    setVisible(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      title=""
      footer={null}
      closable={false}
      maskClosable={false}
      style={{ paddingBottom: 0 }}
    >
      <div className={styles.welcomeModalWrapper}>
        <div className={styles.title}>
          بِسۡمِ اللّٰہِ الرَّحۡمٰنِ الرَّحِیۡمِِ
        </div>
        <img
          src={mainLogo}
          className={styles.img}
          alt="Ahmadiyya Movement In Islam"
        />
        <div className={styles.subtitle}>New Finance Portal</div>
        <div className={styles.body}>
          With the sheer Grace and Mercy of Allah Ta'ala, and prayers of Hadhrat
          Khalifa tul Masih ایدہ اللہ تعالیٰ بنصرہ العزیز Finance Department has
          launched its new donation portal on this blessed day of Friday. While
          we believe the site is self-explanatory, you can watch a quick
          tutorial to familiarize yourself with this new site (
          <a
            href="https://youtu.be/L4YtXC-nmYQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            View tutorial
          </a>
          ).
          <br />
          <br />
          Please feel free to give us feedback from the "<em>Contact Us</em>"
          section of the portal. With humble request for prayers.
        </div>
        <div className={styles.action_button}>
          <Button className={styles.button} type="link" onClick={handleDismiss}>
            Dismiss
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
