/* eslint-env browser */
import React, { Component } from 'react';
import { CreditCardOutlined, UserOutlined } from '@ant-design/icons';
import { Steps, Button, Modal } from 'antd';
import { Link, Prompt } from 'react-router-dom';
import Media from 'react-media';
import ChandasForm from './ChandasForm';
import RecurrenceForm from './RecurrenceForm';
import IdentificationForm from './IdentificationForm';
import { payChanda, verifyMember } from '../../services/actions';
import LoansForm from './LoansForm';
import SubscriptionsAndOrders from './SubscriptionsAndOrdersForm';
import getPayMainRequestBody from '../../utils/request_object';
import ReviewPayment from './ReviewPayment';
import { getForteOneTimeToken } from '../../services/forte';
import Receipt from '../../components/Payments/Receipt';
import TJForm from './TJForm';
import routes from '../../routes';
import CopyToClipboard from '../../components/CopyToClipboard';

const { Step } = Steps;

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    paddingBottom: 25,
    minHeight: '70vh',
  },
};

const unmountMessage =
  'Do you want to exit the donation process?\nAll the entered info will be lost.';

let testState;
if (process.env.NODE_ENV !== 'production') {
  testState = {
    current: 0, // Chanda and Loans: 0, 1, 2, 3; Subscriptions and Orders: 0, 1, 2
    chandaPayers: {
      payer_name: 'Juan Test Castro',
      jamaat: 'BOS',
      member_code: 49994,
      comment:
        'This is a default comment. Please deliver me to the correct endpoint.',
      all_chanda_payers: [
        {
          payer_name: 'Juan Test Castro',
          jamaat: 'BOS',
          member_code: 49994,
          zakat: 100,
          aam: 50,
        },
      ],
    },
    subscriptionsAndOrders: {
      payer_name: 'Juan Test Castro',
      jamaat: 'BOS',
      member_code: 49994,
      comment:
        'This is a default comment. Please deliver me to the correct endpoint.',
      category: 'subscriptions',
      frequency: 1,
      subscriptions: {
        al_fazl_international: true,
      },
      orders: {
        homeopathic_medicine: 20,
      },
    },
    loans: {
      payer_name: 'Juan Test Castro',
      jamaat: 'BOS',
      member_code: 49994,
      comment:
        'This is a default comment. Please deliver me to the correct endpoint.',
      loans: {
        qarza_hasana_wakalat_mal: 2000,
      },
    },
    relatives_form: {
      payer_name: 'Juan Test Castro',
      jamaat: 'BOS',
      member_code: 49994,
      pin: '1234',
      comment:
        'This is a default comment. Please deliver me to the correct endpoint.',
      relatives: [],
    },
    totalAmount: 150,
    recurrence: {
      payment_recurrence: 'oneTime',
    },
    identification: {
      name: 'Juan Test Castro',
      firstName: 'Juan Test',
      lastName: 'Castro',
      email: 'ja.castrocabrera@gmail.com',
      address: '1 Main Street',
      city: 'Boston',
      state: 'MA',
      zipCode: '02215',
      phone: '6109090914',
      payment_type: 'credit', // "ach", "debit" or "credit"
      bank_account_type: 'c',
      bank_account_number: '1111111111111',
      bank_account_number_2: '1111111111111',
      routing_number: '056008849',
      // Failing routing number: account not approved
      // bank_account_number: '987654321',
      // bank_account_number_2: '987654321',
      // routing_number: '021000021',
      debit_card_number: '4242424242424242',
      credit_card_number: '4242424242424242',
      expiry_date: '09/2024',
      cvv: '123',
      card_gateway_brand: 'visa',
      update_jamaat_address: true,
      apply_address_to_family: false,
      asume_processing_fee: false,
    },
    chandas_form: {
      chandaPayersState: [
        {
          dateAdded: 1629504700354,
          otherCharities: [],
        },
      ],
      payer_name_0: 'Juan Test Castro',
      jamaat_0: 'BOS',
      member_code_0: 49994,
      fitrana_0: 200,
      comment:
        'This is a default comment. Please deliver me to the correct endpoint.',
    },
    confirmationVisible: false,
    transaction_errors: [],
    avoidResubmit: false,
    response: null,
  };
}

// eslint-disable-next-line no-underscore-dangle
const _initialState = {
  current: 0, // Chanda and Loans: 0, 1, 2, 3; Subscriptions and Orders: 0, 1, 2
  chandaPayers: {
    payer_name: '',
    jamaat: '',
    member_code: '',
    comment: '',
    all_chanda_payers: [],
  },
  subscriptionsAndOrders: {
    payer_name: '',
    jamaat: '',
    member_code: '',
    comment: '',
    category: 'subscriptions',
    frequency: 1,
    subscriptions: {},
    orders: {},
  },
  loans: {
    payer_name: '',
    jamaat: '',
    member_code: '',
    comment: '',
    loans: {},
  },
  relatives_form: {
    payer_name: '',
    jamaat: '',
    member_code: '',
    pin: '',
    comment: '',
    relatives: [],
  },
  totalAmount: 0,
  recurrence: {
    payment_recurrence: undefined, // "oneTime" or "recurring"
    payment_recurrence_expires: undefined,
    payment_recurrence_startDate: undefined,
    payment_recurrence_endDate: undefined,
    payment_recurrence_frequency: undefined,
  },
  identification: {
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    payment_type: 'ach', // "ach", "debit" or "credit"
    bank_account_type: 'c',
    bank_account_number: '',
    bank_account_number_2: '',
    routing_number: '',
    debit_card_number: '',
    credit_card_number: '',
    expiry_date: '',
    cvv: '',
    card_gateway_brand: '',
    update_jamaat_address: true,
    apply_address_to_family: false,
    asume_processing_fee: false,
  },
  chandas_form: {},
  confirmationVisible: false,
  transaction_errors: [],
  response: null,
};

const fastTest = process.env.NODE_ENV === 'development'; // Fast testing purpose
let initialState = _initialState;
if (fastTest) {
  initialState = testState;
}

class PayMain extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.scrollToTop();
  }

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    const { current } = this.state;
    const { onChanda, onLoans, onSubscriptionsAndOrders, onTJ } = this.props;
    if (prevState.current !== current) {
      return 'current-step-changed';
    }
    if (
      prevProps.onTJ !== onTJ ||
      prevProps.onLoans !== onLoans ||
      prevProps.onSubscriptionsAndOrders !== onSubscriptionsAndOrders ||
      prevProps.onChanda !== onChanda
    ) {
      return 'route-changed';
    }
    return null;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { current } = this.state;
    if (snapshot === 'current-step-changed') {
      console.log('*****************************');
      console.log(`State of PayMain (step ${current + 1}):`, this.state);
      console.log('*****************************');
      this.scrollToTop();
    }
    if (snapshot === 'route-changed') {
      this.clearData();
    }
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.props.onSubscriptionsAndOrders) {
        if (current > 0 && current < 2) {
          return unmountMessage;
        }
      } else if (current > 0 && current < 3) {
        return unmountMessage;
      }
    };
  }

  /**
   *  - stores the selected charities with their amount the user wants to donate.
   *  - stores the total amount of donations in USD
   *  - saves the ChandasForm.js state for checkpoint purposes
   *
   * @param {Object} chandaPayers - Object containing the following properties:
   * @property {string} payer_name - Name of the main donor
   * @property {string} jamaat - Jama'at of the main donor
   * @property {string} member_code - Member's code of the main donor
   * @property {Array} all_chanda_payers - Estructured array containing each chanda donor detailed donation
   * @param {int36} totalAmount - Amount of the total donations
   * @param {Object} chandas_form - Exact values returned from the form validation
   * @param {Array} chandaPayersState - State of ChandasForm.js for checkpoint purposes
   */
  handleChandasForm = (
    chandaPayers,
    totalAmount,
    chandas_form,
    chandaPayersState,
  ) => {
    console.log(chandaPayers, totalAmount, chandas_form, chandaPayersState);
    const arrayOfMembers = [];
    for (let i = 0; i < chandaPayers.all_chanda_payers.length; i++) {
      const chanda_payer = chandaPayers.all_chanda_payers[i];
      arrayOfMembers.push({
        name: chanda_payer.payer_name,
        member_code: chanda_payer.member_code,
        jamaat: chandaPayers.all_chanda_payers[0].jamaat,
      });
    }
    this.verifyMembers(arrayOfMembers, () => {
      this.setState({
        chandaPayers,
        totalAmount,
        current: 1,
        chandas_form: { chandaPayersState, ...chandas_form },
      });
    });
  };

  /**
   *  - stores the selected loans with their amount the user wants to pay.
   *  - stores the total amount of loan payments in USD
   *
   * @param {Object} loans - Amount of the total loans payment
   * @param {int36} totalAmount - Amount of the total payment
   */
  handleLoans = (loans, totalAmount) => {
    console.log(loans, totalAmount);
    const arrayOfMembers = [];
    arrayOfMembers.push({
      name: loans.payer_name,
      member_code: loans.member_code,
      jamaat: loans.jamaat,
    });
    this.verifyMembers(arrayOfMembers, () => {
      this.setState({
        loans,
        totalAmount,
        current: 1,
      });
    });
  };

  /**
   *  - stores the selected payments on behalf of relatives with their amount the user wants to pay.
   *  - stores the total amount of payment in USD
   *
   * @param {Object} relatives_form - Exact values returned from the form validation
   * @param {int36} totalAmount - Amount of the total payment
   */
  handleRelatives = (relatives_form, totalAmount) => {
    console.log('handleRelatives', relatives_form, totalAmount);
    // No need to verify members since it was done at the early stage of the form submission
    this.setState({
      relatives_form,
      totalAmount,
      current: 1,
    });
  };

  /**
   *  - stores the selected subscriptions/orders with their amount the user wants to donate.
   *  - stores the total amount of subscriptions/orders in USD
   *
   * @param {Array} subscriptionsAndOrders - Array of objects containing the following properties:
   * @property {string} payer_name - Name of the main donor
   * @property {string} jamaat - Jama'at of the main donor
   * @property {string} member_code - Member's code of the main donor
   * @param {int36} totalAmount - Amount of the total payment
   */
  handleSubscriptionAndOrders = (subscriptionsAndOrders, totalAmount) => {
    console.log(subscriptionsAndOrders, totalAmount);
    const arrayOfMembers = [];
    arrayOfMembers.push({
      name: subscriptionsAndOrders.payer_name,
      member_code: subscriptionsAndOrders.member_code,
      jamaat: subscriptionsAndOrders.jamaat,
    });
    this.verifyMembers(arrayOfMembers, () => {
      this.setState({
        subscriptionsAndOrders,
        totalAmount,
        current: 1,
      });
    });
  };

  /**
   *  - stores selected recurrence of the payments
   *  - stores the RecurrenceForm.js state for checkpoint purposes
   *
   * @param {string} payment_recurrence - Recurrence ot the payments: "oneTime", "recurrence"
   * @param {boolean} payment_recurrence_expires - Donations until user cancels: true, false
   * @param {Date} payment_recurrence_startDate - Starting date of the donation: Date
   * @param {Date} payment_recurrence_endDate - Expiration date of the donation: Date, undefined
   * @param {string} payment_recurrence_frequency - Recurrence ot the payments:
   * "weekly","bi_weekly","semi_monthly","monthly","quarterly","semi_annually","annually"
   *
   */
  handleRecurrenceForm = (recurrence) => {
    console.log(recurrence);
    this.setState({ recurrence, current: 2 });
  };

  /**
   * Function
   *  -> stores the information about the user that wants to make the payment.
   *
   * @param {object} - object containing the following properties
   * @property {string} name - name of user
   * @property {string} email - email of the user
   * @property {string} address - address of the user
   * @property {string} city - city of the user
   * @property {string} state- state of the user
   * @property {string} zipCode - zipCode of the user
   * @property {string} phone - phone of the user
   * @property {string} payment_type - the user's payment method: "ahc", "credit", "debit"
   * @property {string} bank_account_type - bank account type of the user's payment method
   * @property {string} bank_account_number - bank account number of the user's payment method
   * @property {string} bank_account_number_2 - bank account number again of the user's payment method
   * @property {string} routing_number - routing number of the user's payment method
   * @property {string} debit_card_number - debit card number of the user's payment method
   * @property {string} credit_card_number - credit card number of the user's payment method
   * @property {string} expiry_date - expiry date of the user's payment method
   * @property {string} cvv - cvv of the user's payment method
   * @param {Boolean} onlySave - True if the user wants to just save the form state.
   */
  handleIdentification = (identification_form, onlySave) => {
    console.log(identification_form);
    this.setState({ identification: identification_form }, () => {
      if (!onlySave) {
        this.showPaymentConfirmation();
      }
    });
  };

  /**
   * Saves the ref from the Identification Form
   * @param {Object} formRef Ref from the Identification Form to access the Antd-Form
   */
  saveIdentificationFormRef = (formRef) => {
    this.identificationFormRef = formRef;
  };

  showPaymentConfirmation = () => {
    this.setState({ confirmationVisible: true });
  };

  hidePaymentConfirmation = () => {
    this.setState({ confirmationVisible: false });
  };

  verifyMembers = async (arrayOfMembers, callback) => {
    const validateMember = Modal.info({
      footer: null,
      okButtonProps: { loading: true },
      okText: 'Please wait...',
      maskClosable: false,
      keyboard: false,
      centered: true,
      icon: <UserOutlined />,
      title: 'We are currently validating your information.',
      content: "Please, don't close this site.",
    });
    // Forcing the user to wait 2s. To avoid quickly dismiss
    await new Promise((resolve) => setTimeout(resolve, 1200));
    const proceedPayment = await verifyMember({ arrayOfMembers });
    validateMember.destroy();
    if (proceedPayment) {
      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  /**
   * - Makes the transaction to the server.
   * - If transaction succeeded, user is notified
   * - If transaction fails, errors are shown to the user
   */
  makeDonation = async () => {
    this.hidePaymentConfirmation();
    const { onSubscriptionsAndOrders, onLoans, onTJ } = this.props;
    const {
      chandaPayers,
      subscriptionsAndOrders,
      loans,
      relatives_form,
      totalAmount,
      recurrence,
      identification,
    } = this.state;
    const loadingModal = Modal.info({
      footer: null,
      okButtonProps: { loading: true },
      okText: 'Please wait...',
      maskClosable: false,
      keyboard: false,
      centered: true,
      icon: <CreditCardOutlined />,
      title: 'We are currently processing your transaction.',
      content:
        "This might take around 15 seconds. Please, don't close this site.",
    });

    // One Time Token
    const oneTimeTokenBody = {};
    if (identification.payment_type === 'ach') {
      oneTimeTokenBody.account_type = identification.bank_account_type;
      oneTimeTokenBody.account_number = identification.bank_account_number;
      oneTimeTokenBody.routing_number = identification.routing_number;
      oneTimeTokenBody.sec_code = 'WEB'; // Source: https://www.forte.net/devdocs/api_resources/forte_api_v2.htm#
    } else {
      const [M, Y] = identification.expiry_date.split('/');
      oneTimeTokenBody.expire_year = Y;
      oneTimeTokenBody.expire_month = M;
      oneTimeTokenBody.cvv = identification.cvv;
      if (identification.payment_type === 'debit') {
        oneTimeTokenBody.card_number = identification.debit_card_number
          .split(' ')
          .join('');
      } else {
        oneTimeTokenBody.card_number = identification.credit_card_number
          .split(' ')
          .join('');
      }
    }

    const { forteRes, error, message } = await getForteOneTimeToken(
      oneTimeTokenBody,
    );
    if (error) {
      loadingModal.destroy();
      return Modal.error({ title: message });
    }

    // Cleaning the request body to be send
    const body = getPayMainRequestBody(
      {
        chandaPayers,
        subscriptionsAndOrders,
        loans,
        relatives_form,
        totalAmount,
        recurrence,
        identification: { ...identification, forteRes },
      },
      onSubscriptionsAndOrders,
      onLoans,
      onTJ,
    );
    console.log('PayMain.js | Body', body);

    return payChanda(body)
      .then((res) => {
        console.log(res);
        loadingModal.destroy();
        this.setState((prevState) => ({
          response: res,
          current: prevState.current + 1,
        }));
      })
      .catch((err) => {
        console.log(err);
        loadingModal.destroy();
        setTimeout(() => {
          let avoidResubmit = false;
          const transaction_errors = [];
          if (err && err.error && err.error.message) {
            const [errorDes, referenceID, forteTxID] = (
              err.error.message || ''
            ).split('___');
            if (errorDes === 'DoNotResubmit') {
              avoidResubmit = true;
              transaction_errors.push([
                <span>
                  <CopyToClipboard
                    value={`Reference ID: ${referenceID}\nTransaction ID: ${forteTxID}`}
                    buttonText="Copy information"
                  />
                </span>,
              ]);
              transaction_errors.push([
                <span>
                  Unfortunately we could not complete the transaction. Please
                  send an email with the following information to{' '}
                  <a
                    // eslint-disable-next-line max-len
                    href={`mailto:finance@ahmadiyya.us?subject=Incomplete Transaction | Reference ${referenceID}&body=Need help with my transaction ID ${forteTxID} with reference ID ${referenceID}.`}
                  >
                    finance@ahmadiyya.us
                  </a>
                </span>,
              ]);
              transaction_errors.push([
                <span>
                  Do not submit another donation until this is resolved. If you
                  resubmit it may create a duplicate transaction. We apologize
                  for this inconvenience.
                </span>,
              ]);
              if (referenceID) {
                transaction_errors.push([
                  <span>
                    Reference ID: <strong>{referenceID}</strong>
                  </span>,
                ]);
              }
              if (referenceID) {
                transaction_errors.push([
                  <span>
                    Transaction ID: <strong>{forteTxID}</strong>
                  </span>,
                ]);
              }
            } else {
              transaction_errors.push([
                <span>
                  The specific error code is:
                  {' "'}
                  {err.error.message}
                  ". If this doesn't seem correct, please contact{' '}
                  <a href="mailto:finance@ahmadiyya.us">finance@ahmadiyya.us</a>
                </span>,
              ]);
            }
          } else if (process.env.REACT_APP_BUILD_ENV !== 'production') {
            transaction_errors.push(JSON.stringify(err));
          }
          this.setState((prevState) => ({
            transaction_errors,
            current: prevState.current + 1,
            avoidResubmit,
          }));
        }, 1500);
      });
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  clearData = () => {
    this.setState(initialState);
  };

  tryAgain = () => {
    this.setState((prevState) => ({
      current: prevState.current - 1,
      transaction_errors: [],
    }));
  };

  prev = () => {
    this.setState((prevState) => {
      const { onSubscriptionsAndOrders } = this.props;
      if (
        (onSubscriptionsAndOrders && prevState.current === 1) ||
        (!onSubscriptionsAndOrders && prevState.current === 2)
      ) {
        // Save identificationFormStep state
        if (this.identificationFormRef) {
          const { props } = this.identificationFormRef;
          const { form } = props;
          form
            .validateFields()
            .then((values) => {
              this.handleIdentification(values, true);
            })
            .catch((err) => {
              console.log('Form errors', err);
            });
        }
      }
      return { current: prevState.current - 1 };
    });
  };

  render() {
    const { onSubscriptionsAndOrders, onLoans, onTJ } = this.props;
    const {
      current,
      chandaPayers,
      chandas_form,
      subscriptionsAndOrders,
      loans,
      relatives_form,
      recurrence,
      identification,
      totalAmount,
      max700w,
      mobile,
      confirmationVisible,
      transaction_errors,
      response,
      avoidResubmit,
    } = this.state;

    let firstStepLabel = 'Chanda';
    let firstStepContent = (
      <div style={styles.mainContainer}>
        <h2 align="center">Select your donation</h2>
        <ChandasForm
          handleSubmit={this.handleChandasForm}
          chandas_form={chandas_form}
        />
      </div>
    );
    if (onSubscriptionsAndOrders) {
      firstStepLabel = 'Selection';
      firstStepContent = (
        <div style={styles.mainContainer}>
          <h2 align="center">Select your subscriptions</h2>
          <SubscriptionsAndOrders
            handleSubmit={this.handleSubscriptionAndOrders}
            subscriptionsAndOrders_form={subscriptionsAndOrders}
          />
        </div>
      );
    }
    if (onLoans) {
      firstStepLabel = 'Loans';
      firstStepContent = (
        <div style={styles.mainContainer}>
          <h2 align="center">Select your Loans</h2>
          <LoansForm handleSubmit={this.handleLoans} loans_form={loans} />
        </div>
      );
    }
    if (onTJ) {
      firstStepLabel = 'Tehrik-e-Jadid';
      firstStepContent = (
        <div style={styles.mainContainer}>
          <h2 align="center">Pay Tehrik-e-Jadid on behalf of a Relative</h2>
          <h3 align="center">
            <strong>Note:</strong> You must first{' '}
            <Link to={routes.register_relatives}>register</Link> members for
            whom you wish to pay change. If you haven't already, please{' '}
            <Link to={routes.register_relatives}>register</Link> them now.
          </h3>
          <TJForm
            handleSubmit={this.handleRelatives}
            relatives_form={relatives_form}
          />
        </div>
      );
    }
    const steps = [
      {
        key: 0,
        title: firstStepLabel,
        content: firstStepContent,
      },
      {
        key: 1,
        title: 'Recurrence',
        content: (
          <div style={styles.mainContainer}>
            <h2 align="center">Select your payment plan</h2>
            <RecurrenceForm
              handleSubmit={this.handleRecurrenceForm}
              recurrence_form={recurrence}
            />
          </div>
        ),
      },
      {
        key: 2,
        title: 'Payment Information',
        content: (
          <div style={styles.mainContainer}>
            <h2 align="center">Fill the following form, please</h2>
            <IdentificationForm
              handleSubmit={this.handleIdentification}
              identification_form={identification}
              totalAmount={totalAmount}
              onChanda={!onLoans && !onSubscriptionsAndOrders && !onTJ}
              saveIdentificationFormRef={this.saveIdentificationFormRef}
            />
          </div>
        ),
      },
      {
        key: 3,
        title: 'Done',
        content: (
          <div style={styles.mainContainer}>
            <Receipt
              avoidResubmit={avoidResubmit}
              transaction_errors={transaction_errors}
              response={response}
              clearData={this.clearData}
              tryAgain={this.tryAgain}
            />
          </div>
        ),
      },
    ].filter((x) => (onSubscriptionsAndOrders ? x.key !== 1 : true));
    return (
      <div>
        <Prompt
          when={current > 0 && current < steps.length - 1}
          message={unmountMessage}
        />
        <Media
          query="(max-width: 700px)"
          onChange={(matches) => this.setState({ max700w: matches })}
        />
        <Media
          query="(max-width: 400px)"
          onChange={(matches) => this.setState({ mobile: matches })}
        />
        <Steps
          current={current}
          labelPlacement="vertical"
          size={max700w ? 'small' : 'default'}
          style={
            mobile
              ? {
                  marginLeft: -32,
                  width: 'calc(100% + 64px)',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          {steps.map((item, index) => (
            <Step
              key={item.title}
              title={
                current === index || !(max700w && !mobile) ? item.title : ''
              }
              style={mobile ? { maxWidth: '25vw' } : {}}
            />
          ))}
        </Steps>
        {current > 0 && current < steps.length - 1 ? (
          <Button style={{ margin: '8px 0px' }} onClick={() => this.prev()}>
            Previous
          </Button>
        ) : (
          <div style={{ height: 16, margin: '8px 0px' }} />
        )}
        <div>{steps[current]?.content || null}</div>
        <Modal
          title="Confirm your payment details"
          centered
          keyboard={false}
          maskClosable={false}
          closable={false}
          visible={confirmationVisible}
          style={{ minWidth: 400, maxWidth: '85%', paddingBottom: 0 }}
          bodyStyle={{ maxHeight: '85%', height: '75vh', overflow: 'auto' }}
          footer={[
            <Button key="close" onClick={this.hidePaymentConfirmation}>
              Close
            </Button>,
            <Button
              key="pay"
              type="primary"
              onClick={this.makeDonation}
              disabled={!confirmationVisible}
            >
              Confirm and pay
            </Button>,
          ]}
        >
          <ReviewPayment
            chandaPayers={chandaPayers}
            subscriptionsAndOrders={subscriptionsAndOrders}
            loans={loans}
            relatives_form={relatives_form}
            recurrence={recurrence}
            identification={identification}
            totalAmount={totalAmount}
            onSubscriptionsAndOrders={onSubscriptionsAndOrders}
            onLoans={onLoans}
            onTJ={onTJ}
          />
        </Modal>
      </div>
    );
  }
}

export default PayMain;
