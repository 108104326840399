export default {
  pay_chanda: '/pay',
  tj_on_behalf_relatives: '/relatives',
  register_relatives: '/relatives/register',
  loans: '/loans',
  subscriptions_and_orders: '/subscriptions-and-orders',
  calculate_chanda: '/calculate',
  modify_recurring_donation: '/modify',
  get_payment_status: '/status',
  minaratul_masih_donors: '/minaratul-masih-donors',
  faq: '/faq',
  resources: '/resources',
  contact_us: '/contact',
  tajneed: '/tajneed',
};
