import moment from 'moment';
import { TAHRIK_E_JADID } from './chandas';
import { getScheduleQuantity } from './form';
import subscriptions from './subscriptions';
import { getTransactionFee } from './transaction_rates';

/**
 *
 * @param {Array} payersDetails
 * @returns Returns an ordered detailed array with reduced properties name (acronyms)
 */
const getPayersDetails = (payersDetails) => {
  const donors = [];
  if (Array.isArray(payersDetails)) {
    for (let i = 0; i < payersDetails.length; i++) {
      const { payer_name, member_code, jamaat, wasiyyat_rate, ..._donations } =
        payersDetails[i];
      const donations = [];
      let total_amount = 0;
      for (let j = 0; j < Object.keys(_donations).length; j++) {
        const property = Object.keys(_donations)[j];
        let amount, donation_type;
        if (_donations[property] === true) {
          // This is the only case for subscriptions
          const foundSubscription = subscriptions.find(
            (x) => x.name === property,
          );
          if (foundSubscription) {
            amount = foundSubscription.price;
            total_amount += amount;
            /**
             * @ubinatus 03/08/2021
             * Review of Religion (Student Rate) donation_type must be passed as
             * "review_of_religions" instead of "review_of_religions_student_rate"
             * For convenience, its transformed here and not on subscriptions.js utils.
             */
            if (foundSubscription.name === 'review_of_religions_student_rate') {
              donation_type = 'review_of_religions';
            } else if (foundSubscription.name === 'review_of_religions_3yrs') {
              donation_type = 'review_of_religions';
            } else if (foundSubscription.name === 'aams_usa_student') {
              donation_type = 'students_1';
            } else if (foundSubscription.name === 'aams_usa_employed') {
              donation_type = 'students_1';
            } else {
              donation_type = property;
            }
          }
        } else {
          donation_type = property;
          amount = _donations[property];
          total_amount += amount;
        }
        donations.push({ donation_type, amount });
      }
      donors.push({
        ami_code: payersDetails[i].member_code,
        name: payersDetails[i].payer_name,
        total_amount,
        donations,
      });
    }
    return donors;
  }
  return donors;
};

/**
 * Takes the state from PayMain and the route and returnes the parsed body to be sent to the API endpoint
 * @param {Object} state The state of PayMain
 * @param {Boolean} onSubscriptionsAndOrders Boolean indicating if the route is "Subscriptions and Orders"
 * @param {Boolean} onLoans Boolean indicating if the route is "Pay Loans"
 * @param {Boolean} onTJ Boolean indicating if the route is "Pay TJ on behalf of relatives"
 * @param {Boolean} getMetadata Boolean indicating to include Metadata to the parsed body
 * @returns {Object} Body that contains all the data, including metadata for CardConnect
 */
const getPayMainRequestBody = (
  state,
  onSubscriptionsAndOrders,
  onLoans,
  onTJ,
  getMetadata = true,
) => {
  const {
    chandaPayers,
    subscriptionsAndOrders,
    loans,
    relatives_form,
    totalAmount,
    recurrence,
    identification,
  } = state;
  const onChanda = !onSubscriptionsAndOrders && !onLoans && !onTJ;
  // Getting the variables by category (chanda, loans, subscriptions or orders);
  let donor_name, donor_jamaat, ami_code, category, payersDetails, comment;
  if (onChanda) {
    category = 'chanda';
    donor_name = chandaPayers.payer_name;
    donor_jamaat = chandaPayers.jamaat;
    ami_code = chandaPayers.member_code;
    comment = chandaPayers.comment;
    payersDetails = chandaPayers.all_chanda_payers;
  } else if (onLoans) {
    category = 'loans';
    donor_name = loans.payer_name;
    donor_jamaat = loans.jamaat;
    ami_code = loans.member_code;
    comment = loans.comment;
    payersDetails = [
      {
        payer_name: loans.payer_name,
        jamaat: loans.jamaat,
        member_code: loans.member_code,
        ...loans.loans,
      },
    ];
  } else if (onTJ) {
    category = 'tj';
    donor_name = relatives_form.payload.full_name;
    donor_jamaat = relatives_form.payload.branch;
    ami_code = relatives_form.member_code;
    comment = relatives_form.comment;
    payersDetails = relatives_form.relatives
      .filter((x) => x.amount > 0)
      .map((relative) => ({
        payer_name: relative.payer_name,
        jamaat: relative.jamaat,
        member_code: relative.member_code,
        [TAHRIK_E_JADID]: relative.amount,
      }));
    console.log('on TJ payersDetails', payersDetails);
  } else {
    let payerDetail = {};
    if (subscriptionsAndOrders.category === 'subscriptions') {
      payerDetail = {
        payer_name: subscriptionsAndOrders.payer_name,
        jamaat: subscriptionsAndOrders.jamaat,
        member_code: subscriptionsAndOrders.member_code,
        ...subscriptionsAndOrders.subscriptions,
      };
    } else {
      payerDetail = {
        payer_name: subscriptionsAndOrders.payer_name,
        jamaat: subscriptionsAndOrders.jamaat,
        member_code: subscriptionsAndOrders.member_code,
        ...subscriptionsAndOrders.orders,
      };
    }

    category = subscriptionsAndOrders.category;
    donor_name = subscriptionsAndOrders.payer_name;
    donor_jamaat = subscriptionsAndOrders.jamaat;
    ami_code = subscriptionsAndOrders.member_code;
    comment = subscriptionsAndOrders.comment;
    payersDetails = [payerDetail];
  }

  // Getting the payment information
  const payee_name = `${identification.firstName} ${identification.lastName}`;

  // Frequency of the transaction
  let payment_frequency =
    recurrence.payment_recurrence === 'oneTime'
      ? recurrence.payment_recurrence
      : recurrence.payment_recurrence_frequency;

  let payee_payment_end_date;
  let schedule_quantity;
  let subscriptionsAndOrdersRecurrence = 'oneTime';
  let subscriptionsAndOrdersFrequency;
  if (
    onSubscriptionsAndOrders &&
    subscriptionsAndOrders.category === 'subscriptions'
  ) {
    // Subscriptions are yearly
    subscriptionsAndOrdersRecurrence =
      subscriptionsAndOrders.frequency === 1 ? 'oneTime' : 'recurrence';
    payment_frequency =
      subscriptionsAndOrders.frequency === 1 ? 'oneTime' : 'recurrence';
    schedule_quantity = subscriptionsAndOrders.frequency;
    subscriptionsAndOrdersFrequency = 'annually';
  }

  if (!onSubscriptionsAndOrders) {
    if (recurrence.payment_recurrence_endDate) {
      payee_payment_end_date = moment(
        recurrence.payment_recurrence_endDate,
      ).format('YYYY-MM-DD');
      schedule_quantity = recurrence.payment_recurrence_expires
        ? getScheduleQuantity(
            recurrence.payment_recurrence_startDate,
            recurrence.payment_recurrence_endDate,
            recurrence.payment_recurrence_frequency,
          )
        : 0;
    } else {
      schedule_quantity = 0;
    }
  }

  const mainPayerDetails = {
    donor_name,
    donor_jamaat,
    ami_code,
    category,
    TOT: totalAmount,
  };

  const recurrenceDetails = {
    payment_frequency,
    payee_payment_recurrence: onSubscriptionsAndOrders
      ? subscriptionsAndOrdersRecurrence
      : recurrence.payment_recurrence,
  };

  if (recurrenceDetails.payee_payment_recurrence === 'recurring') {
    recurrenceDetails.payee_payment_start_date = onSubscriptionsAndOrders
      ? moment().format('YYYY-MM-DD')
      : moment(recurrence.payment_recurrence_startDate).format('YYYY-MM-DD');
    recurrenceDetails.payee_payment_recurrence_frequency =
      onSubscriptionsAndOrders
        ? subscriptionsAndOrdersFrequency
        : recurrence.payment_recurrence_frequency;
    recurrenceDetails.payee_payment_end_date = payee_payment_end_date;
    recurrenceDetails.schedule_quantity = schedule_quantity;
  }

  const payerIdentificationDetails = {
    payee_name,
    payee_firstName: identification.firstName,
    payee_lastName: identification.lastName,
    donor_email: identification.email.replace(/\s/g, ''),
    payee_address: identification.address,
    payee_city: identification.city,
    payee_state: identification.state,
    payee_zip: identification.zipCode,
    donor_phone: identification.phone.replace(/[^0-9]/g, ''),
  };

  const transactionDetails = {
    payee_payment_type: identification.payment_type,
  };
  if (identification.forteRes) {
    // Passing the payment method details from forte.js
    transactionDetails.one_time_token = identification.forteRes.onetime_token;

    if (identification.payment_type === 'ach') {
      transactionDetails.eCheck = {
        account_holder: payee_name,
        account_type: identification.forteRes.account_type,
        last_4: identification.forteRes.last_4,
      };
    } else {
      transactionDetails.card = {
        card_holder: payee_name,
        card_type: identification.forteRes.card_type,
        expire_year: identification.forteRes.expire_year,
        expire_month: identification.forteRes.expire_month,
        last_4: identification.forteRes.last_4,
      };
    }
  }

  const transactionFee = getTransactionFee(
    totalAmount,
    identification.payment_type,
    identification.card_gateway_brand,
  );
  const otherTransactionDetails = {
    payee_total_payment: identification.asume_processing_fee
      ? Math.round((transactionFee + totalAmount) * 100) / 100
      : Math.round(totalAmount * 100) / 100,
    payee_transaction_fee: identification.asume_processing_fee
      ? transactionFee
      : 0,
    payee_update_jamaat_address: identification.update_jamaat_address,
    payee_apply_address_to_family: onChanda
      ? identification.apply_address_to_family
      : undefined,
    payee_asume_processing_fee: identification.asume_processing_fee,
  };

  if (!getMetadata) {
    return {
      ...mainPayerDetails,
      ...payerIdentificationDetails,
      ...recurrenceDetails,
      ...transactionDetails,
      ...otherTransactionDetails,
      payersDetails,
      comment,
    };
  }

  // Metadata (CustomVariables to store on CardConnect)
  const metadata = {
    donors: getPayersDetails(payersDetails),
    recurrence: recurrenceDetails,
    mainPayer: mainPayerDetails,
    transaction: otherTransactionDetails,
  };

  return {
    ...mainPayerDetails,
    ...payerIdentificationDetails,
    ...recurrenceDetails,
    ...transactionDetails,
    ...otherTransactionDetails,
    metadata,
    comment,
  };
};

export default getPayMainRequestBody;
