export const formatNumber = function formatNumber(x) {
  if (!x || Number.isNaN(x)) return '0';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeFirstLetter = function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getQueryString = function getQueryString(obj) {
  if (typeof obj !== 'object') return '';
  return new URLSearchParams(obj);
};
