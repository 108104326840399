import * as React from 'react';

import { Button, message } from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';

async function copyToClipboardWithMeta(value) {
  message.destroy();
  try {
    navigator.clipboard.writeText(value);
    message.success('Copied to clipboard', 3);
  } catch (error) {
    message.error("Couldn't copy to clipboard", 3);
  }
}

export default function CopyToClipboard({
  value,
  className,
  buttonText,
  src,
  ...props
}) {
  const [hasCopied, setHasCopied] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  }, [hasCopied]);

  return (
    <Button
      type="dashed"
      icon={hasCopied ? <CheckOutlined /> : <CopyOutlined />}
      onClick={() => {
        copyToClipboardWithMeta(value, {
          component: src,
        });
        setHasCopied(true);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {buttonText || (hasCopied ? 'Copied' : 'Copy')}
    </Button>
  );
}
