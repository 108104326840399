/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Button,
  message,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Divider,
  Select,
  Modal,
  Result,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  customValidations,
  formVariables,
  requiredMessages,
  validateMemberCode,
  validatePIN,
} from '../../utils/form';
import RelativesTable from '../../components/Relatives/RelativesTable';
import {
  getMemberRelatives,
  updateMemberRelatives,
} from '../../services/actions';
import jamaatList, { getBranchId } from '../../utils/jamaat';
import getEmailSuggestions from '../../utils/email_suggestions';

const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10 },
};

const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 14 },
  lg: { span: 12 },
  xl: { span: 10 },
  xxl: { span: 8 },
};

let initialForm;
if (process.env.NODE_ENV !== 'production') {
  initialForm = {
    member_code: 49994,
    jamaat: 'BOS',
    email: 'ja.castrocabrera@gmail.com',
    pin: 1234,
  };
}

function RegisterRelatives() {
  const [form] = Form.useForm();
  const [emailSuggestion, setEmailSuggesetion] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [relatives, setRelatives] = useState(null);
  const [payload, setPayload] = useState(null);

  const handleCreate = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log('Values of form:', values);
        setLoading(true);
        if (relatives === null) {
          const data = await getMemberRelatives(
            values[formVariables.member_code.property],
            values[formVariables.pin.property],
            true,
          );
          setLoading(false);
          if (data === false) {
            setPayload(null);
            setRelatives(null);
          } else {
            setPayload(data);
            setRelatives(
              data === null
                ? []
                : data.members.map((member) => ({
                    id: member.id,
                    member_code: member.obo_code,
                    payer_name: member.full_name,
                    relation: member.relationship,
                    deceased: member.is_deceased,
                    daftar: member.daftar,
                  })),
            );
          }
        } else {
          const newRelatives = relatives.map((relative, relativeIdx) => ({
            ...relative,
            ...values.relatives[relativeIdx],
          }));
          if (newRelatives.length === 0) {
            message.destroy();
            message.error("You haven't added any relatives");
            return;
          }
          console.log('Submit relatives', {
            ...values,
            relatives: newRelatives,
            payload,
          });
          const data = await updateMemberRelatives({
            id: payload === null ? undefined : payload.id,
            ami_code: String(values[formVariables.member_code.property]),
            branch: getBranchId(values[formVariables.jamaat.property]),
            email: values[formVariables.email.property],
            pin: values[formVariables.pin.property],
            members: newRelatives
              .filter((x) => !x.deleted)
              .map((relative) => ({
                id: relative.isNew ? undefined : relative.id,
                obo_code: String(relative.member_code),
                full_name: relative.payer_name,
                relationship: relative.relation,
                is_deceased: relative.deceased || false,
                daftar: relative.daftar,
              })),
            firstTimeAddingRelatives: payload === null,
          });
          console.log('relatives after update', data);
          setLoading(false);
          setSuccess(true);
          Modal.success({
            title: 'Your submission has been saved successfully.',
            content:
              'You will receive an email from the finance department once the submission has been approved. Then you will be able to pay on behalf of your registered members.',
          });
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const onEmailBlur = (e) => {
    if (e.target.value) {
      const newEmailSuggestion = getEmailSuggestions(e.target.value);
      setEmailSuggesetion(newEmailSuggestion);
      return;
    }
    setEmailSuggesetion('');
  };

  const changeEmailForSuggestion = (email) => {
    form.setFieldsValue({ email });
    setEmailSuggesetion('');
  };

  const handleAddRelative = () => {
    const newRelatives = [].concat(relatives);
    newRelatives.push({
      id: Date.now(),
      member_code: '',
      payer_name: '',
      relation: '',
      deceased: false,
      daftar: '',
      isNew: true,
    });
    setRelatives(newRelatives);
  };
  const handleRemoveRelative = (idx) => {
    Modal.confirm({
      title: 'Do you want to delete this relative?',
      content: 'All the information of this relative will be lost.',
      okText: 'Yes, delete this relative',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        setRelatives(relatives.filter((_, i) => i !== idx));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const reset = () => {
    form.resetFields();
    setSuccess(false);
    setRelatives(null);
    setPayload(false);
    setEmailSuggesetion('');
  };

  return (
    <Form
      form={form}
      className="chandas-form"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      initialValues={initialForm}
      scrollToFirstError
    >
      <div>
        <h2 align="center">
          Register Relatives to Pay Tehrik-e-Jadid on Their Behalf
        </h2>
        <h3 align="center">
          <strong>Note:</strong> This feature is only for those relatives who
          have passed away or cannot get a Member Code in the United States.
        </h3>
        <Row
          style={{
            padding: 24,
            background: '#fbfbfb',
            border: '1px solid #d9d9d9',
            borderRadius: '6px',
            marginBottom: 12,
          }}
        >
          <Col xs={24}>
            <Row
              type="flex"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Col lg={14} md={18} sm={22} xs={24}>
                <h3 style={{ textAlign: 'center' }}>Enter Your Information</h3>
                <Form.Item
                  name={formVariables.member_code.property}
                  label={formVariables.member_code.label}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(
                        formVariables.member_code.property,
                      ),
                    },
                    {
                      validator: (rules, values, callback) =>
                        callback(validateMemberCode(values)),
                    },
                  ]}
                >
                  <InputNumber
                    className="input-number-no-stepper"
                    autoComplete="off"
                    style={{ width: 90 }}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                  />
                </Form.Item>
                <Form.Item
                  labelCol={labelCol}
                  wrapperCol={wrapperCol}
                  label={formVariables.jamaat.label}
                  name={formVariables.jamaat.property}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(formVariables.jamaat.property),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    autoComplete="none"
                    optionFilterProp="children"
                    showAction={['focus', 'click']}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                  >
                    {jamaatList.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={formVariables.email.label}
                  name={formVariables.email.property}
                  extra={
                    emailSuggestion && (
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        onClick={() =>
                          changeEmailForSuggestion(emailSuggestion)
                        }
                      >
                        {`Did you mean ${emailSuggestion}`}
                      </Button>
                    )
                  }
                  rules={[{ required: true, validator: customValidations }]}
                >
                  <Input
                    onBlur={onEmailBlur}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={formVariables.pin.property}
                  label={formVariables.pin.label}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(formVariables.pin.property),
                    },
                    {
                      validator: (rules, values, callback) =>
                        callback(validatePIN(values)),
                    },
                  ]}
                  extra="Enter a 4-digit PIN that you will have to use in the future to make changes."
                >
                  <Input
                    autoComplete="off"
                    style={{ width: 90 }}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                    maxLength={4}
                  />
                </Form.Item>
              </Col>
              {success ? (
                <>
                  <Divider style={{ margin: 0 }} />
                  <Result
                    status="success"
                    title="Successfully registered your relatives!"
                    subTitle="The finance department will process this request within a week. Once you receive the email, then you can submit donations for the relative(s)."
                    extra={[
                      <Button type="primary" key="buy" onClick={reset}>
                        Done
                      </Button>,
                    ]}
                  />
                </>
              ) : relatives === null ? (
                <Col
                  lg={14}
                  md={18}
                  sm={22}
                  xs={24}
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={handleCreate}
                    loading={loading}
                  >
                    Retrieve Information
                  </Button>
                </Col>
              ) : (
                <>
                  <Divider style={{ marginTop: 0 }} />
                  <RelativesTable
                    data={relatives}
                    isRegister
                    disabled={loading}
                    handleRemoveRelative={handleRemoveRelative}
                  />
                  <Col xs={24} align="center">
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={handleAddRelative}
                    >
                      Add another relative
                    </Button>
                  </Col>
                  <Col xs={24} align="center" style={{ marginTop: 24 }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={handleCreate}
                      loading={loading}
                      size="large"
                      style={{ width: 120 }}
                    >
                      Submit
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default RegisterRelatives;
