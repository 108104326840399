/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useMemo } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
  daftar,
  getDaftarLabel,
  getRelationLabel,
  relationships,
} from '../../utils/relatives';

export default function RelativesTable({
  data,
  isRegister,
  handleRemoveRelative,
}) {
  const columns = useMemo(() => {
    if (isRegister) {
      return [
        {
          title: 'Name',
          dataIndex: 'payer_name',
          key: 'payer_name',
          align: 'center',
          render: (val, record, relativeIndex) =>
            !record.isNew ? (
              val
            ) : (
              <Form.Item
                key={`relatives.${relativeIndex}.payer_name`}
                name={['relatives', relativeIndex, 'payer_name']}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input
                  autoComplete="off"
                  placeholder="Enter name"
                  style={{ width: 200 }}
                  size="small"
                />
              </Form.Item>
            ),
        },
        {
          title: 'Relation',
          dataIndex: 'relation',
          key: 'relation',
          align: 'center',
          render: (val, record, relativeIndex) =>
            !record.isNew ? (
              getRelationLabel(val)
            ) : (
              <Form.Item
                key={`relatives.${relativeIndex}.relation`}
                name={['relatives', relativeIndex, 'relation']}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select
                  autoComplete="none"
                  optionFilterProp="children"
                  showAction={['focus', 'click']}
                  style={{ width: 160, textAlign: 'left' }}
                  placeholder="Select"
                  options={relationships}
                />
              </Form.Item>
            ),
        },
        {
          title: 'Deceased',
          dataIndex: 'deceased',
          key: 'deceased',
          align: 'center',
          // eslint-disable-next-line no-nested-ternary
          render: (val, record, relativeIndex) =>
            !record.isNew ? (
              val ? (
                'Yes'
              ) : (
                'No'
              )
            ) : (
              <Form.Item
                key={`relatives.${relativeIndex}.deceased`}
                name={['relatives', relativeIndex, 'deceased']}
                valuePropName="checked"
                style={{ justifyContent: 'center' }}
              >
                <Checkbox style={{ margin: '0px auto' }} />
              </Form.Item>
            ),
        },
        {
          title: 'Daftar',
          dataIndex: 'daftar',
          key: 'daftar',
          align: 'center',
          render: (val, record, relativeIndex) =>
            !record.isNew ? (
              getDaftarLabel(val)
            ) : (
              <Form.Item
                key={`relatives.${relativeIndex}.daftar`}
                name={['relatives', relativeIndex, 'daftar']}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select
                  autoComplete="none"
                  optionFilterProp="children"
                  showAction={['focus', 'click']}
                  style={{ width: 180, textAlign: 'left' }}
                  placeholder="Select"
                  options={daftar}
                />
              </Form.Item>
            ),
        },
        {
          title: 'OBO ID',
          dataIndex: 'member_code',
          key: 'member_code',
          align: 'center',
          render: (val) => (!val ? '-' : val),
        },
        {
          title: 'Delete',
          dataIndex: 'isNew',
          key: 'isNew',
          align: 'center',
          render: (_val, record, relativeIndex) =>
            record.isNew ? (
              <Button
                type="text"
                danger
                onClick={() => handleRemoveRelative(relativeIndex)}
                size="small"
              >
                <DeleteOutlined style={{ color: '#ff4d4f' }} />
              </Button>
            ) : (
              <Form.Item
                key={`relatives.${relativeIndex}.deleted`}
                name={['relatives', relativeIndex, 'deleted']}
                valuePropName="checked"
                style={{ justifyContent: 'center' }}
              >
                <Checkbox />
              </Form.Item>
            ),
        },
      ];
    }
    return [
      {
        title: 'MBO Code',
        dataIndex: 'member_code',
        key: 'member_code',
        align: 'center',
        render: (val) => (!val ? 'Pending' : val),
      },
      {
        title: 'Name',
        dataIndex: 'payer_name',
        key: 'payer_name',
        align: 'center',
      },
      {
        title: 'Relation',
        dataIndex: 'relation',
        key: 'relation',
        align: 'center',
        render: (val) => getRelationLabel(val),
      },
      {
        title: 'Deceased',
        dataIndex: 'deceased',
        key: 'deceased',
        render: (val) => (val ? 'Yes' : 'No'),
        align: 'center',
      },
      {
        title: 'Daftar',
        dataIndex: 'daftar',
        key: 'daftar',
        align: 'center',
        render: (val) => getDaftarLabel(val),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (_val, record, relativeIndex) => (
          <Form.Item
            key={`relatives.${relativeIndex}.amount`}
            name={['relatives', relativeIndex, 'amount']}
            rules={[{ required: false }]}
          >
            <InputNumber
              autoComplete="off"
              min={0}
              step={1}
              placeholder="0"
              disabled={!record.member_code}
            />
          </Form.Item>
        ),
      },
    ];
  }, [isRegister, handleRemoveRelative]);
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      size="small"
      rowKey="id"
      locale={{ emptyText: 'No relatives registered' }}
      style={{ overflow: 'hidden', overflowX: 'auto' }}
    />
  );
}
