import axios from 'axios';

const getBankName = (routingNumber) =>
  new Promise((resolve, reject) =>
    axios
      .get(`https://www.routingnumbers.info/api/data.json?rn=${routingNumber}`)
      .then((res) => {
        if (res.data.code === 200) {
          resolve(res.data);
        }
        reject(res.data);
      })
      .catch((error) => reject(error.response)),
  );

export default getBankName;
