import React, { useEffect, useState } from 'react';
import { Button, Select, Tabs, Input, message, Table, Modal, Form } from 'antd';
import moment from 'moment';
import { formatNumber } from '../../utils/formats';
import { getMetalsPrice } from '../../services/actions';
import ZakatCalculatorFAQ from '../FAQ/ZakatCalculatorFAQ';

const { Option } = Select;
const { TabPane } = Tabs;

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    padding: '0px 25px 25px',
  },
  quoteImages: {
    width: '100%',
    borderRadius: '6px',
  },
  form: {
    padding: '8px 0px',
  },
};

const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8 },
  lg: { span: 9 },
  xl: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
  xl: { span: 6 },
  xxl: { span: 4 },
};

const tailWrapperCol = {
  sm: {
    span: 24,
    offset: 0,
  },
  md: { offset: 8 },
  lg: { offset: 9 },
  xl: { offset: 10 },
};

const zakatInputs = [
  { label: 'Gold', property: 'gold' },
  { label: 'Silver', property: 'silver' },
  { label: 'Cash', property: 'cash' },
];

const comoditiesPrefix = [
  { label: 'Gram', property: 'gram' },
  { label: 'Tola', property: 'tola' },
];

const currencyPrefix = [{ label: 'US $', property: 'usd' }];

const columns = [
  {
    title: 'Asset Class',
    dataIndex: 'asset',
  },
  {
    title: 'Current Price',
    dataIndex: 'currentPrice',
  },
  {
    title: 'Amount Unused for 1 Year',
    dataIndex: 'amountUnused',
  },
  {
    title: 'Value of Amount Unused for 1 Year',
    dataIndex: 'valueAmountUnused',
  },
  {
    title: 'Nisab',
    dataIndex: 'nisab',
  },
  {
    title: 'Equal or Greater than Nisab?',
    dataIndex: 'greaterThanNisab',
  },
  {
    title: 'Zakat to pay',
    dataIndex: 'zakatToPay',
  },
];

const troyOunceToGrams = (troyOunce) => troyOunce / 31.1034768;

function CalculateZakat() {
  const [goldPricePerGram, setGoldPricePerGram] = useState(null);
  const [silverPricePerGram, setSilverPricePerGram] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(undefined);
  const [zakatCalculation, setZakatCalculation] = useState([]);
  const [totalZakatToPay, setTotalZakatToPay] = useState(null);
  const [activeKey, setActiveKey] = useState('form');
  const [viewZakatFAQ, setViewZakatFAQ] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getMetalsPrice()
      .then((res) => {
        if (res && res.metals) {
          const factor = 22 / 24;
          const goldPricePerTroyOunce = res.metals.gold;
          const silverPriceTroyOunce = res.metals.silver;
          const newGoldPricePerGram =
            troyOunceToGrams(goldPricePerTroyOunce) * factor;
          const newSilverPricePerGram =
            troyOunceToGrams(silverPriceTroyOunce) * factor;
          const newLastUpdated = moment
            .unix(res.timestamp)
            .format('MM/DD/YYYY hh:mmA');
          setGoldPricePerGram(newGoldPricePerGram);
          setSilverPricePerGram(newSilverPricePerGram);
          setLastUpdated(newLastUpdated);
        }
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
        message.error(
          'There was an error getting the latest metals price. Please try again later.',
        );
      });
    return () => {};
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form: ', values);

        const newZakatCalculation = [];
        const tola = 11.6638038;
        const getMetalGrams = (unitSelected) =>
          unitSelected === 'gram' ? 1 : tola;
        const roundAndFormatNumber = (num) =>
          Number.isNaN(num) ? 0 : formatNumber(Math.round(num * 100) / 100);

        // Gold
        const gold = values.gold || 0;
        const goldGramsSelected = gold * getMetalGrams(values.gold_unit);
        const goldGreaterThanNisab = goldGramsSelected >= 87.4785285;
        const goldNisab = 87.4785285 * goldPricePerGram;
        const goldZakatToPay = goldGreaterThanNisab
          ? goldGramsSelected * goldPricePerGram * 0.025
          : 0;

        newZakatCalculation.push({
          asset: 'Gold',
          currentPrice: `US$ ${roundAndFormatNumber(goldPricePerGram)}`,
          amountUnused: `${roundAndFormatNumber(goldGramsSelected)} grams`,
          valueAmountUnused: `US$ ${roundAndFormatNumber(
            goldGramsSelected * goldPricePerGram,
          )}`,
          nisab: `US$ ${roundAndFormatNumber(goldNisab)}`,
          greaterThanNisab: goldGreaterThanNisab ? 'Yes' : 'No',
          zakatToPay: `US$ ${roundAndFormatNumber(goldZakatToPay)}`,
        });

        // Silver
        const silver = values.silver || 0;
        const silverGramsSelected = silver * getMetalGrams(values.silver_unit);
        const silverGreaterThanNisab = silverGramsSelected >= 612.3497;
        const silverNisab = 612.3497 * silverPricePerGram;
        const silverZakatToPay = silverGreaterThanNisab
          ? silverGramsSelected * silverPricePerGram * 0.025
          : 0;

        newZakatCalculation.push({
          asset: 'Silver',
          currentPrice: `US$ ${roundAndFormatNumber(silverPricePerGram)}`,
          amountUnused: `${roundAndFormatNumber(silverGramsSelected)} grams`,
          valueAmountUnused: `US$ ${roundAndFormatNumber(
            silverGramsSelected * silverPricePerGram,
          )}`,
          nisab: `US$ ${roundAndFormatNumber(silverNisab)}`,
          greaterThanNisab: silverGreaterThanNisab ? 'Yes' : 'No',
          zakatToPay: `US$ ${roundAndFormatNumber(silverZakatToPay)}`,
        });

        // Cash
        const cash = values.cash || 0;
        const cashNisab = 87.4785285 * goldPricePerGram;
        const cashGreaterThanNisab = cash >= cashNisab;
        const cashZakatToPay = cashGreaterThanNisab ? cash * 0.025 : 0;

        newZakatCalculation.push({
          asset: 'Cash',
          currentPrice: '',
          amountUnused: `US$ ${roundAndFormatNumber(cash)}`,
          valueAmountUnused: `US$ ${roundAndFormatNumber(cash)}`,
          nisab: `US$ ${roundAndFormatNumber(cashNisab)}`,
          greaterThanNisab: cashGreaterThanNisab ? 'Yes' : 'No',
          zakatToPay: `US$ ${roundAndFormatNumber(cashZakatToPay)}`,
        });

        const newTotalZakatToPay = `US$ ${roundAndFormatNumber(
          goldZakatToPay + silverZakatToPay + cashZakatToPay,
        )}`;
        console.log(newTotalZakatToPay);
        console.log(newZakatCalculation);

        setActiveKey('results');
        setZakatCalculation(newZakatCalculation);
        setTotalZakatToPay(newTotalZakatToPay);
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const resetCalculation = () => {
    form.resetFields();
    setActiveKey('form');
  };

  const openZakatFAQ = () => {
    setViewZakatFAQ(true);
  };

  const closeZakatFAQ = () => {
    setViewZakatFAQ(false);
  };

  return (
    <div>
      <Tabs activeKey={activeKey} tabBarStyle={{ display: 'none' }}>
        <TabPane tab="Zakat Form" key="form">
          <h3 style={{ textAlign: 'center' }}>
            Enter Unused Amount for 1-year
          </h3>
          <Form
            form={form}
            style={styles.form}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            scrollToFirstError
          >
            {zakatInputs.map((x) => (
              <Form.Item
                key={x.property}
                name={x.property}
                label={x.label}
                rules={[
                  {
                    required: true,
                    message: `
                        Please enter the unused amount for 1 year. 
                        Enter 0 if you didn’t have any unused ${x.property}.
                      `,
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  addonBefore={
                    <Form.Item
                      noStyle
                      name={`${x.property}_unit`}
                      initialValue={x.property === 'cash' ? 'usd' : 'gram'}
                    >
                      <Select style={{ width: 85 }}>
                        {x.property === 'cash'
                          ? currencyPrefix.map((y) => (
                              <Option key={y.property} value={y.property}>
                                {y.label}
                              </Option>
                            ))
                          : comoditiesPrefix.map((y) => (
                              <Option key={y.property} value={y.property}>
                                {y.label}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  }
                />
              </Form.Item>
            ))}
            <Form.Item
              wrapperCol={tailWrapperCol}
              extra={
                <div>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={openZakatFAQ}
                  >
                    How is Zakat Calculated?
                  </Button>
                </div>
              }
            >
              <Button type="primary" onClick={handleSubmit}>
                How much Zakat should I pay?
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Estimated Chanda" key="results">
          <h3 style={{ textAlign: 'center' }}>
            Based on information you've just provided, you should pay:
          </h3>
          <Table
            scroll={{ x: 'auto' }}
            columns={columns}
            dataSource={zakatCalculation}
            style={{ background: '#fff' }}
            pagination={false}
            bordered
            rowKey={(x) => x.asset}
            footer={() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h4>Total Zakat to Pay</h4>
                <h3 style={{ fontWeight: '600' }}>{totalZakatToPay}</h3>
              </div>
            )}
          />
          <Form.Item wrapperCol={tailWrapperCol}>
            <Button
              htmlType="submit"
              style={{ marginTop: 24 }}
              onClick={resetCalculation}
            >
              Calculate again
            </Button>
          </Form.Item>
        </TabPane>
      </Tabs>
      <Modal
        title={null}
        centered
        visible={viewZakatFAQ}
        onCancel={closeZakatFAQ}
        style={{ minWidth: 400, maxWidth: '85%', paddingBottom: 0 }}
        bodyStyle={{ maxHeight: '85%', height: '85vh', overflow: 'auto' }}
        width={800}
        footer={[
          <Button key="close" onClick={closeZakatFAQ}>
            Close
          </Button>,
        ]}
      >
        <ZakatCalculatorFAQ lastUpdated={lastUpdated} />
      </Modal>
    </div>
  );
}

export default CalculateZakat;
