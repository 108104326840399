const transaction_rates = {
  ach: {
    description: 'US$ 0.06 (six cents)',
    currency: 'USD',
    fixed_cost: 0.06,
    variable_cost: 0,
  },
  debit: {
    description: 'US$ 0.22 + 0.13% per transaction',
    currency: 'USD',
    fixed_cost: 0.22,
    variable_cost: 0.0013,
  },
  credit: {
    description:
      'Cost varies according to the card type and rewards on the card',
    currency: 'USD',
    custom: [
      {
        gateway_brand: 'amex',
        fixed_cost: 0.06,
        variable_cost: 0.0215,
      },
      {
        gateway_brand: 'discover',
        fixed_cost: 0.06,
        variable_cost: 0.0145 + 0.0015,
      },
      {
        gateway_brand: 'visa',
        fixed_cost: 0.06,
        variable_cost: 0.0145 + 0.0015,
      },
      {
        gateway_brand: 'mastercard',
        fixed_cost: 0.06,
        variable_cost: 0.0145 + 0.0015,
      },
    ],
  },
};

/**
 * Function -> Gets the fee of a transaction depending on the payment method.
 * @param {number} amount - Number greater than 0;
 * @param {string} payment_type - "ach", "debit", "credit"
 * @param {string} card_gateway_brand - "amex", "discover", "visa", "mastercard"
 */
export const getTransactionFee = (amount, payment_type, card_gateway_brand) => {
  let transactionFee = 0;
  if (payment_type === 'ach') {
    transactionFee =
      transaction_rates.ach.fixed_cost +
      transaction_rates.ach.variable_cost * amount;
  } else if (payment_type === 'debit') {
    transactionFee =
      transaction_rates.debit.fixed_cost +
      transaction_rates.debit.variable_cost * amount;
  } else if (payment_type === 'credit') {
    const gatewayFee = transaction_rates.credit.custom.find(
      (x) => x.gateway_brand === card_gateway_brand,
    );
    if (gatewayFee) {
      transactionFee =
        gatewayFee.fixed_cost + gatewayFee.variable_cost * amount;
    }
  }
  return Math.round(transactionFee * 100) / 100;
};

export const getTransactionFeeFormula = (payment_type, card_gateway_brand) => {
  let formula;
  if (payment_type === 'ach') {
    formula = `US$ ${transaction_rates.ach.fixed_cost}`;
  } else if (payment_type === 'debit') {
    formula = `US$ ${transaction_rates.debit.fixed_cost} + ${
      transaction_rates.debit.variable_cost * 100
    }%`;
  } else if (payment_type === 'credit') {
    const gatewayFee = transaction_rates.credit.custom.find(
      (x) => x.gateway_brand === card_gateway_brand,
    );
    if (gatewayFee) {
      formula = `US$ ${gatewayFee.fixed_cost} + ${
        Math.round(gatewayFee.variable_cost * 10000) / 100
      }%`;
    }
  }
  return formula;
};

export default transaction_rates;
