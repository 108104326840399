import React, { useState } from 'react';
import { Input, Select, Modal, InputNumber } from 'antd';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import jamaatList from '../../utils/jamaat';
import FormWithRecaptcha from '../../components/Form/FormWithRecaptcha';
import { sendContactForm } from '../../services/actions';
import {
  customValidations,
  requiredMessages,
  validateMemberCode,
} from '../../utils/form';

const { Option } = Select;

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    // padding: '0px 25px 25px',
  },
};

const ContactUsMain = () => {
  const [formRef, setFormRef] = useState(null);

  const handleSubmit = (values) => {
    console.log('Received values of form: ', values);
    const {
      name,
      jamaat,
      member_code,
      email,
      phone,
      transaction_number,
      custom_message,
    } = values;
    sendContactForm(
      name,
      jamaat,
      member_code,
      email,
      phone,
      transaction_number,
      custom_message,
    )
      .then(() => {
        formRef.resetForm();
        Modal.success({
          title: 'Your feedback has been sent',
          content:
            'Thank you for reaching us. We will try to respond as soon as posible.',
        });
      })
      .catch((err) => {
        console.log(err);
        formRef.resetForm();
        Modal.error({
          title: 'Something went wrong',
          content: "The feedback couldn't be sent. Please, try again.",
        });
      });
  };

  return (
    <div style={styles.mainContainer}>
      <FormWithRecaptcha
        wrappedRef={setFormRef}
        handleSubmit={handleSubmit}
        title="Contact US"
        subTitle={`
          For any questions or concerns, please don't hesitate to contact us.
        `}
        submitButton="Send"
        formInputs={[
          {
            label: 'Name',
            property: 'name',
            rules: [{ required: true, validator: customValidations }],
            component: <Input autoComplete="off" />,
          },
          {
            label: "Jama'at",
            property: 'jamaat',
            rules: [{ required: true, message: requiredMessages('jamaat') }],
            component: (
              <Select
                showSearch
                autoComplete="none"
                optionFilterProp="children"
                showAction={['focus', 'click']}
              >
                {jamaatList.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.label}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            label: 'Member Code',
            property: 'member_code',
            rules: [
              { required: false },
              {
                validator: (rules, values, callback) =>
                  callback(validateMemberCode(values)),
              },
            ],
            component: (
              <InputNumber
                className="input-number-no-stepper"
                autoComplete="off"
                style={{ width: '100%' }}
              />
            ),
          },
          {
            label: 'E-mail',
            property: 'email',
            getEmailSuggestion: true,
            rules: [{ required: true, validator: customValidations }],
            component: <Input autoComplete="off" />,
          },
          {
            label: 'Cell phone',
            property: 'phone',
            rules: [{ required: true, validator: customValidations }],
            component: (
              <Cleave
                className="ant-input"
                options={{ phone: true, phoneRegionCode: 'US' }}
              />
            ),
          },
          {
            label: 'Transaction/Receipt Number',
            property: 'transaction_number',
            rules: [],
            component: (
              <Input
                autoComplete="off"
                placeholder="Please provide the transaction or receipt number if applicable"
              />
            ),
          },
          {
            label: 'Message',
            property: 'custom_message',
            rules: [
              { required: true, message: 'Message is required' },
              { max: 750, message: 'Maximum 750 characters' },
              { min: 10, message: 'Minimum 10 characters' },
            ],
            component: (
              <Input.TextArea
                autoComplete="off"
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default ContactUsMain;
