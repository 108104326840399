import validator from 'validator';
import moment from 'moment';
import validateCard from 'card-validator';
import isValidRoutingNumber from './routing_number_validator';

export const formVariables = {
  name: {
    property: 'name',
    label: 'Name',
  },
  firstName: {
    property: 'firstName',
    label: 'First Name',
  },
  lastName: {
    property: 'lastName',
    label: 'Last Name',
  },
  jamaat: {
    property: 'jamaat',
    label: "Jama'at",
  },
  payer_name: {
    property: 'payer_name',
    label: "Donor's Full Name",
  },
  member_code: {
    property: 'member_code',
    label: 'Member Code',
    not_required: true,
  },
  email: {
    property: 'email',
    label: 'Email',
  },
  address: {
    property: 'address',
    label: 'Address',
  },
  city: {
    property: 'city',
    label: 'City',
  },
  state: {
    property: 'state',
    label: 'State',
  },
  zipCode: {
    property: 'zipCode',
    label: 'Zip code',
  },
  phone: {
    property: 'phone',
    label: 'Cell phone',
  },
  comment: {
    property: 'comment',
    label: 'Comment',
  },

  // Payment on behalf of a Relative
  pin: {
    property: 'pin',
    label: 'PIN',
  },

  // Payment recurrence
  payment_recurrence: {
    property: 'payment_recurrence',
    label: 'Payment Recurrence',
  },
  payment_recurrence_expires: {
    property: 'payment_recurrence_expires',
    label: 'Payment Plan Expiration',
  },
  payment_recurrence_startDate: {
    property: 'payment_recurrence_startDate',
    label: 'Payment Plan Start Date',
  },
  payment_recurrence_endDate: {
    property: 'payment_recurrence_endDate',
    label: 'Payment Plan End Date',
  },
  payment_recurrence_frequency: {
    property: 'payment_recurrence_frequency',
    label: 'Payment Plan Frequency',
  },
  // Payment related
  payment_type: {
    property: 'payment_type',
    label: 'Payment type',
  },
  bank_account_type: {
    property: 'bank_account_type',
    label: 'Bank Account Type',
  },
  bank_account_number: {
    property: 'bank_account_number',
    label: 'Bank Account No.',
  },
  bank_account_number_2: {
    property: 'bank_account_number_2',
    label: 'Re-enter Bank Account No.',
  },
  routing_number: {
    property: 'routing_number',
    label: 'Routing No.',
  },
  debit_card_number: {
    property: 'debit_card_number',
    label: 'Debit Card No.',
  },
  credit_card_number: {
    property: 'credit_card_number',
    label: 'Credit Card No.',
  },
  expiry_date: {
    property: 'expiry_date',
    label: 'Expiry Date',
  },
  cvv: {
    property: 'cvv',
    label: 'CVV',
  },
  card_gateway_brand: {
    property: 'card_gateway_brand',
    label: 'Credit/Debit Card brand',
  },
  update_jamaat_address: {
    property: 'update_jamaat_address',
    // eslint-disable-next-line max-len
    label:
      'If my payment address is not the same as the address in Jama’at Tajneed, please update the Jama’at records with this address',
  },
  apply_address_to_family: {
    property: 'apply_address_to_family',
    label: 'Apply this address change to the whole family',
  },
  asume_processing_fee: {
    property: 'asume_processing_fee',
    label:
      'I want to pay the processing fee for this transaction (will be added to the total)',
  },
};

export const requiredMessages = (variableProperty) => {
  const { label } = formVariables[variableProperty];
  return `${label || 'This field'} is required`;
};

export const getCardGateway = (card_number) => {
  if (card_number) {
    const numberValidation = validateCard.number(card_number);
    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      return numberValidation.card.type;
    }
  }
  return undefined;
};

export const cardNumberFormat = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = matches && matches[0] ? matches[0] : '';
  const parts = [];

  for (let i = 0; i < match.length; i++) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  }
  return value;
};

export const validateMemberCode = (value) => {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }
  if (value === 999 || value === '999' || (value >= 20000 && value <= 60000)) {
    return undefined;
  }
  return 'Member code not valid';
};
export const validatePIN = (value) => {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }
  if (/(^\d{4}$)/.test(value)) {
    return undefined;
  }
  return 'PIN must be 4 digits';
};

export const getDaysFromTimeUnit = function getDaysFromTimeUnit(frequency) {
  let numDays;
  if (frequency === 'weekly') {
    numDays = 7;
  } else if (frequency === 'bi_weekly') {
    numDays = 3.5;
  } else if (frequency === 'semi_monthly') {
    numDays = 14;
  } else if (frequency === 'monthly') {
    numDays = 30;
  } else if (frequency === 'quarterly') {
    numDays = 90;
  } else if (frequency === 'semi_annually') {
    numDays = 180;
  } else if (frequency === 'annually') {
    numDays = 360;
  } else {
    numDays = 1;
  }
  return numDays;
};

export const getScheduleQuantity = (startDate, endDate, frequency) => {
  if (['weekly', 'monthly', 'annually'].indexOf(frequency) > -1) {
    // eslint-disable-next-line no-nested-ternary
    const format =
      frequency === 'weekly'
        ? 'weeks'
        : frequency === 'monthly'
        ? 'months'
        : 'years';
    return moment(endDate).diff(moment(startDate), format) + 1;
  }
  const numDays = getDaysFromTimeUnit(frequency);

  const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
  return Math.floor(daysDiff / numDays);
};

export const validateRecurrenceDate = (startDate, endDate, frequency) => {
  if (!endDate) {
    return undefined;
  }
  const schedule_quantity = getScheduleQuantity(startDate, endDate, frequency);
  if (schedule_quantity <= 1) {
    const numDays = getDaysFromTimeUnit(frequency);
    if (['weekly', 'monthly', 'annually'].indexOf(frequency) > -1) {
      // eslint-disable-next-line no-nested-ternary
      const format =
        frequency === 'weekly'
          ? 'weeks'
          : frequency === 'monthly'
          ? 'months'
          : 'years';
      return `The minimum end date should be ${moment(startDate)
        .add(1, format)
        .format('MM/DD/YYYY')}`;
    }
    return `The minimum end date should be ${moment(startDate)
      .add(numDays - 1, 'days')
      .format('MM/DD/YYYY')}`;
  }
  if (moment(endDate).diff(moment(startDate), 'days') > 0) {
    return undefined;
  }
  return 'End Date must be greater than the Start Date';
};

export const customValidations = (rules, values, callback, otherProps) => {
  try {
    // Name, First name, Last name
    if (
      [
        formVariables.name.property,
        formVariables.firstName.property,
        formVariables.lastName.property,
      ].indexOf(rules.field) > -1
    ) {
      const formattedNamed = values ? values.split(' ').join('') : '';
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (formattedNamed.length > 64) {
        return callback('Maximum length of 64 characters');
      }
      return callback();
    }
    // console.log(rules, values);
    // Member code
    if (rules.field === formVariables.member_code.property) {
      if (values === undefined || values === null || values === '') {
        return callback();
      }
      if (
        values === 99999 ||
        values === '99999' ||
        (values >= 20000 && values <= 60000)
      ) {
        return callback();
      }
      return callback('Member code not valid');
    }
    // Email
    if (rules.field === formVariables.email.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isEmail(values)) {
        return callback('Please enter a valid email');
      }
      return callback();
    }

    // Address
    if (rules.field === formVariables.address.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      return callback();
    }

    // City
    if (rules.field === formVariables.city.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      return callback();
    }

    // State
    if (rules.field === formVariables.state.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      return callback();
    }

    // Zipcode
    if (rules.field === formVariables.zipCode.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!/(^\d{5}$)/.test(values)) {
        return callback('Zip code must be 5 digits');
      }
      if (!validator.isPostalCode(values, 'US')) {
        return callback('Zip code is invalid');
      }
      return callback();
    }

    // Mobile Phone
    if (rules.field === formVariables.phone.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isMobilePhone(values, ['en-US'])) {
        return callback('Please enter a valid phone number');
      }
      return callback();
    }

    // Payment Type
    if (rules.field === formVariables.payment_type.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      return callback();
    }

    // Bank Account Number
    if (rules.field === formVariables.bank_account_type.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (['c', 's'].indexOf(values) === -1) {
        return callback('Please select the correct option');
      }
      return callback();
    }
    // Bank Account Number
    if (rules.field === formVariables.bank_account_number.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isNumeric(values)) {
        return callback('Please enter valid account number');
      }
      return callback();
    }

    // Re enter Bank account number
    if (rules.field === formVariables.bank_account_number_2.property) {
      if (!values) {
        return callback('Account number is required');
      }
      if (
        !validator.equals(
          values,
          otherProps[formVariables.bank_account_number.property],
        )
      ) {
        return callback('Account numbers do not match');
      }
      return callback();
    }

    // Routing number
    if (rules.field === formVariables.routing_number.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isNumeric(values)) {
        return callback('Please enter a valid routing number');
      }
      if (isValidRoutingNumber(values)) {
        return callback();
      }
      return callback('Please enter a valid routing number');
    }

    // Debit Card Number
    if (rules.field === formVariables.debit_card_number.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isCreditCard(values)) {
        return callback('Please enter a valid debit card number');
      }
      return callback();
    }

    // Credit Card Number
    if (rules.field === formVariables.credit_card_number.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isCreditCard(values)) {
        return callback('Please enter a valid credit card number');
      }
      return callback();
    }

    // Expiry Date
    if (rules.field === formVariables.expiry_date.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (values.length !== 7) {
        return callback('Please use the following format: MM/YYYY');
      }
      if (moment(values, 'MM/YYYY').endOf('month').diff(moment()) < 0) {
        return callback('Card is expired');
      }
      return callback();
    }

    // CVV
    if (rules.field === formVariables.cvv.property) {
      if (!values) {
        return callback(requiredMessages(rules.field));
      }
      if (!validator.isNumeric(values)) {
        return callback('Please enter only numbers');
      }
      if (values.length !== 3 && values.length !== 4) {
        return callback('CVV number is not valid');
      }
      return callback();
    }
    return callback('Contact support');
  } catch (error) {
    // console.log(error);
    return callback('Contact support');
  }
};
