const loans = [
  {
    name: 'education_loans',
    label: 'Education Loans',
    description:
      'Repayment of loans disbursed through the Taleem department to support higher education of students in the USA.',
    special_warning:
      'Only select this if you have taken out an educational loan',
  },
  {
    name: 'qarza_hasana_usa_hqr',
    label: 'Qarza Hasana USA HQR',
    description:
      'Repayment of loans given to members for urgent financial need by the headquarters in Jama’at USA.',
    special_warning:
      'Only select this if you have taken out a loan from USA Headquarters',
  },
  {
    name: 'qarza_hasana_wakalat_mal',
    label: 'Qarza Hasana Wakalat Mal',
    description:
      'Repayment of loans given to members for urgent financial need by the international headquarters under Wakalat Mal of Tahrik-e-Jadid.',
    special_warning:
      'Only select this if you have taken out a loan from Wakalat Mal',
  },
];

export const getLoanLabel = (name) => {
  const foundLoan = loans.find((x) => x.name === name);
  return foundLoan ? foundLoan.label : '';
};

export default loans;
