/* eslint-disable */
import axios from 'axios';

const config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
};

export default function apiCall(method, path, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios[method.toLowerCase()](path, data, config);
      resolve(res.data);
    } catch (error) {
      if (error.response) {
        reject(error.response.data);
      } else {
        reject('Error');
      }
    }
  });
}
