import React from 'react';
import { Divider } from 'antd';
import CalculateChanda from './CalculateChanda';
import CalculateZakat from './CalculateZakat';

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    // padding: '0px 25px 25px',
  },
  quoteImages: {
    width: '100%',
    borderRadius: '6px',
  },
  form: {
    padding: '8px 0px',
  },
};

function CalculateChandaMain() {
  return (
    <div style={styles.mainContainer}>
      <h1 align="center">Zakat Calculator</h1>
      <CalculateZakat />
      <Divider />
      <h1 align="center">
        Calculate Your Jamaat Obligatory Chanda
        <br />
        (Based on Annual Net Income)
      </h1>
      <CalculateChanda />
    </div>
  );
}

export default CalculateChandaMain;
