/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { Input, Modal, Checkbox, Alert, Result, Button } from 'antd';
import moment from 'moment';
import FormWithRecaptcha from '../../components/Form/FormWithRecaptcha';
import { getPaymentStatus } from '../../services/actions';
import {
  requiredMessages,
  validateMemberCode,
  customValidations,
} from '../../utils/form';

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    // padding: '0px 25px 25px',
  },
};

const PaymentStatusMain = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formRef, setFormRef] = useState(null);

  const handleSubmit = (values) => {
    console.log('Received values of form: ', values);
    setLoading(true);
    getPaymentStatus({
      amiCode: values.member_code,
      email: values.email,
      includeFamily: values.includeFamily,
      currentYtd: values.currentYtd,
      prev_fy: values.prev_fy,
      current_cal: values.current_cal,
      prev_cal: values.prev_cal,
      tj: values.tj,
      wj: values.wj,
      taxStatement: values.taxStatement,
      tajnid: values.tajnid,
    })
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        formRef.resetForm();
        setLoading(false);
        Modal.error({
          title:
            'Sorry, the information you provided doesn’t match our records',
          content:
            'Please contact your local Jama’at to get the payment status or to update your information.',
        });
      });
  };

  const resetForm = () => {
    setSuccess(false);
  };

  const getCurrentCalendarYear = (startMonth, endMonth, isPreviousYear) => {
    let year = isPreviousYear ? moment().year() - 1 : moment().year();
    const currentMonth = moment().month() + 1;
    if (currentMonth > endMonth) {
      year += 1;
    }
    if (startMonth > endMonth) {
      // Ex: Jul 2021 - Jun 2022
      return `${moment(startMonth, 'M').format('MMM')} ${year - 1} - ${moment(
        endMonth,
        'M',
      ).format('MMM')} ${year}`;
    }
    // Ex: Jan 2021 - Jun 2021
    return `${moment(startMonth, 'M').format('MMM')} ${year} - ${moment(
      endMonth,
      'M',
    ).format('MMM')} ${year}`;
  };

  return (
    <div style={styles.mainContainer}>
      {success ? (
        <div style={{ maxWidth: 600, margin: 'auto' }}>
          <Result
            status="success"
            title="Payment Status Request Submitted"
            subTitle={
              <div>
                <p>Your request has been submitted successfully</p>
                <br />
                <p style={{ color: 'rgba(0,0,0,0.82)' }}>
                  If your member code and email match, and your email has been
                  verified, an email will be sent to you shortly. If you have
                  not verified your email, a verification code will be emailed.
                  You must verify the email before you can receive any
                  statements.
                  <br />
                  JazakAllah
                </p>
                <br />
                <Alert
                  type="warning"
                  message="Please note that statements can be delayed by upto 10 minutes, so do not request another report if you do not immediately receive a statement"
                />
                <br />
                <Alert
                  type="warning"
                  message="Don't forget to check your SPAM/JUNK folders"
                />
              </div>
            }
            extra={
              <Button key="back" onClick={resetForm}>
                Go back
              </Button>
            }
          />
        </div>
      ) : (
        <FormWithRecaptcha
          wrappedRef={setFormRef}
          loading={loading}
          handleSubmit={handleSubmit}
          title="Get Your Payment Status"
          subTitle="Provide the information below if you want to get the payment status for current fiscal year."
          description="If your email address and member code matches what we have in our Tajneed database, we will email the information to you"
          submitButton="Send my Payment Status"
          formInputs={[
            {
              label: 'Member Code',
              property: 'member_code',
              rules: [
                { required: true, message: requiredMessages('member_code') },
                {
                  validator: (rules, values, callback) =>
                    callback(validateMemberCode(values)),
                },
              ],
              component: (
                <Input
                  className="input-number-no-stepper"
                  autoComplete="off"
                  disabled={loading}
                />
              ),
            },
            {
              label: 'E-mail',
              property: 'email',
              rules: [{ required: true, validator: customValidations }],
              component: <Input autoComplete="off" disabled={loading} />,
            },
            {
              label: '',
              property: 'includeFamily',
              extra:
                'Will only work if the Head of Family Member code is entered',
              isCheckbox: true,
              rules: [],
              initialValue: true,
              component: (
                <Checkbox disabled={loading}>
                  Send the record of my entire family unit
                </Checkbox>
              ),
            },
            {
              property: 'currentYtd',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: true,
              component: (
                <Checkbox disabled={loading}>
                  Current <strong>Fiscal Year</strong> Statement (
                  {getCurrentCalendarYear(7, 6)})
                </Checkbox>
              ),
            },
            {
              property: 'prev_fy',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>
                  <strong style={{ color: 'red' }}>Previous Fiscal Year</strong>{' '}
                  Statement ({getCurrentCalendarYear(7, 6, true)})
                </Checkbox>
              ),
            },
            {
              property: 'current_cal',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>
                  Current <strong>Calendar Year</strong> Statement (
                  {getCurrentCalendarYear(1, 12)})
                </Checkbox>
              ),
            },
            {
              property: 'prev_cal',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>
                  <strong style={{ color: 'red' }}>
                    Previous Calendar Year
                  </strong>{' '}
                  Statement ({getCurrentCalendarYear(1, 12, true)})
                </Checkbox>
              ),
            },
            {
              property: 'tj',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>
                  Current Year Tehrik-e-Jadid ({getCurrentCalendarYear(11, 10)})
                </Checkbox>
              ),
            },
            {
              property: 'wj',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>
                  Current Year Waqf-e-Jadid ({getCurrentCalendarYear(1, 12)})
                </Checkbox>
              ),
            },
            {
              property: 'taxStatement',
              isCheckbox: true,
              style: { marginBottom: 0 },
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>3-Year Tax Statement</Checkbox>
              ),
            },
            {
              property: 'tajnid',
              isCheckbox: true,
              initialValue: false,
              component: (
                <Checkbox disabled={loading}>Tajneed Statement</Checkbox>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

export default PaymentStatusMain;
