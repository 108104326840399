import React from 'react';
import {
  Button,
  message,
  InputNumber,
  Select,
  Input,
  Row,
  Col,
  Divider,
  Checkbox,
  Radio,
  Card,
  Form,
} from 'antd';
import {
  formVariables,
  requiredMessages,
  validateMemberCode,
} from '../../utils/form';
import jamaatList from '../../utils/jamaat';
import { formatNumber } from '../../utils/formats';
import subscriptions from '../../utils/subscriptions';
import orders from '../../utils/orders';

const { Option } = Select;
const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 14 },
  lg: { span: 12 },
  xl: { span: 10 },
  xxl: { span: 8 },
};

const subscriptionsAndOrdersLabelCol = {
  xs: { span: 24 },
  style: {
    textAlign: 'center',
    whiteSpace: 'inherit',
    textOverflow: 'ellipsis',
  },
};
const subscriptionsAndOrdersWrapperCol = {
  xs: { span: 24 },
  style: { textAlign: 'center' },
};

/**
 * - Returns the amount selected for the selected subscriptions or orders
 *
 * @param {Object} formValues Object containing the form properties and values
 *
 * @return {number} Total donations made for the current subscriptions or orders
 */
const getTotalAmount = (formValues) => {
  let totalAmount = 0;
  // Subscriptions
  if (formValues.subscriptions && formValues.category === 'subscriptions') {
    for (let i = 0; i < Object.keys(formValues.subscriptions).length; i++) {
      const subscriptionName = Object.keys(formValues.subscriptions)[i];
      if (subscriptionName) {
        const foundSubscription = subscriptions.find(
          (x) => x.name === subscriptionName,
        );
        if (
          foundSubscription &&
          formValues.subscriptions[subscriptionName] > 0
        ) {
          totalAmount +=
            foundSubscription.price *
            formValues.subscriptions[subscriptionName];
        }
      }
    }
  } else if (formValues.orders && formValues.category === 'orders') {
    for (let i = 0; i < Object.keys(formValues.orders).length; i++) {
      const orderName = Object.keys(formValues.orders)[i];
      if (orderName) {
        const foundOrder = orders.find((x) => x.name === orderName);
        if (foundOrder && formValues.orders[orderName] > 0) {
          totalAmount += foundOrder.price * formValues.orders[orderName];
        }
      }
    }
  }
  return Math.round(totalAmount * 100) / 100;
};

/**
 * @param {Object} subscriptions subscriptions object from the form validation
 * @returns True if the user has selected more than 1 subscription;
 */
const invalidSubscriptionSelection = (subscriptionsObj) => {
  let numberOfSubscriptions = 0;
  for (let i = 0; i < Object.keys(subscriptionsObj).length; i++) {
    const key = Object.keys(subscriptionsObj)[i];
    if (subscriptionsObj[key] === true) {
      numberOfSubscriptions += 1;
    }
  }
  return numberOfSubscriptions > 1;
};

function SubscriptionsAndOrdersForm({
  handleSubmit,
  subscriptionsAndOrders_form,
}) {
  const [form] = Form.useForm();

  const handleCategoryChange = () => {
    form.setFieldsValue({
      frequency: 1,
      subscriptions: [],
      orders: [],
    });
  };

  const handleSubscriptionCheckbox = (checked, subscriptionName) => {
    if (checked) {
      const otherSubscriptions = subscriptions.filter(
        (x) => x.name !== subscriptionName,
      );
      const newSubscriptions = {
        [subscriptionName]: true,
      };
      for (let i = 0; i < otherSubscriptions.length; i++) {
        newSubscriptions[otherSubscriptions[i].name] = false;
      }
      form.setFieldsValue({ subscriptions: newSubscriptions });
    }
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        // Passing just the selected subscriptions
        const newSubscriptions = {};
        if (values.subscriptions) {
          for (let i = 0; i < Object.keys(values.subscriptions).length; i++) {
            const key = Object.keys(values.subscriptions)[i];
            if (values.subscriptions[key] === true) {
              newSubscriptions[key] = true;
            }
          }
        }
        // Passing just the selected orders
        const newOrders = {};
        if (values.orders) {
          for (let i = 0; i < Object.keys(values.orders).length; i++) {
            const key = Object.keys(values.orders)[i];
            if (values.orders[key] > 0) {
              newOrders[key] = values.orders[key];
            }
          }
        }
        const formValuesObj = {
          category: values.category,
          frequency: values.frequency,
          payer_name: values.payer_name,
          jamaat: values.jamaat,
          member_code: values.member_code,
          comment: values.comment,
          subscriptions: newSubscriptions,
          orders: newOrders,
        };

        const totalAmount = getTotalAmount(formValuesObj);

        console.log('Ordered values of form:', formValuesObj);
        if (totalAmount > 0) {
          if (totalAmount > 75000) {
            message.destroy();
            message.error('The maximum total amount is US$ 75,000.00');
            return;
          }

          if (invalidSubscriptionSelection(formValuesObj.subscriptions)) {
            message.destroy();
            message.error('Select just one subscription');
            return;
          }
          handleSubmit(formValuesObj, totalAmount);
        } else {
          message.destroy();
          message.error(
            `You have not entered any ${
              formValuesObj.category === 'subscriptions'
                ? 'subscription'
                : 'orders'
            }`,
          );
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  return (
    <Form
      form={form}
      className="chandas-form"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      initialValues={subscriptionsAndOrders_form}
      scrollToFirstError
    >
      <div>
        <Row
          type="flex"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            background: '#fbfbfb',
            border: '1px solid #d9d9d9',
            borderRadius: '6px',
            marginBottom: 12,
          }}
        >
          <Col lg={14} md={18} sm={22} xs={24}>
            <Form.Item
              name={formVariables.payer_name.property}
              label={formVariables.payer_name.label}
              rules={[
                {
                  required: true,
                  message: requiredMessages(formVariables.payer_name.property),
                },
                { max: 64, message: 'Maximum characters allowed are 64' },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              name={formVariables.jamaat.property}
              label={formVariables.jamaat.label}
              rules={[
                {
                  required: true,
                  message: requiredMessages(formVariables.jamaat.property),
                },
              ]}
            >
              <Select
                showSearch
                autoComplete="none"
                optionFilterProp="children"
                showAction={['focus', 'click']}
              >
                {jamaatList.map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={formVariables.member_code.property}
              label={formVariables.member_code.label}
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: requiredMessages(formVariables.member_code.property),
                },
                {
                  validator: (rules, values, callback) =>
                    callback(validateMemberCode(values)),
                },
              ]}
            >
              <InputNumber
                className="input-number-no-stepper"
                autoComplete="off"
                style={{ width: 90 }}
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col xs={24} align="center">
            <Form.Item
              name="category"
              labelCol={subscriptionsAndOrdersLabelCol}
              wrapperCol={subscriptionsAndOrdersWrapperCol}
              hidden
              initialValue="subscriptions"
            >
              <Radio.Group buttonStyle="solid" onChange={handleCategoryChange}>
                <Radio.Button value="subscriptions">Subscriptions</Radio.Button>
                <Radio.Button value="orders">Orders</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div
              align="center"
              style={{ paddingBottom: 12, color: 'orangered' }}
            >
              Only one subscription per transaction is allowed
            </div>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevState, newState) =>
              prevState.category !== newState.category
            }
          >
            {() => {
              if (form.getFieldValue('category') === 'subscriptions') {
                return (
                  <>
                    <Form.Item
                      key="frequency"
                      name="frequency"
                      initialValue={1}
                      noStyle
                      hidden
                    >
                      <Select style={{ width: 150 }}>
                        <Option value={1}>1 year</Option>
                      </Select>
                    </Form.Item>
                    {subscriptions.map((x) => (
                      <Col xl={6} lg={8} md={12} sm={22} xs={24} key={x.name}>
                        <Form.Item
                          labelCol={subscriptionsAndOrdersLabelCol}
                          wrapperCol={subscriptionsAndOrdersWrapperCol}
                          style={{ padding: '0px 12px' }}
                        >
                          <Card
                            actions={[
                              <Form.Item
                                name={['subscriptions', x.name]}
                                valuePropName="checked"
                                noStyle
                              >
                                <Checkbox
                                  onChange={(e) =>
                                    handleSubscriptionCheckbox(
                                      e.target.checked,
                                      x.name,
                                    )
                                  }
                                >
                                  {`US$ ${x.price} / year`}
                                </Checkbox>
                              </Form.Item>,
                            ]}
                          >
                            <Card.Meta
                              title={
                                <div style={{ whiteSpace: 'break-spaces' }}>
                                  {x.label}
                                </div>
                              }
                              description={x.description}
                            />
                          </Card>
                        </Form.Item>
                      </Col>
                    ))}
                  </>
                );
              }
              if (form.getFieldValue('category') === 'orders') {
                return orders.map((x) => (
                  <Col xl={6} lg={8} md={12} sm={22} xs={24} key={x.name}>
                    <Form.Item
                      labelCol={subscriptionsAndOrdersLabelCol}
                      wrapperCol={subscriptionsAndOrdersWrapperCol}
                      style={{ padding: '0px 12px' }}
                    >
                      <Card
                        actions={[
                          <Form.Item
                            labelCol={subscriptionsAndOrdersLabelCol}
                            wrapperCol={subscriptionsAndOrdersWrapperCol}
                            name={['orders', x.name]}
                            style={{ padding: '0px 12px', marginBottom: 0 }}
                            label="Quantity"
                            extra={`US$ ${x.price} ${x.per_unit}`}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              style={{ width: 150 }}
                            />
                          </Form.Item>,
                        ]}
                      >
                        <Card.Meta
                          title={
                            <div
                              style={{
                                textAlign: 'center',
                                whiteSpace: 'break-spaces',
                              }}
                            >
                              {x.label}
                            </div>
                          }
                          description={x.description}
                        />
                      </Card>
                    </Form.Item>
                  </Col>
                ));
              }
              return null;
            }}
          </Form.Item>
        </Row>
      </div>
      <Row justify="center" style={{ paddingTop: 24 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={8}>
          <Form.Item
            labelCol={subscriptionsAndOrdersLabelCol}
            wrapperCol={subscriptionsAndOrdersWrapperCol}
            label="Leave a comment (optional)"
            name={formVariables.comment.property}
            rules={[
              { max: 150, message: 'Maximum characters allowed are 150' },
            ]}
            extra={
              <Form.Item
                noStyle
                shouldUpdate={(prev, cur) =>
                  prev[formVariables.comment.property] !==
                  cur[formVariables.comment.property]
                }
              >
                {() => {
                  const commentText = form.getFieldValue(
                    formVariables.comment.property,
                  );
                  return (
                    <div align="end">
                      {`${commentText ? commentText.length : 0} / 150`}
                    </div>
                  );
                }}
              </Form.Item>
            }
          >
            <Input.TextArea
              maxLength={150}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ padding: '12px 24px 0px', textAlign: 'right' }}>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              <div>
                Total
                {form.getFieldValue('category') === 'subscriptions'
                  ? ' per year'
                  : ''}
                :
              </div>
              <div
                style={{ padding: '0px 4px', fontWeight: 'bold', fontSize: 16 }}
              >
                {`US$ ${formatNumber(
                  Number(getTotalAmount(form.getFieldsValue())).toFixed(2),
                )}`}
              </div>
            </>
          )}
        </Form.Item>
        <Button htmlType="submit" type="primary" onClick={handleCreate}>
          Next
        </Button>
      </div>
    </Form>
  );
}

export default SubscriptionsAndOrdersForm;
