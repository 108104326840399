const orders = [
  {
    name: 'homeopathic_medicine',
    label: 'Homeopathic Medicine',
    price: 10,
    description: 'For the homeopathic medicines ordered.',
    per_unit: 'per unit',
  },
];

export const getOrderLabel = (name) => {
  const foundOrder = orders.find((x) => x.name === name);
  return foundOrder ? foundOrder.label : '';
};
export const getOrderPrice = (name) => {
  const foundOrder = orders.find((x) => x.name === name);
  return foundOrder ? foundOrder.price : '';
};

export default orders;
