/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* import { FilePdfOutlined } from '@ant-design/icons'; */
import React from 'react';
import './TajneedMain.css';

const TajneedMain = () => (
  <div className="tajneed-main">
    <h1 align="center">Tajneed</h1>
    <h3 style={{ textAlign: 'center' }}>
      DEPARTMENTS / LAST UPDATED: 24 SEPTEMBER 2018
    </h3>

    <div className="tajneed-section">
      <h3>Adding / Updating Tajneed Information</h3>
      <p>
        Keeping an up-to-date tajneed information is very important for many
        reasons. It allows:
      </p>
      <ol>
        <li>
          Center to share important information about events, campaigns, etc.
          with the membership.
        </li>
        <li>
          Local and National offices to plan and register for events like Jalsa,
          etc. based on more accurate information.
        </li>
        <li>
          Finance Secretaries to create an accurate budget for the coming years,
          which helps center plan for various projects and services.
        </li>
        <li>
          Members to pay their chanda and get it properly recorded and accounted
          for.
        </li>
      </ol>
    </div>

    <div className="tajneed-section">
      <h3>How to Add/Update Tajneed Information</h3>
      <p style={{ fontWeight: 'bold' }}>
        There are several ways you can add / update Tajneed information.
      </p>
      <div>
        Tajneed Forms:
        <ul>
          <li>
            <strong>(1) T1 New/Change Membership Information</strong> (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSe0B3fgdb4xtIJReDoQZ8cUHj4rzMkNLtyRAlT2yoT2vWUu1g/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T1 Online
            </a>
            )
          </li>
          <li>
            <strong>(2) T2 Change of Address Information</strong> (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSed_dkxBv-yo5_2Sf1FV-Uhk8dnDYnyUemJvYw0NrBJvYHH3Q/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T2 Online
            </a>
            )
          </li>
          <li>
            <strong>(3) T3 Newborn Info</strong> (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScYZmt5g368R_nB_d2W8EMFR-QdLw6mMLFNtMP8emE4VTe6OQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T3 Online
            </a>
            )
          </li>
          <li>
            <strong>(4) T4 Bai'at/Tajneed Form</strong> (
            <a
              href="https://docs.google.com/forms/d/16VF0c4DAI8TKj8bsYWWEs91uc6KHN2DWHDmTH46f05E/viewform?ts=63b48be5&edit_requested=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T4 Online
            </a>
            )
          </li>
        </ul>
      </div>
    </div>

    <div className="tajneed-section">
      <h3>How to order Jamaat ID Cards</h3>
      <div>
        Jamaat ID Cards Forms:
        <ul>
          <li>
            <strong>(7-A) T7 Order Jamaat ID Card</strong> (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdDq0cqCUJgRiuHsGL_aIGm1vneeNMoOEcW21oXKZ5B6g8-qw/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T7 Online - <span className="tag__men-only">Men Only</span>
            </a>
            )
          </li>
          <li>
            <strong>(7-B) T7 Order Jamaat ID Card</strong> (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScXrKKIi9dMdIBNLJ8pCWUQb1tNVkn5g63Ez2oi--UYiJH9uw/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit T7 Online -{' '}
              <span className="tag__women-only">Women Only</span>
            </a>
            )
          </li>
        </ul>
      </div>
      <div>
        Notes about the pictures:
        <ul>
          <li>Photo size should be the 3 by 4 portrait.</li>
          <li>Background Should be white</li>
          <li>Female members should have their heads covered</li>
          <li>
            All photos should be facing forward and looking straight at the
            camera with a neutral expression, and closed mouth, without a red
            eye.
          </li>
          <li>
            Lighting should be even and no shadow should be visible on the
            picture.
          </li>
          <li>
            If the applicant uses glasses (for eyeshight only) it is recommended
            to have them also in the photo.
          </li>
          <li>
            Photos should be in the natural condition i.e. must not be enhanced
            or modified by any photo editing program.
          </li>
        </ul>
      </div>
    </div>

    <div className="tajneed-section">
      <h3>How to Fill the Tajneed Forms:</h3>
      <ol>
        <li>
          All Tajneed Forms are available Online and can be submitted Online by
          member/applicant/office holder
        </li>
        <li>
          Make sure important information is completely filled out, e.g. Jamaat
          Name, AMI Code of Head of Family (in case of changes), Contact Phone
          Numbers
        </li>
        <li>
          We require that local Finance Secretary or President approve/verify
          all new Member registration requests. therefore make sure to provide
          current and previous Jamaat name, president/ameer names and contact
          info.
        </li>
        <li>
          Make sure to enter your email address and cell phone number, so that
          its easy for us to contact you in case we need more information or any
          clarification. Otherwise, there might be additional delays in
          processing your request.
        </li>
        <li>
          Whenever you fill out and submit a tajneed form online, expect to
          receive a confirmation email with a reference number. If you dont
          receive it, then there might be some problems in submitting the form
          and we did not receive that information either. So please try again.
        </li>
        <li>
          Do not leave any field empty. Write Not applicable in fields that are
          not applicable to your request.
        </li>
        <li>
          In case of new converts, we need both the tajneed form (online) and
          Bai'at form properly filled out and attested from local Jamaat.
        </li>
        <li>
          In case of Ahmadi members moving from other countries to US, we need
          complete information about their previous Jamaat/Country for
          verification purposes. Absence of such information will only result in
          processing delays.
        </li>
      </ol>
    </div>

    <div className="tajneed-section">
      <h3>Submit Tajneed Requests by Mail:</h3>
      <p>
        Please send completely filled out forms to the headquarter at this
        address:
      </p>
      <div>National Secretary Finance</div>
      <div>Attn: Tajneed Additions/Changes</div>
      <div>Ahmadiyya Movement In Islam</div>
      <div>15000 Good Hope Road</div>
      <div>Silver Spring, MD 20905</div>
    </div>

    <div className="tajneed-section">
      <h3>Submit Tajneed Requests by Email:</h3>
      <p>
        You can also scan the filled out forms and email us at{' '}
        <a href="mailto:tajneed@ahmadiyya.us">tajneed@ahmadiyya.us</a>
      </p>
    </div>

    <div className="tajneed-section">
      <h3>Submit Tajneed Requests Online:</h3>
      <p>
        You can fill out these Tajneed forms online by clicking the links above.
        This way, you would not need to print them out or mail/fax them
        anywhere. Once you submit these forms online, they will automatically be
        sent to Tajneed section at the headquarter. You will also be notified
        and once these forms are processed, you will be informed by phone/email.
      </p>
    </div>
  </div>
);

export default TajneedMain;
