/* eslint-env browser */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BarsOutlined, CloseOutlined, SelectOutlined } from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import Media from 'react-media';
import Dashboard from './Dashboard';
import mainLogo from '../assets/images/main_logo.png';
import routes from '../routes';
import bismillahImg from '../assets/images/bismillah.png';
import { loadForteScript } from '../services/forte';
import WelcomeModal from '../components/Onboarding/WelcomeModal';

const { Header, Content, Footer, Sider } = Layout;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  componentDidMount() {
    loadForteScript();
    this.handleCollapse();
    setTimeout(() => {
      this.scrollToTop();
    }, 500);
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.scrollToTop();
      return 'location-changed';
    }
    return null;
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleMenuClick = ({ key }) => {
    if (key[0] === '/') {
      const { history } = this.props;
      const { mobile } = this.state;
      history.push(key);
      if (mobile) {
        this.setState({ collapsed: true });
      }
    }
  };

  handleCollapse = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    const { collapsed, mobile } = this.state;
    const { history, location } = this.props;
    const selectedKeys = [location.pathname];
    return (
      <Layout>
        <Media
          query="(max-width: 600px)"
          onChange={(matches) => this.setState({ mobile: matches })}
        />
        <WelcomeModal />
        <Sider
          id="sidebar-tour-step-1"
          breakpoint="lg"
          width={mobile ? '100%' : 225}
          collapsedWidth={mobile ? 0 : 80}
          onCollapse={this.handleCollapse}
          collapsed={mobile ? collapsed : false}
          trigger={null}
          style={{
            background: 'black',
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            zIndex: 10,
          }}
        >
          {!collapsed && mobile && (
            <div id="sidebar-closeDrawer-onPhone">
              <CloseOutlined
                style={{ color: '#fff', padding: 12, fontSize: 24 }}
                onClick={this.handleCollapse}
              />
            </div>
          )}
          <div style={{ padding: '0px 16px 16px' }}>
            <Link to="/" onClick={() => history.push('/')}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={mainLogo}
                  style={{ width: mobile ? '60%' : '100%' }}
                  alt="Ahmadiyya Movement In Islam"
                />
                <h3 style={{ color: '#fff' }}>Finance Portal</h3>
              </div>
            </Link>
          </div>
          <Menu
            theme="dark"
            style={{
              background: 'black',
              padding: '24px 0px 48px',
            }}
            mode="inline"
            selectedKeys={selectedKeys}
            onClick={this.handleMenuClick}
            items={[
              { key: routes.pay_chanda, label: 'Pay Chanda' },
              {
                key: routes.tj_on_behalf_relatives,
                label: 'Tehrik-e-Jadid Daftar',
              },
              { key: routes.calculate_chanda, label: 'Calculate Chanda' },
              { key: routes.loans, label: 'Pay Loans' },
              { key: routes.subscriptions_and_orders, label: 'Subscriptions' },
              {
                key: routes.modify_recurring_donation,
                label: 'Modify Recurring Status',
              },
              {
                key: 'a-budget',
                label: (
                  <a
                    href="https://budget.amchq.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submit Your Budget <SelectOutlined />
                  </a>
                ),
              },
              {
                key: routes.get_payment_status,
                label: 'Get Your Payment Status',
              },
              {
                key: routes.minaratul_masih_donors,
                label: 'Minaratul Masih Donors',
              },
              {
                key: 'a-chanda',
                label: (
                  <a
                    href="https://chanda.mkausa.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Khuddam Chanda <SelectOutlined />
                  </a>
                ),
              },
              {
                key: 'a-amanat',
                label: (
                  <a
                    href="https://amanat.ansarusa.org/donate"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ansar Chanda <SelectOutlined />
                  </a>
                ),
              },
              { key: routes.resources, label: 'Resources' },
              { key: routes.tajneed, label: 'Tajneed' },
              { key: routes.contact_us, label: 'Contact Us' },
            ]}
          />
        </Sider>
        <Layout style={{ marginLeft: mobile ? 0 : 225 }}>
          {mobile && (
            <Header
              style={{
                position: 'fixed',
                zIndex: 9,
                width: '100%',
                padding: '0px 40px',
              }}
            >
              <div style={{ width: 0, height: 0 }}>
                <Button
                  type="link"
                  onClick={this.handleCollapse}
                  style={{
                    position: 'relative',
                    left: -24,
                    top: 4,
                    padding: 0,
                  }}
                >
                  <BarsOutlined style={{ color: 'white', fontSize: 22 }} />
                </Button>
              </div>
              <div>
                <div
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    marginTop: -12,
                    height: 24,
                  }}
                >
                  بِسۡمِ اللّٰہِ الرَّحۡمٰنِ الرَّحِیۡمِِ
                </div>
                <div
                  style={{
                    fontSize: '0.9em',
                    textAlign: 'center',
                    color: '#d6d6d6',
                    fontWeight: 'bold',
                    height: 32,
                  }}
                >
                  AHMADIYYA MOVEMENT IN ISLAM
                </div>
              </div>
            </Header>
          )}
          <Content
            style={{
              padding: mobile ? 0 : '16px',
              marginTop: mobile ? 64 : 0,
              overflow: 'initial',
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
            }}
          >
            <div style={{ paddingTop: mobile ? 16 : 0 }}>
              <div
                style={{
                  backgroundImage: `url(${bismillahImg})`,
                  height: 30,
                  width: 100,
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  margin: 'auto',
                  display: mobile ? 'none' : 'inherit',
                }}
              />
              <Dashboard />
            </div>
            <Footer style={{ textAlign: 'center' }}>
              {`Ahmadiyya Movement in Islam ©${new Date().getFullYear()} United States of America`}
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default Main;
