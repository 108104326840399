/* eslint-disable no-alert */
/* eslint-env browser */

/**
 * Inserts the Forte.js SDK. For more information: https://www.forte.net/devdocs/forte.js/forte.js.htm
 * @param {Function} callback Callback after the script finishes loading
 */
// eslint-disable-next-line import/prefer-default-export
export const loadForteScript = (callback) => {
  const scriptId = 'fortejs_sdk';
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      process.env.REACT_APP_BUILD_ENV === 'production'
        ? 'https://api.forte.net/js/v1'
        : 'https://sandbox.forte.net/api/js/v1';
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const forteErrors = [
  {
    value:
      'The browser you are using is not supported. Fortejs supports the latest versions of the following browsers: Internet Explorer, Firefox, Chrome, and Safari.',
    message:
      'The browser you are using is not supported. Please use the latest versions of the following browsers: Edge, Firefox, Chrome, and Safari.',
  },
  {
    value:
      'The browser you are using is not supported. Please download the latest version of your browser to use Fortejs.',
    message:
      'The browser you are using is not supported. Please download the latest version of your browser.',
  },
  {
    value: 'The api_login_id is required.', // Action required
    message: "We couldn't verify your information. Please try again later",
  },
  {
    value: 'The expire_month is required.',
    message: 'The expiration month is required',
  },
  {
    value: 'The account_number is required.',
    message: 'The account number is required.',
  },
  {
    value: 'The routing_number is required.',
    message: 'The routing number is required.',
  },
  {
    value: 'The routing_number is invalid.',
    message: 'The routing number is invalid.',
  },
  {
    value: 'The account_type is required.',
    message: 'The account type is required.',
  },
  {
    value: 'The account_type is invalid.',
    message: 'The account type is invalid.',
  },
  {
    value: 'The expire_year is required.',
    message: 'The expiration year is required.',
  },
  {
    value: 'The cvv is required.',
    message: 'The CVV is required.',
  },
  {
    value: 'The card_number is invalid.',
    message: 'The card number is invalid.',
  },
  {
    value: 'The cvv is invalid.',
    message: 'The CVV is invalid.',
  },
  {
    value: 'The expire_month and expire_year are invalid.',
    message: 'The expiry month and expiry year are invalid.',
  },
  {
    value: 'Invalid authentication.',
    message: 'Invalid authentication.',
  },
];

/**
 * Get one time token in exchange of payment information
 * @param {Object} param Body
 * @param {String} card_number
 * @param {String} expire_year
 * @param {String} expire_month
 * @param {String} cvv
 * @param {String} account_number
 * @param {String} routing_number
 * @param {String} account_type "c" = Checking or "s" = Savings
 * @param {String} sec_code Standard Entry Class Code
 */
export const getForteOneTimeToken = ({
  card_number,
  expire_year,
  expire_month,
  cvv,
  account_number,
  routing_number,
  account_type,
  sec_code,
}) =>
  new Promise((resolve, reject) => {
    if (!window.forte || typeof window.forte.createToken !== 'function') {
      return reject(new Error('Forte SDK not found'));
    }
    return window.forte
      .createToken({
        api_login_id: '20E18FD4B1',
        card_number,
        expire_year,
        expire_month,
        cvv,
        account_number,
        routing_number,
        account_type,
        sec_code,
      })
      .success((res) => {
        if (process.env.REACT_APP_BUILD_ENV !== 'production') {
          console.log('forte.createToken: ', res);
          console.log('The onetime_token created: ', res.onetime_token);
        }
        resolve({ forteRes: res });
      })
      .error((err) => {
        console.log(err);
        if (process.env.REACT_APP_BUILD_ENV !== 'production') {
          console.log(
            'Getting onetime_token failed: ',
            err.response_description,
          );
        }
        const foundError = forteErrors.find(
          (x) => x.value === err.response_description,
        );
        resolve({
          error: true,
          message: foundError
            ? foundError.message
            : 'There was an error why validating your payment method. Please, try again later.',
        });
      });
  });
