import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Divider,
} from 'antd';
import {
  formVariables,
  requiredMessages,
  validateMemberCode,
  validatePIN,
} from '../../utils/form';
import { formatNumber } from '../../utils/formats';
import RelativesTable from '../../components/Relatives/RelativesTable';
import { getMemberRelatives } from '../../services/actions';

const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 14 },
  lg: { span: 12 },
  xl: { span: 10 },
  xxl: { span: 8 },
};

const relativesLabelCol = {
  xs: { span: 24 },
  style: {
    textAlign: 'center',
    whiteSpace: 'inherit',
    textOverflow: 'ellipsis',
  },
};
const relativesWrapperCol = {
  xs: { span: 24 },
  style: { textAlign: 'center' },
};

/**
 * - Returns the amount selected for the current relatives
 *
 * @param {Object} formValues Object containing the form properties and values
 *
 * @return {number} Total donations made on behalf of relatives
 */
const getTotalAmount = (formValues) => {
  if (Array.isArray(formValues.relatives)) {
    return Math.round(
      formValues.relatives.reduce((acc, relative) => {
        if (!Number.isNaN(Number(relative.amount))) {
          return acc + Number(relative.amount);
        }
        return acc;
      }, 0),
    );
  }
  return 0;
};

function TJForm({ handleSubmit, relatives_form }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [relatives, setRelatives] = useState(null);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    if (relatives_form.relatives && relatives_form.relatives.length > 0) {
      setRelatives(relatives_form.relatives);
    }
    setPayload(relatives_form.payload || null);
    return () => {};
  }, [relatives_form]);

  const handleCreate = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log('Values of form:', values);
        if (relatives === null) {
          setLoading(true);
          const data = await getMemberRelatives(
            values[formVariables.member_code.property],
            values[formVariables.pin.property],
          );
          console.log('data from GET relatives', data);
          setLoading(false);
          setPayload(data === false ? null : data);
          setRelatives(
            data === false
              ? null
              : data.members.map((member) => ({
                  id: member.id,
                  member_code: member.obo_code,
                  payer_name: member.full_name,
                  relation: member.relationship,
                  deceased: member.is_deceased,
                  daftar: member.daftar,
                })),
          );
        } else {
          const totalAmount = getTotalAmount(values);
          console.log('should submit!', totalAmount);
          if (relatives.filter((x) => !!x.member_code).length === 0) {
            message.destroy();
            message.error('Unable to proceed with the donation');
            return;
          }
          if (totalAmount > 0) {
            if (totalAmount > 75000) {
              message.destroy();
              message.error('The maximum total amount is US$ 75,000.00');
              return;
            }
            const newRelatives = relatives.map((relative, relativeIdx) => ({
              ...relative,
              amount: values.relatives[relativeIdx].amount,
            }));
            handleSubmit(
              { ...values, relatives: newRelatives, payload },
              totalAmount,
            );
          } else {
            message.destroy();
            message.error('You have not entered any amount');
          }
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  return (
    <Form
      form={form}
      className="chandas-form"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      initialValues={relatives_form}
      scrollToFirstError
    >
      <div>
        <Row
          style={{
            padding: 24,
            background: '#fbfbfb',
            border: '1px solid #d9d9d9',
            borderRadius: '6px',
            marginBottom: 12,
          }}
        >
          <Col xs={24}>
            <Row
              type="flex"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Col lg={14} md={18} sm={22} xs={24}>
                <h3 style={{ textAlign: 'center' }}>
                  Enter Your Member Code and PIN
                </h3>
                <Form.Item
                  name={formVariables.member_code.property}
                  label={formVariables.member_code.label}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(
                        formVariables.member_code.property,
                      ),
                    },
                    {
                      validator: (rules, values, callback) =>
                        callback(validateMemberCode(values)),
                    },
                  ]}
                >
                  <InputNumber
                    className="input-number-no-stepper"
                    autoComplete="off"
                    style={{ width: 90 }}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={14} md={18} sm={22} xs={24}>
                <Form.Item
                  name={formVariables.pin.property}
                  label={formVariables.pin.label}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(formVariables.pin.property),
                    },
                    {
                      validator: (rules, values, callback) =>
                        callback(validatePIN(values)),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    style={{ width: 90 }}
                    disabled={
                      loading || (relatives !== null && relatives.length > 0)
                    }
                    maxLength={4}
                  />
                </Form.Item>
              </Col>
              {relatives === null ? (
                <Col
                  lg={14}
                  md={18}
                  sm={22}
                  xs={24}
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={handleCreate}
                    loading={loading}
                  >
                    Retrieve Information
                  </Button>
                </Col>
              ) : (
                <>
                  <Divider style={{ marginTop: 0 }} />
                  <RelativesTable data={relatives} />
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <Row justify="center" style={{ paddingTop: 24 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={8}>
          <Form.Item
            labelCol={relativesLabelCol}
            wrapperCol={relativesWrapperCol}
            label="Leave a comment (optional)"
            name={formVariables.comment.property}
            rules={[
              { max: 150, message: 'Maximum characters allowed are 150' },
            ]}
            extra={
              <Form.Item
                noStyle
                shouldUpdate={(prev, cur) =>
                  prev[formVariables.comment.property] !==
                  cur[formVariables.comment.property]
                }
              >
                {() => {
                  const commentText = form.getFieldValue(
                    formVariables.comment.property,
                  );
                  return (
                    <div align="end">
                      {`${commentText ? commentText.length : 0} / 150`}
                    </div>
                  );
                }}
              </Form.Item>
            }
          >
            <Input.TextArea
              maxLength={150}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </Form.Item>
        </Col>
      </Row>
      {relatives === null ? null : (
        <div style={{ padding: '12px 24px 0px', textAlign: 'right' }}>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <>
                <div>Total:</div>
                <div
                  style={{
                    padding: '0px 4px',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  {`US$ ${formatNumber(
                    Number(getTotalAmount(form.getFieldsValue())).toFixed(2),
                  )}`}
                </div>
              </>
            )}
          </Form.Item>
          <Button htmlType="submit" type="primary" onClick={handleCreate}>
            Next
          </Button>
        </div>
      )}
    </Form>
  );
}

export default TJForm;
