/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable quotes */
import React from 'react';
import { Row, Col, Divider, Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils/formats';

const { Paragraph, Text } = Typography;

const getRecurrentFrequencyLabel = (value) =>
  ({
    weekly: 'weekly',
    bi_weekly: 'biweekly',
    semi_monthly: 'twice-a-month',
    monthly: 'monthly',
    quarterly: 'quarterly',
  }[value]);

export default function Receipt({
  clearData,
  tryAgain,
  transaction_errors = [],
  response,
  avoidResubmit,
}) {
  const {
    transaction_id,
    total_amount,
    fee_amount,
    receipts,
    payment_method,
    email,
    date,
    isRecurrent,
    recurrence_startDate,
    recurrence_endDate,
    recurrence_frequency,
  } = response || {};
  return (
    <div>
      <Result
        status={transaction_errors.length === 0 ? 'success' : 'error'}
        title={
          transaction_errors.length === 0
            ? 'Successful Transaction'
            : 'Transaction Failed'
        }
        subTitle={
          transaction_errors.length === 0
            ? 'JazakAllah Ahsanal Jaza. An email with a copy of your donation receipt(s) will be sent shortly.'
            : avoidResubmit
            ? "Unfortunately we couldn't complete the transaction. Please follow the instructions below to resolve this. Sorry for the inconvenience."
            : 'Please check that the payment information is valid before resubmitting.'
        }
        extra={
          avoidResubmit
            ? []
            : [
                <Button
                  key="buy"
                  type="primary"
                  onClick={
                    transaction_errors.length === 0 ? clearData : tryAgain
                  }
                >
                  {transaction_errors.length === 0
                    ? 'Make another donation'
                    : 'Try again'}
                </Button>,
              ]
        }
      >
        {transaction_errors.length > 0 && (
          <div>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                An error occurred while processing your request
              </Text>
            </Paragraph>
            {transaction_errors.map((x) => (
              <Paragraph key={x}>
                <CloseCircleOutlined style={{ color: 'red' }} /> {x}
              </Paragraph>
            ))}
          </div>
        )}
      </Result>
      {transaction_id && (
        <div
          style={{ background: 'white', padding: '24px 24px' }}
          id="printable"
        >
          <h2 align="center">Donation Transcript</h2>
          <Row>
            <Col md={12} xs={24}>
              <div>
                Transaction ID: <strong>{transaction_id}</strong>
              </div>
              <div>
                Payment method: <strong>{payment_method}</strong>
              </div>
              <div>
                Total:{' '}
                <strong>
                  {`$ ${formatNumber(Number(total_amount).toFixed(2))}`}
                </strong>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div>
                Date: <strong>{moment(date).format('lll')}</strong>
              </div>
              <div>
                Email: <strong>{email}</strong>
              </div>
              <div>
                Fees:{' '}
                <strong>
                  {`$ ${formatNumber(Number(fee_amount).toFixed(2))}`}
                </strong>
              </div>
            </Col>
            {isRecurrent && (
              <Col xs={24} style={{ paddingTop: 24 }}>
                {`
                  This is a recurring donation that will be executed ${getRecurrentFrequencyLabel(
                    recurrence_frequency,
                  )}, 
                  starting on ${recurrence_startDate} until ${
                  recurrence_endDate || 'you request to stop it'
                }.
                `}{' '}
                You can change your recurring donation status by going into the{' '}
                <Link to={`/modify?reference_id=${transaction_id}`}>
                  Modify Donation Status
                </Link>{' '}
                section or simply email us at{' '}
                <a href="mailto:finance@ahmadiyya.us">finance@ahmadiyya.us</a>.
              </Col>
            )}
            <Col xs={24} style={{ textAlign: 'center', paddingTop: 24 }}>
              <p>
                {'Got a question? Email us at '}
                <a href="mailto:finance@ahmadiyya.us">finance@ahmadiyya.us</a>
                {' or give us a call at +301-879-0110'}
              </p>
              <div>Finance Department - Ahmadiyya Movement In Islam</div>
              <div>
                15000 Good Hope Road, Silver Spring, Maryland, US, 20905
              </div>
            </Col>
            {receipts.map((receipt) => (
              <Col xs={24} key={receipt.receipt_no}>
                <Divider />
                <h3 align="center" style={{ textDecoration: 'underline' }}>
                  Donation Receipt
                </h3>
                <Row>
                  <Col md={12} xs={24}>
                    <div>
                      Receipt No: <strong>{receipt.receipt_no}</strong>
                    </div>
                    <div>
                      Member Code: <strong>{receipt.amicode}</strong>
                    </div>
                    <div>
                      Branch: <strong>{receipt.branch_name}</strong>
                    </div>
                  </Col>
                  <Col md={12} xs={24}>
                    <div>
                      Amount:{' '}
                      <strong>
                        {`$ ${formatNumber(
                          Number(receipt.total_amt).toFixed(2),
                        )}`}
                      </strong>
                    </div>
                    <div>
                      Member Name: <strong>{receipt.name}</strong>
                    </div>
                    <div>
                      IRS EIN: <strong>52-6054158</strong>
                    </div>
                  </Col>
                  <Col md={12} xs={24} style={{ paddingTop: 24 }}>
                    <p>
                      <strong>Donation</strong>
                    </p>
                    {receipt.details.map((detail) => (
                      <p>{detail.donation_type}</p>
                    ))}
                  </Col>
                  <Col
                    md={12}
                    xs={24}
                    style={{ textAlign: 'right', paddingTop: 24 }}
                  >
                    <p>
                      <strong>Amount</strong>
                    </p>
                    {receipt.details.map((detail) => (
                      <p>
                        {`$ ${formatNumber(
                          Number(detail.donation_amount).toFixed(2),
                        )}`}
                      </p>
                    ))}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <div
            align="center"
            className="only_print"
            style={{ color: 'gray', fontSize: 12 }}
          >
            {`Ahmadiyya Movement in Islam ©${new Date().getFullYear()} United States of America`}
          </div>
        </div>
      )}
    </div>
  );
}
