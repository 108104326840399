const subscriptions = [
  {
    name: 'review_of_religions',
    label: 'Review of Religions',
    description: '12 editions per year, issued monthly',
    price: 30,
  },
  {
    name: 'review_of_religions_3yrs',
    label: 'Review of Religions (3-Years)',
    description: '12 editions per year, issued monthly for 3 years',
    price: 75,
  },
  {
    name: 'review_of_religions_student_rate', // This will be passed as "review_of_religions" to server
    label: 'Review of Religion (Student Rate)',
    description: '12 editions per year, issued monthly',
    price: 10,
  },
  {
    name: 'muslim_sunrise',
    label: 'Muslim Sunrise',
    description: '12 editions per year, issued monthly',
    price: 40,
  },
  {
    name: 'al_fazl_international',
    label: 'Al-Fazl International',
    description: '100 editions per year, issued twice weekly',
    price: 100,
  },
  {
    name: 'aams_usa_student', // This will be passed as "students_1" to server
    label: 'AAMS USA (Students)',
    description: 'Membership Fee for AAMS members (students)',
    price: 25,
  },
  {
    name: 'aams_usa_employed', // This will be passed as "students_1" to server
    label: 'AAMS USA (Employed)',
    description: 'Membership Fee for AAMS members (Employed)',
    price: 100,
  },
  {
    name: 'awsa_usa_student',
    label: 'AWSA USA (Students)',
    description: 'Membership Fee for AWSA members (Students)',
    price: 25,
  },
  {
    name: 'awsa_usa_employed',
    label: 'AWSA USA (Employed)',
    description: 'Membership Fee for AWSA members (Employed)',
    price: 100,
  },
  {
    name: 'iaaae',
    label: 'IAAAE',
    description: 'Membership Fee for IAAAE members',
    price: 50,
  },
];

export const getSubscriptionLabel = (name) => {
  const foundSubscription = subscriptions.find((x) => x.name === name);
  return foundSubscription ? foundSubscription.label : '';
};
export const getSubscriptionPrice = (name) => {
  const foundSubscription = subscriptions.find((x) => x.name === name);
  return foundSubscription ? foundSubscription.price : '';
};

export default subscriptions;
