/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* import { FilePdfOutlined } from '@ant-design/icons'; */
import React, { useState, useEffect } from 'react';
import minarat_donors from '../../utils/minarat_donors';
import './MinaratMain.css';

const NameList = ({ names }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const midpoint = Math.ceil(names.length / 2);

  const renderNameItem = (name, index) => (
    <li key={index} className="mb-2 list-none">
      {index + 1}. {name}
    </li>
  );

  return (
    <div className="p-4">
      {isMobile ? (
        <ul type="1" className="list-none p-0">
          {names.map((name, index) => renderNameItem(name, index))}
        </ul>
      ) : (
        <div className="flex">
          <ul className="list-none m-0 w-1_2 pr-4">
            {names.slice(0, midpoint).map((name, index) => renderNameItem(name, index))}
          </ul>
          <ul className="list-none p-0 m-0 w-1_2">
            {names.slice(midpoint).map((name, index) => renderNameItem(name, index + midpoint))}
          </ul>
        </div>
      )}
    </div>
  );
};

const MinaratMain = () => (
  <div className="tajneed-main">
    <h1 align="center">Minaratul Masih Qadian Blessed Donors List</h1>
    <h3 style={{ textAlign: 'center' }}>
      These are 211 blessed souls who gave 100 rupees for Minaratul Masih Qadian (completed in December 1915)
    </h3>
    <NameList names={minarat_donors} />
  </div>
);

export default MinaratMain;
