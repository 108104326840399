import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import PayMain from './Pay/PayMain';
import ContactUsMain from './ContactUs/ContactUsMain';
import PaymentStatusMain from './PaymentStatus/PaymentStatusMain';
import CalculateChandaMain from './CalculateChanda/CalculateChandaMain';
import ModifyRecurringStatusMain from './ModifyRecurringStatus/ModifyRecurringStatusMain';
import routes from '../routes';
import ResourcesMain from './Resources/ResourcesMain';
import TajneedMain from './Tajneed/TajneedMain';
import ResourceDisplay from './Resources/ResourceDisplay';
import RegisterRelatives from './Relatives/RegisterRelatives';
import MinaratMain from './Minarat/MinaratMain';

const Dashboard = () => (
  <div style={{ flex: 1, padding: 16 }}>
    <Switch>
      <Route
        exact
        path={routes.pay_chanda}
        render={() => <PayMain onChanda />}
      />
      <Route
        exact
        path={routes.tj_on_behalf_relatives}
        render={() => <PayMain onTJ />}
      />
      <Route
        exact
        path={routes.register_relatives}
        render={() => <RegisterRelatives />}
      />
      <Route
        exact
        path={routes.calculate_chanda}
        render={() => <CalculateChandaMain />}
      />
      <Route exact path={routes.loans} render={() => <PayMain onLoans />} />
      <Route
        exact
        path={routes.subscriptions_and_orders}
        render={() => <PayMain onSubscriptionsAndOrders />}
      />
      <Route
        exact
        path={routes.modify_recurring_donation}
        render={() => <ModifyRecurringStatusMain />}
      />
      <Route
        exact
        path={routes.get_payment_status}
        render={() => <PaymentStatusMain />}
      />
      <Route
        exact
        path={`${routes.resources}/:resource_id`}
        render={() => <ResourceDisplay />}
      />
      <Route exact path={routes.resources} component={ResourcesMain} />
      <Route exact path={routes.contact_us} render={() => <ContactUsMain />} />
      <Route exact path={routes.tajneed} render={() => <TajneedMain />} />
      <Route exact path={routes.minaratul_masih_donors} render={() => <MinaratMain />} />
      <Redirect to={routes.pay_chanda} />
    </Switch>
  </div>
);

export default withRouter(Dashboard);
