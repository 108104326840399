export default [
  { value: 10, label: '1/10' },
  { value: 9, label: '1/9' },
  { value: 8, label: '1/8' },
  { value: 7, label: '1/7' },
  { value: 6, label: '1/6' },
  { value: 5, label: '1/5' },
  { value: 4, label: '1/4' },
  { value: 3, label: '1/3' },
];
