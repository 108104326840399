import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Main from './containers/Main';
import 'antd/dist/antd.css';

if (process.env.REACT_APP_BUILD_ENV === 'production') {
  console.log = function noop() {};
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/"
          render={(props) => (
            <Main history={props.history} location={props.location} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
