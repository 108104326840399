import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Input, Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import getEmailSuggestions from '../../utils/email_suggestions';

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    padding: '0px 25px 25px',
  },
};

const formItemLayout = {
  style: {
    padding: '8px 0px',
  },
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 },
    lg: { span: 9 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 10 },
    xl: { span: 6 },
    xxl: { span: 4 },
  },
};

const tailWrapperCol = {
  sm: { span: 24, offset: 0 },
  md: { span: 16, offset: 10 },
  lg: { offset: 9 },
  xl: { offset: 10 },
};

function FormWithRecaptcha({
  handleSubmit,
  title,
  subTitle,
  description,
  submitButton,
  formInputs,
  withoutRecaptcha,
  loading,
  wrappedRef,
}) {
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (wrappedRef) {
      wrappedRef({ resetForm: form.resetFields });
    }
    return () => {};
  }, []);

  const verifyRecaptcha = (success) => {
    if (success) {
      setCaptchaValidated(true);
    } else {
      setCaptchaValidated(false);
    }
  };

  const onEmailBlur = (e) => {
    if (e.target.value) {
      const newEmailSuggestion = getEmailSuggestions(e.target.value);
      setEmailSuggestion(newEmailSuggestion);
      return;
    }
    setEmailSuggestion('');
  };

  const changeEmailForSuggestion = (email) => {
    form.setFieldsValue({ email });
    setEmailSuggestion('');
  };

  const formSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form: ', values);
        if (typeof handleSubmit === 'function') {
          handleSubmit(values);
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  return (
    <div style={styles.mainContainer}>
      <h1 align="center">{title}</h1>
      <h3 style={{ textAlign: 'center' }}>{subTitle}</h3>
      {description && <h4 style={{ textAlign: 'center' }}>{description}</h4>}
      <Form
        form={form}
        style={formItemLayout.style}
        labelCol={formItemLayout.labelCol}
        wrapperCol={formItemLayout.wrapperCol}
        scrollToFirstError
      >
        {formInputs.map((input) => (
          <Form.Item
            key={input.property}
            name={input.property}
            initialValue={input.initialValue}
            valuePropName={input.isCheckbox ? 'checked' : 'value'}
            wrapperCol={
              input.isCheckbox ? tailWrapperCol : formItemLayout.wrapperCol
            }
            label={input.label}
            rules={input.rules}
            style={input.style}
            extra={
              input.getEmailSuggestion && emailSuggestion ? (
                <Button
                  style={{ padding: 0, fontSize: 14 }}
                  type="link"
                  onClick={() => changeEmailForSuggestion(emailSuggestion)}
                >
                  {`Did you mean ${emailSuggestion}?`}
                </Button>
              ) : (
                input.extra
              )
            }
          >
            {input.property === 'email' && input.getEmailSuggestion ? (
              <Input autoComplete="off" onBlur={onEmailBlur} />
            ) : (
              input.component
            )}
          </Form.Item>
        ))}
        {withoutRecaptcha ? null : (
          <div
            id="google-recaptcha-wrapper"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
              onChange={verifyRecaptcha}
            />
          </div>
        )}
        <Row
          type="flex"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Col style={{ padding: '8px 0px' }}>
            <Button
              htmlType="submit"
              loading={loading}
              disabled={withoutRecaptcha ? false : !captchaValidated}
              type="primary"
              onClick={formSubmit}
            >
              {submitButton}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FormWithRecaptcha;
