/* eslint-disable max-len */
import React from 'react';
import {
  BookOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Divider } from 'antd';
import KMFinancialIIIQuoteImg from '../assets/images/khalifatul-masih-iii-rh-on-financial-sacrifice.png';
import ExcerptFridaySermonImg from '../assets/images/excerpt-from-friday-sermon-of-hazrat-musleh-maud-ra.png';

const holy_quran = [
  {
    key: 'some-verses-from-the-holy-quran-about-financial-sacrifice',
    icon: <BookOutlined />,
    title: 'Some Verses from the Holy Quran about Financial Sacrifice',
    article: true,
    content: (
      <div>
        <p className="arabic">
          وَ اَنۡفِقُوۡا فِیۡ سَبِیۡلِ اللّٰہِ وَ لَا تُلۡقُوۡا بِاَیۡدِیۡکُمۡ
          اِلَی التَّہۡلُکَۃِ ۚۖۛ وَ اَحۡسِنُوۡا ۚۛ اِنَّ اللّٰہَ یُحِبُّ
          الۡمُحۡسِنِیۡنَ ﴿۱۹۶﴾
        </p>
        <p>
          And spend for the cause of Allah, and cast not yourselves into ruin
          with your own hands, and do good; surely, Allah loves those who do
          good (2:196)
        </p>
        <p className="arabic">
          اور اللہ کی راہ میں خرچ کرو اور اپنے ہاتھوں (اپنے تئیں) ہلاکت میں نہ
          ڈالو۔ اور احسان کرو یقیناً اللہ احسان کرنے والوں سے محبت کرتا ہے۔
        </p>
        <Divider />
        <p className="arabic">
          مَنۡ ذَا الَّذِیۡ یُقۡرِضُ اللّٰہَ قَرۡضًا حَسَنًا فَیُضٰعِفَہٗ لَہٗۤ
          اَضۡعَافًا کَثِیۡرَۃً ؕ وَ اللّٰہُ یَقۡبِضُ وَ یَبۡصُۜطُ ۪ وَ اِلَیۡہِ
          تُرۡجَعُوۡنَ ﴿۲۴۶﴾
        </p>
        <p>
          Who is it that will lend Allah a goodly loan that He may multiply it
          for him manifold? And Allah receives and enlarges, and to Him shall
          you be made to return. (2:246)
        </p>
        <p className="arabic">
          کون ہے جو اللہ کو قرضہ حسنہ دے تاکہ وہ اس کے لئے اسے کئی گنا بڑھائے۔
          اور اللہ (رزق) قبض بھی کر لیتا ہے اور کھول بھی دیتا ہے۔ اور تم اسی کی
          طرف لوٹائے جاؤ گے۔
        </p>
        <Divider />
        <p className="arabic">
          یٰۤاَیُّہَا الَّذِیۡنَ اٰمَنُوۡۤا اَنۡفِقُوۡا مِمَّا رَزَقۡنٰکُمۡ
          مِّنۡ قَبۡلِ اَنۡ یَّاۡتِیَ یَوۡمٌ لَّا بَیۡعٌ فِیۡہِ وَ لَا خُلَّۃٌ
          وَّ لَا شَفَاعَۃٌ ؕ وَ الۡکٰفِرُوۡنَ ہُمُ الظّٰلِمُوۡنَ ﴿۲۵۵﴾
        </p>
        <p>
          O ye who believe! spend out of what We have bestowed on you before the
          day comes wherein there shall be no buying and selling, nor
          friendship, nor intercession; and it is those who disbelieve that do
          wrong to themselves. (2:255)
        </p>
        <p className="arabic">
          اے وہ لوگو جو ایمان لائے ہو! خرچ کرو اس میں سے جو ہم نے تمہیں عطا کیا
          ہے پیشتر اس کے کہ وہ دن آ جائے جس میں نہ کوئی تجارت ہوگی اور نہ کوئی
          دوستی اور نہ کوئی شفاعت۔ اور کافر ہی ہیں جو ظلم کرنے والے ہیں۔
        </p>
        <Divider />
        <p className="arabic">
          مَثَلُ الَّذِیۡنَ یُنۡفِقُوۡنَ اَمۡوَالَہُمۡ فِیۡ سَبِیۡلِ اللّٰہِ
          کَمَثَلِ حَبَّۃٍ اَنۡۢبَتَتۡ سَبۡعَ سَنَابِلَ فِیۡ کُلِّ سُنۡۢبُلَۃٍ
          مِّائَۃُ حَبَّۃٍ ؕ وَ اللّٰہُ یُضٰعِفُ لِمَنۡ یَّشَآءُ ؕ وَ اللّٰہُ
          وَاسِعٌ عَلِیۡمٌ{' '}
        </p>
        <p>
          The similitude of those who spend their wealth for the cause of Allah
          is like the similitude of a grain of corn which grows seven ears, in
          each ear a hundred grains. And Allah multiplies it further for
          whomsoever He pleases; and Allah is Bountiful, All-Knowing.
        </p>
        <p className="arabic">
          ان لوگوں کی مثال جو اپنے مال اللہ کی راہ میں خرچ کرتے ہیں ایسے بیج کی
          طرح ہے جو سات بالیں اُگاتا ہو۔ ہر بالی میں سو دانے ہوں اور اللہ جسے
          چاہے (اس سے بھی) بہت بڑھا کر دیتا ہے۔ اور اللہ وسعت عطا کرنے والا
          (اور) دائمی علم رکھنے والا ہے۔
        </p>
        <Divider />
        <p className="arabic">
          اَلَّذِیۡنَ یُنۡفِقُوۡنَ اَمۡوَالَہُمۡ فِیۡ سَبِیۡلِ اللّٰہِ ثُمَّ لَا
          یُتۡبِعُوۡنَ مَاۤ اَنۡفَقُوۡا مَنًّا وَّ لَاۤ اَذًی ۙ لَّہُمۡ
          اَجۡرُہُمۡ عِنۡدَ رَبِّہِمۡ ۚ وَ لَا خَوۡفٌ عَلَیۡہِمۡ وَ لَا ہُمۡ
          یَحۡزَنُوۡنَ ﴿۲۶۳﴾
        </p>
        <p>
          They who spend their wealth for the cause of Allah, then follow not up
          what they have spent with taunt or injury, for them is their reward
          with their Lord, and they shall have no fear, nor shall they grieve.
          (2:263)
        </p>
        <p className="arabic">
          وہ لوگ جو اپنے اموال اللہ کی راہ میں خرچ کرتے ہیں، پھر جو وہ خرچ کرتے
          ہیں اُس کا احسان جتاتے ہوئے یا تکلیف دیتے ہوئے پیچھا نہیں کرتے، اُن کا
          اجر اُن کے ربّ کے پاس ہے اور اُن پر کوئی خوف نہیں ہو گا اور نہ وہ غم
          کریں گے۔
        </p>
        <Divider />
        <p className="arabic">
          وَ مَثَلُ الَّذِیۡنَ یُنۡفِقُوۡنَ اَمۡوَالَہُمُ ابۡتِغَآءَ مَرۡضَاتِ
          اللّٰہِ وَ تَثۡبِیۡتًا مِّنۡ اَنۡفُسِہِمۡ کَمَثَلِ جَنَّۃٍۭ بِرَبۡوَۃٍ
          اَصَابَہَا وَابِلٌ فَاٰتَتۡ اُکُلَہَا ضِعۡفَیۡنِ ۚ فَاِنۡ لَّمۡ
          یُصِبۡہَا وَابِلٌ فَطَلٌّ ؕ وَ اللّٰہُ بِمَا تَعۡمَلُوۡنَ بَصِیۡرٌ
          ﴿۲۶۶﴾
        </p>
        <p>
          And the case of those who spend their wealth to seek the pleasure of
          Allah and to strengthen their souls is like the case of a garden on
          elevated ground. Heavy rain falls on it so that it brings forth its
          fruit twofold. And if heavy rain does not fall on it, then light rain
          suffices. And Allah sees what you do. (2:266)
        </p>
        <p className="arabic">
          اور ان لوگوں کی مثال جو اپنے اموال اللہ کی رضا چاہتے ہوئے اور اپنے
          نفوس میں سے بعض کوثبات دینے کے لئے خرچ کرتے ہیں، ایسے باغ کی سی ہے جو
          اونچی جگہ پر واقع ہو اور اُسے تیز بارش پہنچے تو وہ بڑھ چڑھ کر اپنا پھل
          لائے، اور اگر اسے تیز بارش نہ پہنچے تو شبنم ہی بہت ہو۔ اور اللہ اس پر
          جو تم کرتے ہو گہری نظر رکھنے والا ہے۔
        </p>
        <Divider />
        <p className="arabic">
          اَلَّذِیۡنَ یُنۡفِقُوۡنَ اَمۡوَالَہُمۡ بِالَّیۡلِ وَ النَّہَارِ سِرًّا
          وَّ عَلَانِیَۃً فَلَہُمۡ اَجۡرُہُمۡ عِنۡدَ رَبِّہِمۡ ۚ وَ لَا خَوۡفٌ
          عَلَیۡہِمۡ وَ لَا ہُمۡ یَحۡزَنُوۡنَ ﴿۲۷۵﴾ؔ
        </p>
        <p>
          Those who spend their wealth by night and day, secretly and openly,
          have their reward with their Lord; on them shall come no fear, nor
          shall they grieve. (2:275)
        </p>
        <p className="arabic">
          وہ لوگ جو اپنے اموال خرچ کرتے ہیں رات کو بھی اور دن کو بھی، چھپ کر بھی
          اور کھلے عام بھی، تو ان کے لئے ان کا اجر اُن کے ربّ کے پاس ہے اور ان
          پر کوئی خوف نہیں ہو گا اور نہ وہ غم کریں گے۔
        </p>
        <Divider />
        <p className="arabic">
          لَنۡ تَنَالُوا الۡبِرَّ حَتّٰی تُنۡفِقُوۡا مِمَّا تُحِبُّوۡنَ ۬ؕ وَ
          مَا تُنۡفِقُوۡا مِنۡ شَیۡءٍ فَاِنَّ اللّٰہَ بِہٖ عَلِیۡمٌ ﴿۹۳﴾
        </p>
        <p>
          Never shall you attain to righteousness unless you spend out of that
          which you love; and whatever you spend, Allah surely knows it well.
          (3:93)
        </p>
        <p className="arabic">
          تم ہرگز نیکی کو پا نہیں سکو گے یہاں تک کہ تم اُن چیزوں میں سے خرچ کرو
          جن سے تم محبت کرتے ہو۔ اور تم جو کچھ بھی خرچ کرتے ہو تو یقیناً اللہ اس
          کو خوب جانتا ہے۔
        </p>
        <Divider />
        <p className="arabic">
          وَ مَا لَکُمۡ اَلَّا تُنۡفِقُوۡا فِیۡ سَبِیۡلِ اللّٰہِ وَ لِلّٰہِ
          مِیۡرَاثُ السَّمٰوٰتِ وَ الۡاَرۡضِ ؕ لَا یَسۡتَوِیۡ مِنۡکُمۡ مَّنۡ
          اَنۡفَقَ مِنۡ قَبۡلِ الۡفَتۡحِ وَ قٰتَلَ ؕ اُولٰٓئِکَ اَعۡظَمُ
          دَرَجَۃً مِّنَ الَّذِیۡنَ اَنۡفَقُوۡا مِنۡۢ بَعۡدُ وَ قٰتَلُوۡا ؕ وَ
          کُلًّا وَّعَدَ اللّٰہُ الۡحُسۡنٰی ؕ وَ اللّٰہُ بِمَا تَعۡمَلُوۡنَ
          خَبِیۡرٌ ﴿٪۱۱﴾
        </p>
        <p>
          And why is it that you spend not in the way of Allah, while to Allah
          belongs the heritage of the heavens and the earth? Those of you who
          spent and fought before the Victory are not equal to those who did so
          later. They are greater in rank than those who spent and fought
          afterwards. And to all has Allah promised good. And Allah is
          Well-Aware of what you do. (57:11)
        </p>
        <Divider />
        <p className="arabic">
          مَنۡ ذَا الَّذِیۡ یُقۡرِضُ اللّٰہَ قَرۡضًا حَسَنًا فَیُضٰعِفَہٗ لَہٗ
          وَ لَہٗۤ اَجۡرٌ کَرِیۡمٌ ﴿ۚ۱۲﴾
        </p>
        <p>
          Who is he that will lend to Allah a goodly loan? So He will increase
          it manifold for him, and he will have a generous reward. (57:12)
        </p>
        <p className="arabic">
          کون ہے جو اللہ کو قرضہ حسنہ دے پس وہ اسے اس کے لئے بڑھا دے اور اس کے
          لئے ایک بڑی عزت والااجر بھی ہے۔
        </p>
        <Divider />
        <p className="arabic">
          اِنَّ اللّٰہَ اشۡتَرٰی مِنَ الۡمُؤۡمِنِیۡنَ اَنۡفُسَہُمۡ وَ
          اَمۡوَالَہُمۡ بِاَنَّ لَہُمُ الۡجَنَّۃَ ؕ یُقَاتِلُوۡنَ فِیۡ سَبِیۡلِ
          اللّٰہِ فَیَقۡتُلُوۡنَ وَ یُقۡتَلُوۡنَ ۟ وَعۡدًا عَلَیۡہِ حَقًّا فِی
          التَّوۡرٰٮۃِ وَ الۡاِنۡجِیۡلِ وَ الۡقُرۡاٰنِ ؕ وَ مَنۡ اَوۡفٰی
          بِعَہۡدِہٖ مِنَ اللّٰہِ فَاسۡتَبۡشِرُوۡا بِبَیۡعِکُمُ الَّذِیۡ
          بَایَعۡتُمۡ بِہٖ ؕ وَ
        </p>
        <p>
          Surely, Allah has purchased of the believers their persons and their
          property in return for the Garden they shall have; they fight in the
          cause of Allah, and they slay and are slain — a promise that He has
          made incumbent on Himself in the Torah, and the Gospel, and the
          Qur’an. And who is more faithful to his promise than Allah? Rejoice,
          then, in your bargain which you have made with Him; and that it is
          which is the supreme triumph. (9:11)
        </p>
        <p className="arabic">
          یقیناً اللہ نے مومنوں سے ان کی جانیں اور ان کے اموال خرید لئے ہیں تا
          کہ اس کے بدلہ میں اُنہیں جنت ملے۔ وہ اللہ کی راہ میں قتال کرتے ہیں پس
          وہ قتل کرتے ہیں اور قتل کئے جاتے ہیں۔ اُس کے ذمہ یہ پختہ وعدہ ہے جو
          تورات اور انجیل اور قرآن میں (بیان) ہے۔ اور اللہ سے بڑھ کر کون اپنے
          عہد کو پورا کرنے والا ہے۔ پس تم اپنے اس سودے پر خوش ہوجاؤ جو تم نے اس
          کے ساتھ کیا ہے اور یہی بہت بڑی کامیابی ہے۔
        </p>
        <Divider />
        <p className="arabic">
          اَلتَّآئِبُوۡنَ الۡعٰبِدُوۡنَ الۡحٰمِدُوۡنَ السَّآئِحُوۡنَ
          الرّٰکِعُوۡنَ السّٰجِدُوۡنَ الۡاٰمِرُوۡنَ بِالۡمَعۡرُوۡفِ وَ
          النَّاہُوۡنَ عَنِ الۡمُنۡکَرِ وَ الۡحٰفِظُوۡنَ لِحُدُوۡدِ اللّٰہِ ؕ وَ
          بَشِّرِ الۡمُؤۡمِنِیۡنَ ﴿۱۱۲﴾
        </p>
        <p>
          They are the ones who turn to God in repentance, who worship Him, who
          praise Him, who go about in the land serving Him, who bow down to God,
          who prostrate themselves in prayer, who enjoin good and forbid evil,
          and who watch the limits set by Allah. And give glad tidings to those
          who believe. (9:112)
        </p>
        <p className="arabic">
          توبہ کرنے والے ، عبادت کرنے والے، حمد کرنے والے، (خدا کی راہ میں) سفر
          کرنے والے، (لِلّٰہ) رکوع کرنے والے، سجدہ کرنے والے، نیک باتوں کا حکم
          دینے والے، اور بُری باتوں سے روکنے والے، اور اللہ کی حدود کی حفاظت
          کرنے والے، (سب سچّے مومن ہیں) اور تُو مومنوں کو بشارت دےدے۔
        </p>
      </div>
    ),
  },
];

const books = [
  {
    key: 'an-introduction-to-financial-sacrifice',
    icon: <BookOutlined />,
    url: 'https://www.alislam.org/library/books/An-Introduction-to-Financial-Sacrifice.pdf',
    title: 'An Introduction to FINANCIAL SACRIFICE',
  },
];
const videos = [
  {
    key: 'hadhrat-khalifatul-masih-v-aba-on-financial-sacrifice',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/erkuVL7M5kE',
    thumbnail:
      'https://drive.google.com/thumbnail?id=11j51xhT1RGURQ_4PbV2T4XPjA7027yQ2',
    // thumbnail: 'https://img.youtube.com/vi/erkuVL7M5kE/hqdefault.jpg',
    title: 'Hadhrat Khalifatul Masih V (aba): Financial Sacrifice',
    video: true,
  },
  {
    key: 'hadhrat-khalifatul-masih-v-aba-n-oblgatory-chanda-situation-in-usa-jamaat',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/vdYI_AdzWYA',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1loO-6EDSO2H9Y26jQX2ZB6inGjN4kAxX',
    // thumbnail: 'https://img.youtube.com/vi/vdYI_AdzWYA/hqdefault.jpg',
    title:
      'Hadhrat Khalifatul Masih V (aba): Obligatory Chanda situation in USA Jama’at',
    video: true,
  },
  {
    key: 'hadhrat-khalifatul-masih-v-(aba)-order-of-the-importance-of-different-chanda-heads',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/iIY0VQSIo5A',
    thumbnail: 'https://img.youtube.com/vi/iIY0VQSIo5A/hqdefault.jpg',
    // thumbnail: 'https://img.youtube.com/vi/vdYI_AdzWYA/hqdefault.jpg',
    title:
      'Hadhrat Khalifatul Masih V (aba): Order of the Importance of Different Chanda Heads',
    video: true,
  },
  {
    key: 'examples-of-financial-Sacrifices-of-the-companion-of-holy-prophet-pbuh',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/GB_AxKbmTZE',
    thumbnail: 'https://img.youtube.com/vi/GB_AxKbmTZE/hqdefault.jpg',
    title:
      'Examples of Financial Sacrifices of the Companion of Holy Prophet PBUH',
    video: true,
  },
  {
    key: 'examples-of-financial-Sacrifices-of-the-companion-of-promised-messiah-as',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/GHM51F066ME',
    thumbnail: 'https://img.youtube.com/vi/GHM51F066ME/hqdefault.jpg',
    title:
      'Examples of Financial Sacrifices of the Companion of Promised Messiah AS',
    video: true,
  },
  {
    key: 'no-donation-accepted-from-unlawful-earnings',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/TwIlGDWludw',
    // thumbnail: 'https://drive.google.com/thumbnail?id=12bKxX_aW3k7_6pAPoKIjxEohTVPqFTXF',
    thumbnail: 'https://img.youtube.com/vi/TwIlGDWludw/hqdefault.jpg',
    title: 'No Donation Accepted from Unlawful Earnings',
    video: true,
  },
  {
    key: 'hadhrat-khalifatul-masih-iv-rh-on-if-usa-jamaat-collects-according-to-prescribed-rate',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/4ro2iEA0Rog',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1i46ZjrGt_bA-Zq-xKBfVF5Gch_LgBWqo',
    // thumbnail: 'https://img.youtube.com/vi/4ro2iEA0Rog/hqdefault.jpg',
    title:
      'Hadhrat Khalifatul Masih IV (rh): If USA Jama’at collects according to prescribed rate',
    video: true,
  },
  {
    key: 'standard-of-financial-sacrifice-and-devotion-set-by-hadhrat-khalifa-tul-masih-awal-ra',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/c0EMSinKK24',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1yp_DdWKyDQx9ti-rVFWC3TGcKIpZfJ5Q',
    // thumbnail: 'https://img.youtube.com/vi/c0EMSinKK24/hqdefault.jpg',
    title:
      'Standard of Financial Sacrifice and Devotion Set by Hadhrat Khalifa tul Masih Awal (RA)',
    video: true,
  },
  {
    key: 'hadhrat-khalifatul-masih-iv-rh-on-chanda-aam-percentage',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/OVNUE_hM9yY',
    thumbnail:
      'https://drive.google.com/thumbnail?id=17mZu2GcaXnkl8kWhzGqAUsMwaGC6Cxe4',
    // thumbnail: 'https://img.youtube.com/vi/OVNUE_hM9yY/hqdefault.jpg',
    title: 'Hadhrat Khalifatul Masih IV (rh): Chanda Aam Percentage',
    video: true,
  },
  {
    key: 'hadhrat-khalifatul-masih-iv-rh-on-definition-of-income',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/XPJhDsnvI-k',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1w7IVCpq-aFbIEpy8MBb0GFrmzosoNI-m',
    // thumbnail: 'https://img.youtube.com/vi/XPJhDsnvI-k/hqdefault.jpg',
    title: 'Hadhrat Khalifatul Masih IV (rh): definition of income',
    video: true,
  },
  {
    key: 'whether-the-chanda-system-will-ever-end',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/fX-_G2M6aTk',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1vyPUMirHOs95Rs7S0QnWnZWD7K7rwCGm',
    // thumbnail: 'https://img.youtube.com/vi/fX-_G2M6aTk/hqdefault.jpg',
    title: 'Whether the Chanda System will ever end',
    video: true,
  },
  {
    key: 'promised-messiah-as-excerpt-from-mulfoozat',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/0gX7iYTokyk',
    thumbnail:
      'https://drive.google.com/thumbnail?id=1NX6ECOKv0ygLhB7SpWAe4oAJ3ai3QzDh',
    // thumbnail: 'https://img.youtube.com/vi/0gX7iYTokyk/hqdefault.jpg',
    title: 'Promised Messiah (AS) Excerpt from Mulfoozat',
    video: true,
  },
  {
    key: 'an-example-of-financial-sacrifice-by-companion-of-promised-messiah-as',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/PwpyvJXKLIg',
    thumbnail:
      'https://drive.google.com/thumbnail?id=19SebZCJHTFfsXY5oquKg8xLLIqBkVczM',
    // thumbnail: 'https://img.youtube.com/vi/PwpyvJXKLIg/hqdefault.jpg',
    title:
      'An example of Financial Sacrifice by Companion of Promised Messiah (as)',
    video: true,
  },
  {
    key: 'sir-ch-zafarullah-khan-on-excuses-for-not-paying-chanda',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/pX4LCqpES_E',
    thumbnail:
      'https://drive.google.com/thumbnail?id=12bKxX_aW3k7_6pAPoKIjxEohTVPqFTXF',
    // thumbnail: 'https://img.youtube.com/vi/pX4LCqpES_E/hqdefault.jpg',
    title: 'Sir Ch. Zafrullah Khan on Excuses For Not Paying Chanda',
    video: true,
  },
];

const articles = [
  {
    key: 'some-ahadith-regarding-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Ahadith Regarding Financial Sacrifice',
    article: true,
    content: (
      <div>
        <p>
          "Abu Huraira (ra) reported Allah’s messenger (sa) as stating that
          Allah the Almighty says: "Spend with an open heart, Allah will spend
          upon thee." (Sahih Muslim)
        </p>
        <p>
          "Each morning, two angles descend, one of them saying: ‘Allah, bestow
          a (good) return upon the spender’; and the other saying: ‘Allah,
          Destroy the property of the miser’." (Sahih Bukhari)
        </p>
        <p>
          "Whoever spends anything in the cause of Allah, is rewarded seven
          hundred times more." (Tirmidhi)
        </p>
        <p>
          "A generous one is nearer to Allah, nearer to people, nearer to
          paradise and farther away from hell, but the miser is farther from
          Allah, farther from people, farther from paradise and nearer to hell.
          Indeed, an ignorant one who is generous is dearer to Allah then
          worshiper who is miserly." (Qashiriya, Al-Jud wul Sikha)
        </p>
        <p>
          "Only two persons deserve to be envied: firstly, a person to whom
          Allah gave wealth and he spent it in the cause of Truth; and secondly
          the person upon whom Allah has bestowed knowledge, wisdom and insight
          by which he judges between people and teaches them." (Sahih Bukhari)
        </p>
        <p>
          "Rafi‘ Bin Khudaij (ra) reports that the Messenger of Allah (sa) said:
          "The official who collects Zakat in a just manner is, until he returns
          home, like one who fights in the cause of Allah." (Mishkat)
        </p>
        <p>
          “When the collector of Sadaq comes to you, he should return well
          pleased." (Sahih Muslim)
        </p>
        <p>
          "Hadrat Abu Hurairah(ra) relates that the Holy Prophet (sa) said: "A
          man was walking through a waterless piece of land. The sky was clouded
          and he heard a voice coming from the cloud saying: ‘O cloud, irrigate
          the garden of so and so.’ Upon this the cloud moved to a certain
          direction and rained upon a rocky plateau. The water ran through small
          rivulets. The man followed a rivulet until it entered a garden and he
          saw the owner of the garden working with a shovel and distributing the
          water to his plants. He asked the owner of the garden: ‘O servant of
          Allah! What is your name?’ He told him his name, which was the same
          that he has heard from the cloud. The owner of the garden then asked
          him, ‘O servant of Allah! Why did you ask my name?’ He replied, I
          heard a voice from the cloud, saying, ‘Irrigate the garden of so and
          so’; May I know what you do to your garden to become worthy of such
          favour.’ He said; ‘Since you have asked me, I will tell you. When the
          produce of this garden is ready, I give one third of the produce away
          in charity, keep one third for myself and my family, and use the
          remaining third in sowing and raising another crop in the garden."
          (Sahih Muslim)
        </p>
        <p>
          "Asma’ bint Abu Bakr (ra) reported Allah’s Messenger (sa) as saying,
          "Do not calculate when spending in the way of Allah, or Allah will
          give you in the same manner, but spend as much as you can" (Sahih
          Bukhari)
        </p>
        <p>
          "The Holy Prophet (sa) once asked: Which of you loves the property of
          his heir more than his own? The companions submitted: 'O Messenger of
          Allah there is none among us who loves his heir’s Property more than
          his own'. He said ‘Then your real property is that which you send
          before you; and that which you retain belongs to your heir." (Sahih
          Bukhari)
        </p>
        <p>
          "Hadrat Abu Huraira (ra) related that the Holy Prophet (sa) said: "If
          I had as much gold as the mountain of Uhad, I would be happy if none
          of it was left before three days had expired, apart from that which I
          may keep for paying back my debts." (Sahih Bukhari)
        </p>
        <p>
          The Holy Prophet (sa) stated “On the Day of Judgement, till the
          accountability review is over, the people who spend in the way of
          Allah will remain under the shadow of the wealth that they spend,
          meaning the wealth you spend in the way of Allah, you will be under
          its shadow on day of Judgement.”
        </p>
      </div>
    ),
  },
  {
    key: 'some-quotes-from-promised-messiah-as-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Promised Messiah (as) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>THE NECESSITY AND IMPORTANCE OF SPENDING IN THE WAY OF ALLAH</h3>
        <p>
          "The revival of Islam requires a sacrifice from us. What is that
          sacrifice? It is to die striving in this path, upon which the life of
          Islam, the life of Muslims, and the manifestation of Allah depends.
          This, in other words, is Islam. It is the revival of this Islam which
          Allah today desires. To attain this objective, it was necessary that
          He should Himself establish a great and effective system; and this is
          what the Wise and Mighty has done by sending me into this world for
          reform of mankind. He has divided this task of supporting the truth
          and the propagation of Islam into several branches." (Fath-e-Islam)
        </p>
        <Divider />
        <h3>THE PROMISED MESSIAH’s (as) CALL FOR FINANCIAL SACRIFICE</h3>
        <p>
          "O Well to do people of Islam! I convey to you the message that you
          should assist this Institution of reform, which has been established
          by Allah Almighty, with all your heart, attention and sincerity. You
          must consider all the aspects of this institution with reverence and
          do quickly whatever you can do to help it. Whoever wishes to offer an
          amount each month, according to his means, should make it binding upon
          himself like a debt, and make his payment each month without fail. He
          should take this duty purely as an obligation to Allah, and should not
          be late or slow in its payment. He who wishes to pay all at once can
          do so, but remember that the true method which will ensure the
          continuous progress of this movement is that people, who truly care
          about the faith, should make it binding upon themselves to pay a
          certain amount each month which they can give with ease and
          regularity, unless they are faced with unexpected circumstances. Of
          course, whoever has the means and the resources to give something,
          apart from his monthly pledge, is welcome to do so." "O you my dear
          ones, my loved ones, the evergreen branches of the tree of my being! O
          ye who have, by the grace of the Almighty which is upon you, entered
          into Ba‘iat of allegiance with me! O ye who sacrifice your lives, your
          comfort and your wealth in this cause! I know that you consider it an
          honour to accept whatever I say, and will not hesitate as far as it is
          in your power, but I cannot make compulsory for you with my own tongue
          the service you have to offer, so that your services should be out of
          your own pleasure and not as an obligation from me…." (Fath-e-Islam)
        </p>
        <Divider />
        <h3>THIS TIME WILL NOT RETURN</h3>
        <p>
          "It is now time for all those who count themselves among my followers,
          that they should help this Movement with their money. If someone can
          only afford one paisa, he should pay one paisa each month for the
          requirements of the movement; he who can afford a rupee should offer a
          rupee each month….Everyone who has accepted the Ba‘iat should help
          according to his means, so that Allah too should help them. If the
          assistance is received regularly every month, even if it is minor, it
          is better than that which is made upon impulse after a long time of
          negligence. Every person's sincerity can only be judged by the service
          he offers. O my dear ones! This is the time for helping the faith and
          fulfilling its requirements. Make use of it, for this time will never
          return." (Kashti-e-Nuh)
        </p>
        <Divider />
        <h3>WEALTH CAN ONLY BE GAINED BY ALLAH’S WILL</h3>
        <p>
          "It goes without saying, that you cannot love two things at the same
          time: it is not possible for you to love wealth as well as to love
          Allah. You can love only one of them. Lucky is he who loves Allah. If
          any of you loves Him and spends his/her wealth in His cause, I am
          certain that his/her wealth will increase more than that of others,
          for wealth doesn’t come by itself, rather it comes by Allah’s will.
          Whoever parts with some of his wealth for the sake of Allah, will
          surely get it back. But he who loves his wealth and doesn’t serve in
          the way of Allah as he should, will surely lose his wealth. Do not
          ever imagine that your wealth comes of your own effort, no, it comes
          from Allah Almighty. And do not ever imagine that you do a favour to
          Allah or his Appointed One by offering your money or helping in any
          other way. Rather it is His favour upon you that he calls you to this
          service." (Majmu’ah Ishtaharat, volume 3, p. 497-498)
        </p>
        <Divider />
        <h3>SPECIAL EXHORTATION ABOUT RECEIVING CHANDA FROM NEW AHMADIS</h3>
        <p>
          "Hundreds of people pledge allegiance to me each day, but, when you
          enquire of them, there are few indeed who pay their Chanda regularly
          on monthly basis. What more can we expect from one who doesn’t help
          this movement with a little money according to his capacity? What good
          is such a person to this movement? Even when a common man goes to
          town, however destitute he may be, he always brings back something,
          according to his capacity, for himself and his children; then is this
          movement, which Allah has established for such great purposes, not
          worthy enough that he should sacrifice a little money for it … Has
          there ever been a movement, whether worldly or spiritual, which has
          survived without financial contribution? This being a world of means,
          Allah has ordained a means for every end. How selfish and niggardly
          then is the person who can't spend a trivial thing like money for such
          a lofty cause. Time was when people used to shower their lives like
          sheep and goats for Allah's religion, not to mention their
          possessions. More than once Hadrat Abu Bakr sacrificed his total
          possessions and did not keep even a hair-pin for himself. Hadrat ‘Umar
          and ‘Uthman did likewise according to their means and their spiritual
          status. Similarly, all the Companions of the Holy Prophetsa, according
          to their means and status, were ready to sacrifice themselves for
          Allah's religion along with their lives and possessions. Now there are
          those who pledge their allegiance, and vow to give preference to their
          faith over the world, but when it comes to aiding and assisting the
          faith, they hold fast to their pockets. With such love for the world
          in their heart, can they ever hope to gain spiritual merit? Can such
          people ever be a source of benefit? Never. Never. Allah Almighty says:
          ‘You can’t attain righteousness until you spend in the way of Allah
          that which you love most'…. Therefore, I stress upon all of you,
          whether present or absent, to tell your brothers about the Chanda and
          try to bring your weak brothers into the system of Chanda. This
          opportunity will not present itself ever again." (Malfuzat, volume 3,
          p. 359-360. )
        </p>
        <Divider />
        <h3>SPENDING IN THE WAY OF ALLAH WILL PROLONG YOUR LIVES</h3>
        <p>
          "If you perform righteous deeds and do some service at this time, you
          will set the seal upon your sincerity. You will live longer and your
          wealth will increase." (Tabligh-e-Risalat)
        </p>
        <Divider />
        <h3>ALLAH BECKONS YOU</h3>
        <p>
          "We praise Allah and invoke blessings upon His Prophet, who is kind
          and trustworthy, and upon his progeny who are pure and clean, and on
          his disciples who are perfect. These disciples strived in way of Allah
          and, cutting themselves off from the world, bowed themselves
          completely before Him. So, O friends, you should know—and may Allah
          have mercy on you—that a Beckoner has come to you from Allah at the
          appointed time. Allah's mercy has come to your aid at the head of the
          century. You were waiting for that Beckoner like the thirsty one, so
          now he has come by the grace of Allah, so that he may warn those whose
          forefathers had not been warned and so that the evil-doers should
          become apparent. He has been ordered to call you towards the Truth and
          the Faith and to guide you on the path of Insight and to reveal to you
          all that which will serve you on the day of Judgement. Hence, he has
          taught you those Truths and Insights, for you could never know them by
          yourselves. Hence, he has fully conveyed God’s message to you and has
          given you sight. You have seen what your forefathers did not see, and
          you found the light of certainty which they did not. Do not,
          therefore, reject the blessings of Allah and do not be heedless. I see
          among you people who do not value Allah as ought to be done. They say
          that they believe while they believe not. Do they think they do a
          favour to Allah? Nay, all favors are from Him, only if they knew! All
          Glory and Majesty belongs to Him. If you do not heed Him, he will turn
          away from you and bring another people, and you will be able to do Him
          no harm. These are the days of Allah and the days of His Signs. Hence
          fear Allah and His days if you are fear Him. Soon you will be returned
          to Allah and brought to account, and I don't see any of your wealth
          and property going with you. So, come to your senses and do not be
          foolish. Stand up obediently and be ready to sacrifice your lives and
          your property in His path. Do you expect Allah to be pleased with you
          while you have not done what the Truthful are supposed to do? You will
          never achieve righteousness until you spend out of that which you
          love. Why do you not understand? Do you think you will be allowed to
          live on and will never die? I have been ordered to warn you, and let
          me tell you that Allah sees all your actions and He calls you to help
          him with your lives and your possessions. Will you then be obedient?
          Whoever among you shall help Allah, Allah shall help him. Whatever he
          gives to Allah, shall be returned to him manifold, for He is the more
          Beneficent than any beneficent one. Wake up, therefore, and try to
          excel each other. Allah knows those who excel and He knows those who
          believe and are particular about their Ba‘iat and covenant. He knows
          those who do good deeds and keep moving forward and are steadfast. For
          these people is deliverance, prestigious bounty and Allah’s pleasure.
          The truly faithful are those who are His pious servants." (Majmu’ah
          Ishtiharat, volume. 3, p. 151-153)
        </p>
        <Divider />
        <h3>SPEND IN THE WAY OF ALLAH OUT OF THAT WHICH YOU LOVE</h3>
        <p>
          "The door to piety is a narrow one. So remember that no one can enter
          it by spending useless and worthless things. The clear injuction is:
          ‘You can never attain righteousness until you spend out of that which
          you love.’ Until you spend the things which you love and are dear to
          you, you can not attain the stage of being loved. If you are not ready
          to bear suffering and to acquire true piety, how can you expect to
          succeed? Did the disciples gain their station without doing anything?
          Just look at how one has to work and toil in order to gain a worldly
          title, even though it does not give true peace and satisfaction. Then
          how is it possible for the title of 'may Allah be please with him' to
          be acquired with no difficulty. The truth is that it is not possible
          to gain the pleasure of Allah—which is a source of true
          happiness—until one does not bear momentary hardships. Allah cannot be
          deceived. Happy are those who care not for pain in order to attain His
          pleasure, for it is only after temporary suffering that the believer
          is granted the light of eternal joy and everlasting comfort." (Tafsir
          Hadrat Masih Ma‘ud)
        </p>
        <Divider />
        <h3>WHO IS MY HELPER IN THE WAY OF ALLAH?</h3>
        <p>
          "Most members of my Jama‘at are poor and unprivileged, but to present
          oneself for the service of Allah with a pure heart is the cure for
          every problem and difficulty. So whoever truly believes in the
          existence of Allah and knows that he is dependant upon His favours in
          the material and spiritual world, should not let this blessed time go
          out of his hand, nor should he let the disease of miserliness deprive
          him of this reward. Only he deserves to join this Movement who has a
          high resolve and who promises Allah that from now on he shall try his
          best to offer each month whatever financial assistance he can offer in
          order to remove the difficulties faced by His religion. It is
          hypocrisy to remember Allah when faced by a calamity and to become
          heedless when one is in ease and comfort. Allah is Independent and
          Indifferent; it is you who have to prove your sincerity in order to
          attain His grace. May Allah be with you." (7 Majmu‘ah Ishtiharat,
          volume 3, p. 165-166)
        </p>
        <Divider />
        <h3>FINAL JUDGEMENT</h3>
        <p>
          "This is not an ordinary announcement, rather it is the final
          judgement concerning those who claim to be my followers. Allah
          Almighty has told me that I am connected only to those, and only such
          are my followers in the eyes of Allah, as occupy themselves with
          helping and assisting me. But there are many who would deceive Allah.
          Now, with the beginning of this new system, every one has to pledge
          afresh and inform me in writing that he will consider himself bound to
          send me a certain sum each month. There should be no ifs and buts
          about it. There have been some who did not keep their word and thus
          sinned against Allah. Now everyone must consider carefully and promise
          the amount which he is able to pay each month, even if it is one
          paisa. Do not try to deceive Allah or play tricks with Him. Everyone
          who follows me should bind himself to pay a certain amount each month,
          be it a paisa or a dhela. One who neither promises to give anything
          nor tries to help this Movement in any other way, is a hypocrite and
          he will not remain in this movement. I will wait for three months
          after the publication of the announcement to see if each of my
          followers agrees to assist me with a certain amount each month. Anyone
          who does not respond during this period will be excluded from this
          Community. And if someone makes a pledge but fails to send his money
          for three months, he too shall be excluded. And henceforth no arrogant
          and heedless person who is of no assistance will be able to remain in
          the Community." (Maju’ah Ishtiharat, volume 3, p. 468-469)
        </p>
        <Divider />
      </div>
    ),
  },
  {
    key: 'some-quotes-from-khalifatul-masih-i-ra-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Khalifatul Masih I (ra) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>SPEND YOUR WEALTH IN THE WAY OF ALLAH</h3>
        <p>
          "…In order to receive Divine favours, it is necessary that one should
          go through fear. Fear of what? Fear of Allah? Fear of the enemy? Fear
          of the apostasy of some foolish people with weak faith? But this will
          not be enough. The fact is that this is a prophecy. Allah Almighty
          says: 'I shall test you with fear and hunger and loss of property and
          lives and fruit.' Hunger also comes from fasting, so this verse tells
          us to keep fasts or to spend so much by way of Sadaqa and charity that
          you reach the state of starvation. Spend so much out of your property
          that it should become less, and also spend your lives in His cause and
          similarly your harvests." (Friday Sermon, June 5, 1908)
        </p>
        <Divider />
        <h3>SPEND YOUR WEALTH ACCORDING TO THE WILL OF ALLAH</h3>
        <p>
          "…Being a micro universe, man needs a King to administer his kingdom.
          He also needs someone to fulfil his needs. Each of the attributes
          belongs to Allah. It is under His protection that a believer should
          find shelter against hidden doubts and temptations which pull him
          backwards and are a barrier in his progress. Islam today is in a
          miserable state. A kind of self-conceit afflicts every Muslim. He does
          not spend his property and his time according to Allah’s Teachings.
          Allah has made man free but has also subjected him to some
          regulations, especially regarding his property. Be careful, therefore,
          about what you do with your money. There are many today who consider
          it lawful to give and receive interest. It is simply wrong. It is
          written in a Hadith that whoever receives interest, gives interest,
          and even he who records it or acts as a witness in such a transaction
          is under Allah’s curse. I have hereby fulfilled my duty of conveying
          the message to you. I do not care the least for you. All I desire is
          that you should become Allah’s. Reform yourselves. May Allah enable
          you. Amin” (Friday Sermon, June 25, 1909)
        </p>
      </div>
    ),
  },
  {
    key: 'some-quotes-from-khalifatul-masih-ii-ra-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Khalifatul Masih II (ra) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>THE COMPLETION OF FINANCIAL SACRIFICES IS THROUGH THE KHULAFA’</h3>
        <p>
          "I always demand of my Jama‘at to devote their lives and properties
          for the service of the Jama‘at, and so did the Promised Messiahas, but
          the standard has continued to change with time. When people first
          heard this call, they came forward and said, 'Our lives and our
          properties are at your command.' The Promised Messiahas heard their
          response and said: 'Observe your prayers, keep your fasts, spread the
          teachings of Islam and Ahmadiyyat, and also give something out of your
          belongings for the service of the faith, even if it is a Dhela41 out
          of a rupee.' The people wondered at this seemingly small sacrifice
          because they had been called to offer their lives and properties.
          After a time, they again heard the call that the time had come for
          them to sacrifice their lives and properties, and again they presented
          themselves with lives and properties. This time they were told to
          sacrifice a paisa out of a rupee for Chanda. Time went on, and once
          again they heard the call from Markaz to sacrifice their lives and
          properties for the faith. Once again they came and this time they were
          told to sacrifice two paisas out of a rupee instead of one paisa.
          Things continued in this manner, until the call which has began with a
          Dhela reached paisas. They were told to give two paisas and then three
          and then four. Once again, when the time came, they were told to make
          a Wasiyyat of one tenth of their incomes and properties. Then they
          were told that this was not enough and that they should try to give
          one ninth and those whom Allah had given the means should give even
          more. Those whom Allah has blessed with a thinking and understanding
          mind know that with each step we are being drawn nearer to the
          ultimate purpose without which nations cannot stay alive. Some people
          are led to think that words like sacrifice and devotion which are used
          time and again don’t mean anything. They imagine sacrifice to mean
          giving an anna or anna and a half at most. As far as sacrificing time
          is concerned, they imagine that out of the twenty four hours it is
          enough to sacrifice an hour or two. They are completely oblivious of
          the fact that one day they could actually have to come forward to
          sacrifice their lives and property…. It is very likely that one day …
          there could come the true call from Allah’s representative requiring
          the true manifestation of the call which was made fifty or sixty years
          ago. At that time, some of you, due to the veil of indifference which
          will have covered you with the passage of time, may think that
          sacrificing property means only to give a rupee, and sacrificing life
          means to give an hour or so out of a week or a month, but this will
          not be so. It will not be the time to sacrifice an hour or two hours,
          rather it will be a matter of sacrificing one’s whole
          life…….Similarly, it will not suffice to sacrifice an anna or two
          annas, it will be a question of parting with one’s whole property in
          an instant." (Speech Majlis-e-Mushawarat April 21, 1946)
        </p>
        <Divider />
        <h3>
          THE INCREASE IN DEMAND FOR FINANCIAL SACRIFICE AFTER THE PROMISED
          MESSIAH (as)
        </h3>
        <p>
          "Some people raise the objection that the Promised Messiahas said that
          whoever gives even a paisa every three months is an Ahmadi, why is it
          that now an anna out of a rupee is the required Chanda for each month?
          Such people don’t realize that the Holy Qur’an has said that the
          Jama‘at of the Messiah will be like a bud in the beginning and then it
          will keep growing, that is to say, it will progress in sacrifices and
          will grow stronger. It is wrong to imagine that the Jama‘at will be
          larger in the beginning and will then become less, rather it says that
          it will be weak and will grow stronger, which means that its faith
          will grow stronger. If someone is afraid that this amounts to
          dishonouring the faithful people of the Promised Messiah’sas time, he
          should know that it may be that the people who come afterwards do not
          have such faith as they did. The Promised Messiahas said: ‘How well it
          would be if every one of the Ummah were like Nuruddin.’ Yes it is
          possible that people of such sincerity may not come in the future, but
          these prominent personalities, who served as the pillars of the
          Jama‘at, were but a few and it is possible that time may not produce
          their like. But overall the faith and sacrifice of the Jama‘at is on
          the increase. True, hypocrites are also on the increase, and they
          existed even at the time of the Promised Messiahas, and he has even
          mentioned of them, but they were not prominent at that time because
          the level of sacrifice was so minor that the sacrifice made by a
          sincere one could also be made by a hypocrite. Now that the time has
          come to make greater sacrifices, the hypocrites have began to fall and
          the sincere ones are progressing in their sacrifices. The distinction
          we see today is not because there were no hypocrites before and there
          are now, rather it is because there was no such distinction between
          the hypocrites and the believers as there is now." (Report Majlis
          Mushawarat, 1936)
        </p>
        <Divider />
        <h3>
          THE WORKERS FOR CHANDA COLLECTION ARE ALSO MUJAHIDIN IN THE WAY OF
          ALLAH{' '}
        </h3>
        <p>
          "When you read about Hadrat Khalid, Hadrat Sa‘d, Hadrat ‘Amr bin Ma‘di
          Karb and Hadrat Jarrar, you wish that you had lived at their time and
          achieved similar feats. What you forget is that for every time and
          every thing there is a proper place. In this age, Allah Almighty has
          opened the door to the Jihad of Spreading the faith and Jihad against
          one’s own self, instead of Jihad with the sword. And we cannot spread
          the faith without money. You are the Mujahidin of this age and you
          deserve the same reward which those people did, and indeed you are
          getting it. Do what you are told with diligence and also teach others,
          so that all of you become Mujahidin in the way of Allah. Amin."
          (Letter Hadrat Khalifatul Masih II, to members of Mal Karachi dated
          March 03, 1957)
        </p>
      </div>
    ),
  },
  {
    key: 'some-quotes-from-khalifatul-masih-iii-rh-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Khalifatul Masih III (rh) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>OUR OBLIGATION </h3>
        <p>
          "Allah has created us for the victory of Islam. Our job is to do
          whatever is in our power bring this about and to offer every sacrifice
          in this cause. Even if our efforts fall short of the necessary
          requirement, Allah will Himself do the rest by His grace….Our
          spiritual eye can discern the victory of Islam over the horizon of the
          current turmoil, but we will still have to make sacrifices for it and
          will have to spend our time and money in this cause." (Message to
          Jalsa Salana Qadian, 1978)
        </p>
        <Divider />
        <h3>WASIYYAT IS A GREAT INSTITUTION </h3>
        <p>
          "Through the Promised Messiah (as), Allah Almighty has established the
          system of Wasiyyat in Jama‘at Ahmadiyya. Wasiyyat is indeed a great
          institution in every aspect. The purpose of Wasiyyat is that among the
          members of the Ahmadiyya Movement, there should be a group which
          should fulfil its obligations in view of the teachings of Islam with
          such diligence and sacrifice, that it should become clearly
          distinguishable from the others. Wasiyyat does not mean merely to
          sacrifice one tenth of one’s property. Rather it is a system which
          lifts man from the depths of the earth and elevates him to the heights
          of heaven." (Friday Sermon, April 30, 1982. )
        </p>
        <Divider />
        <h3>THE STATUS OF A MUSI</h3>
        <p>
          "Wasiyyat is an institution which was established by Divine will. The
          rate of Wasiyyat was fixed by the Promised Messiahas and no Khalifa
          can change it. 1/10 will always remain 1/10. So, anyone who promises
          to give 1/10 but actually gives less, ceases to be a Musi. A Musi is
          one who is foremost in his sincerity, his fear of Allah, his purity,
          his morals and indeed in every other quality. Similarly a Musi has to
          be foremost in his financial sacrifice as well. If a Musi does not
          live up to this standard, we should do him a favour and remove him
          from the list of Musian. This is better for him rather than that he
          would die while in breach of his promise to Allah …When a Musi reveals
          his income, it should be considered accurate without further
          investigation and Chanda should be received from him according to this
          income. But if there is solid evidence that the income he has revealed
          is less than his actual income, his Wasiyyat shall not be acceptable,
          for then he will be guilty of lying deliberately, and a liar cannot be
          a Musi. Hence he excludes himself from the class of Musian by his own
          action." (Weekly Badr, Qadian, November 4, 1982)
        </p>
      </div>
    ),
  },
  {
    key: 'some-quotes-from-khalifatul-masih-iv-rh-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Khalifatul Masih IV (rh) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>BE TRUE AND STRAIGHTFORWARD IN YOUR DEALINGS WITH ALLAH</h3>
        <p>
          “"….But Allah Almighty says that these people too have been blessed
          with Insight; you can deceive neither them nor Me. Your lifestyle,
          your society, your values, each of these things reveals how much you
          possess. Since Wasiyyat is not a system of taxation, therefore, in
          view of the moral norms and practice of the Jama‘at, the
          office-bearers accept whatever a person claims to be his income, even
          when they know that he is not speaking the truth. But what comes to
          pass later on is very dangerous indeed for such people: their
          sacrifices of a lifetime go in vain and they lose their wealth and
          fall into all kinds of troubles. Allah, Who knows everything, has
          countless ways of bestowing and similarly of taking back. Such people
          even become deprived of the peace and comfort which wealth is supposed
          to provide. At times the wealth of such families is squandered before
          their very eyes and they are helpless to do anything about it.
          …Therefore, you should always be true and straightforward in your
          dealings with Allah, Who is the Giver and Provider. Only then will
          your sacrifices be of any use to you and you will inherit even greater
          blessings. Why should you fear to spend in the way of Allah? It is
          this spending which is the source of your income and of His blessings.
          At the time of the Promised Messiahas, some of the Disciples who
          presented a little money to him had to make great sacrifices to do so.
          But their progeny has become recipient of such Divine bounties even in
          their worldly affairs, that it is hard to recognize them; so
          wonderfully have their possessions been blessed. … , which means that
          there is a continuous flow of their spending in the way of Allah. We
          keep providing for them and they never stop spending. This is the
          blessed spring which was made to flow by the Holy Prophetsa, and to
          guarantee whose continuous existence we have been created. We, who
          have been assigned the task of providing the whole world with the
          drink of this spring, have to keep it filled with our sacrifices. But,
          remember, this spring is the pool of the sacrifices of a holy Prophet,
          therefore, not a single impure drop should find its way into it. Not a
          particle of the ego must become part of it. Otherwise, you shall not
          be allowed to remain among those who offer sacrifices. With this fear
          in your hearts, you should keep scrutinizing yourselves and keep
          praying that may Allah Almighty keep this financial institution clean
          in every aspect and protect it from the adulteration of our egos. The
          truth is that if a part of the Jama‘at were to adhere to Taqwa in this
          regard, were not to fear any one other than Allah, were not to be
          guilty of Shirk, and were to stand firm on the principle that whatever
          they offer in the way of Allah, they shall do it truthfully, then
          today our Chandas can double without having to increase the rate." (50
          Friday Sermon, July 23, 1982 )
        </p>
        <Divider />
        <h3>SACRIFICES IN THE CAUSE OF ALLAH </h3>
        <p>
          "…It is very strange that although we are called Ahmadis and have
          pledged our allegiance at the hands of the Promised Messiahas, and we
          claim that we shall always give precedence to our faith over all
          worldly affairs, and we have pledged that we shall do our utmost to
          rescue Islam from all its travails, even if we have to sacrifice our
          very lives in this cause, despite all this we see that there are but a
          few members of the Jama‘at who are bearing the burden which is
          supposed to be borne by millions. And nobody seems to realize this and
          nobody seems to care. There doesn’t arise any human sympathy or
          self-reproach that we too are part of this Jama‘at, we too have made
          our pledges, we too are overwhelmed with the benevolence of the
          Promised Messiahas who made us relish the true pleasures of Islam. We
          just stand there like someone on the shore watching a ship sink, and
          his heart is not in the least moved… …Human sympathy therefore demands
          that these people too should be included. All of you who are attending
          this sermon should go back and spread this message in your
          surroundings. Tell those who are weak and are afraid of spending in
          the way of Allah that you are depriving yourselves both of virtue and
          of the blessings of Allah. You are even depriving yourselves of the
          world which you are striving for. Your wealth will decline, you will
          not see the happiness of your children. Before your very eyes your
          pleasures shall vanish and sorrow and worry shall have their place in
          your hearts. This is the destiny of Ahmadis who are moving away from
          Ahmadiyyat. This is what we have always experienced. Allah does not
          leave anyone’s sacrifice unreciprocated. Have you ever seen one who
          makes sacrifices and his children are starving? Just look at how
          Abundantly the family of the Promised Messiahas has been blessed. But
          these blessings are only as long as one realizes the basis on which
          they have been bestowed. If someone were to deceive himself that it is
          all because of his own ingenuity and a sleight of his own hand, he
          would be foolish indeed. These blessings are the result of a few
          crumbs which the Promised Messiahas sacrificed in the cause of Allah.
          Even before he was granted Prophethood, he had already given to Allah
          everything he possessed. All that we are reaping today is due to that
          sacrifice. And this is not all, there are hundreds of Ahmadi families
          who are enjoying the fruits of similar sacrifices." (Friday Sermon,
          September 10, 1982)
        </p>
        <Divider />
        <h3>PERMISSION FOR REMISSION IN CHANDA ‘AM </h3>
        <p>
          “"I have announced again and again that if someone is unable to pay
          the Chanda in keeping with the prescribed rate, he should clearly say
          so and should tell us about his circumstances. Khalifatul Masih can
          grant remission in Chanda ‘Am. And I openly promise that whoever
          honestly thinks that he is not up to the mark and that he should be
          granted remission, he shall be granted remission. But do not lie to
          God! It should not be that God gives you millions and you pay your
          Chanda on the basis of hundreds of thousands, and you say that He has
          only given you that much. Do you think that God forgets what He has
          given you (God forbid) and doesn’t know what you are paying back? He
          who has given you is aware of the secrets of the hearts and has
          knowledge of all hidden intentions. He is aware of the Bank balances
          where the money goes and disappears and yet man doesn’t find
          contentment, he wants to possess even more. Hence, those who need help
          shall be helped, and their needs shall be catered for. They will be
          willingly granted remission. And if there is an Ahmadi who cannot give
          Chanda and is himself in need of assistance, the Jama‘at will assist
          him as far as possible. But no one can be permitted to lie to God. I
          am, therefore, allowing for one last chance, so that we should not
          lose our brothers. I am not in the least worried as to how God’s
          mission will be accomplished. If I were to do so, I would be a
          Mushrik. I am not worried that if we lose some Ahmadis how shall we
          find others in their place. If one were to leave us, God can and shall
          grant us hundreds of thousands in his place. What I am worried about
          is why at all should even a single Ahamdi go astray? Why should our
          brother get lost after treading the righteous path? What I am worried
          about is the thought of their own persons, and it is not the Jama‘at
          that I am worried about. There is God who will take care of the
          Jama‘at as He has always done. He has always fulfilled the needs of
          the Jama‘at, and so he shall do in the future. Hence, if we move
          forward without creating an order in the Jama‘at and without bringing
          all the members up to the minimum standard, then the few, who are the
          first and foremost, will continue to bear the burden of sacrifices.
          And people will not even realize that these are the sacrifices of a
          few people and not of the whole Jama‘at. We also pray for our
          brothers, may Allah grant them understanding and the power and ability
          to offer sacrifices." (Friday Sermon, September 10, 1982)
        </p>
        <Divider />
        <h3>IT IS ESSENTIAL TO REMIND PEOPLE </h3>
        <p>
          "The reason why the Holy Qur’an has repeatedly instructed us to make
          financial sacrifices, is that these sacrifices purify people and
          cleanse their hearts. As a result of it, a believer attains greater
          Taqwa, the society is reformed and rejuvenated and gets rid of many
          evils. I can’t do without reminding the Jama‘at of this, because the
          true philosophy and spirit of sacrifice is that you are like beggars
          in the path of Allah, if you do not make sacrifices, you will be the
          losers……..And the Holy Qur’an teaches us that those who make
          sacrifices in the way of Allah, their possessions are greatly blessed
          by Him. One point which the Holy Qur’an makes is that you will grow
          richer by spending in the way of Allah, for in so doing you will come
          closer to the One who is Self-Sufficient, and if you cut asunder from
          Him, you will become poor. Thus, when religious communities forget
          about financial sacrifice, they are afflicted with adversity. On the
          other hand, if they eagerly offer sacrifices, they are blessed with
          countless bounties. We must understand this secret and use it also for
          national economic development." (Friday Sermon, September 28, 1990)
        </p>
        <Divider />
        <h3>PRINCIPAL OF REGULARITY IS SET OUT BY THE PROMISED MESSIAH (as)</h3>
        <p>
          "The principal of regularity (in payment) as set out by the Promised
          Messiah (as) is very fundamental. Even those who have little to eat
          have some sort of regularity in eating. It is not possible for someone
          to eat once and then stop eating for two months. Everyone, for whom it
          is possible, tries to be regular in his meals. This is why the
          Promised Messiah (as) has linked regularity in financial sacrifices to
          man’s spiritual survival. The Promised Messiah (as) accepts the
          sacrifice made in the way of Allah, but makes it clear that it makes
          no difference to us but it will make a difference to you who give.
          Once you have taken upon yourself to make this sacrifice, you must
          stick to your pledge with sincerity and be forever regular in your
          payments. The principal of giving according to one’s means but giving
          regularly has the seeds of progress in it. Whoever starts giving a
          little bit regularly will naturally increase his sacrifice and his
          means will also grow. Those who give thousands, reach hundreds of
          thousands, and those who give hundreds of thousands reach millions.
          This is the overall picture we get from the history of the Jama‘at.
          Those who gave paisas, but regularly and with sincerity, were soon
          enabled by Allah to sacrifice thousands and even hundreds of
          thousands. And from their progeny their came those who sacrificed
          millions and now we see sacrifices of tens of millions." (Friday
          Sermon, November 11, 1994)
        </p>
      </div>
    ),
  },
  {
    key: 'some-quotes-from-khalifatul-masih-v-aba-on-financial-sacrifice',
    icon: <FileTextOutlined />,
    title: 'Some Quotes from Khalifatul Masih V (aba) on Financial Sacrifice',
    article: true,
    content: (
      <div>
        <h3>KEEP YOUR AFFAIRS WITH ALLAH CLEAN AND STRAIGHTFORWARD </h3>
        <p>
          "If you acquire more income after the Budget has been drawn, you must
          not continue paying according to the Budget. Rather you must pay in
          relation to the blessings which Allah has showered upon you. If our
          dealings with Allah are straightforward, He, being the AllHearing and
          All-Knowing, will hear our prayer all the more. And if there is
          anything which we need for this age and for ourselves, it is the mercy
          and blessings of Allah and prayers which find acceptance at His
          threshold. So I beseech you that, for your prayers to be accepted, it
          is important that you keep your dealings with Allah clear." (Friday
          Sermon, June 6, 2003)
        </p>
        <Divider />
        <h3>SPEND YOUR HOLY THINGS IN THE WAY OF ALLAH</h3>
        <p>
          "……Hadrat ‘Uqba bin ‘Amir reported that the Holy Prophet (sa) said:
          "On the day of judgment, the one who has spent in the way of Allah,
          will remain under the shelter of what he has spent until the Reckoning
          is over." [Ahmad Bin Hambal] But this will only happen if the money
          that has been spent is pure and is spent out of a pure income. You
          can’t expect to win Divine blessings and to stay under the protection
          of your spending, if what you have spent is impure. Moreover, those
          with impure incomes never do spend in the way of Allah. Even if they
          manage to spend a rupee out of a hundred thousand, they tell everyone
          of the good they have done. On the other hand, the pious and righteous
          ones, who have the love of the faith in their hearts, and whose
          incomes are pure, spend out of their pure incomes and try their best
          that no one should know about what they have spent. Allah Almighty
          greatly reveres such people." (Friday Sermon, November 5, 2004)
        </p>
      </div>
    ),
  },
  {
    key: 'excerpt-from-khutba-ilhamiya-hadhrat-masih-maud-as',
    icon: <FileTextOutlined />,
    title: 'Excerpt from Khutba Ilhamiya Hadhrat Masih Maud (as)',
    article: true,
    content: (
      <div>
        <p>
          O People of God! ponder today on this day of Eidul Adhiyya, because
          there are many (spiritual) secrets for the wise that are hidden in
          these sacrifices. As you know, many animals are sacrificed on this day
          ... and all this is done solely for the sake of seeking Allah's
          pleasure, and has been done since the early days of Islam to this day,
          and I think that the sacrifices that are offered in (observation of)
          our illustrious Shariah are uncountably many. Our faith counts this
          act among those actions that lead to nearness to Allah.... and these
          sacrifices are among the highest forms of worship in our shariah. That
          is why these sacrifices are called "Nusuk" in Arabic, which also means
          obedience and submission.... So this clearly proves that the true
          worshiper is one who sacrifices his own self along with all its
          faculties and (love for) those objects of desire that pull his heart,
          for the sake of seeking the pleasure of his Lord... and nothing
          remained of his own self... and this is the true meaning of Islam and
          the essence of perfect obedience.
        </p>
        <p>
          Muslim is one who puts his face down in front of God Almighty to be
          sacrificed... and is never unmindful of death... So every believing
          man and believing woman who seeks the pleasure of the Loving God
          should understand this truth... Do not forget the status of this
          sacrifice … and do not remain oblivious to the wisdom that is hidden
          in these sacrifices. “Say, ‘My Prayer and my sacrifice and my life and
          my death are all for Allah, the Lord of the worlds’ (6:163)”. Qurbanis
          (Sacrifices) are the spiritual rides that take us towards Allah, and
          cover our faults and protect us from difficulties. … It is unfortunate
          that many people do not understand these hidden subtleties, and do not
          observe this instruction (of the Holy Prophet (peace be upon him), and
          to them Eid doesn’t mean anything except that they take a shower, wear
          new clothes, eat food… and then go out lavishly with their families
          and servants to celebrate Eid.
        </p>
        <p>
          So in these bleak times and on this dark night, God’s Mercy has
          demanded that a light descend from the Heavens, so I am that light,
          and that Mujaddid who has come down by the command of God Almighty …
          and I am that Mahdi whose advent was predestined, and I am that
          Messiah who was promised. God’s Grace desired to give to the world
          what it was yearning for, and that mercy be shown to all men and women
          … So good fortune be on you that the Messiah has come to you, and
          felicitations be on you because the promised Mahdi has come. …
        </p>
        <p>
          You do not think, and many signs have been shown for my sake but you
          do not care, and the heavens and the earth and the water and the dirt
          have borne testament for my sake but you do not fear.…O Brothers, our
          times bear a striking resemblance to this month because these are the
          end of times, and this month (the month of Dhul Hijjah) is the last
          among the months of this year, and both of them are drawing to an end.
          This last month has prescribed sacrifices and these end times also
          have prescribed sacrifices, the only difference is the original versus
          the reflection that is cast in a mirror, and an example (of these
          sacrifices) has happened before during the times of the Holy Prophet
          (peace be upon him). …
        </p>
        <p>
          I have been sent from my God with all possible power and blessing and
          honor … so fear God. O brave men, accept me! and do not deny and do
          not die in denial … Meet your Lord, make it a habit to turn towards
          with Him with complete devotion, so that you may be blessed with
          nearness to Allah and His Company.
        </p>
      </div>
    ),
  },
  {
    key: 'guarantee-for-the-ones-who-spend-in-the-way-of-allah',
    icon: <FileTextOutlined />,
    title: 'Guarantee for the Ones who Spend in the Way of Allah',
    article: true,
    content: (
      <div>
        <div style={{ textAlign: 'center', paddingBottom: 12 }}>
          <img src={KMFinancialIIIQuoteImg} alt="" />
        </div>
        <p>
          Hadhrat Masih Maud (as) states "I don't expect that anyone who spends
          his wealth in these times of need, will see any reduction in his
          wealth in any way, because of this expense. Hence one should
          participate with complete sincerity, enthusiasm, and effort, having
          trust in Allah Ta'ala that this is the time of service. Thereafter
          will come a time, where spending even a mountain of gold won't be
          equal to spending a single rupee in today's time."
        </p>
      </div>
    ),
  },
  {
    key: 'excerpt-from-friday-sermon-of-hazrat-musleh-maud-ra',
    icon: <FileTextOutlined />,
    title: "Excerpt from Friday Sermon of Hazrat Musleh Ma'ud (ra)",
    article: true,
    content: (
      <div>
        <h3>
          Excerpt Hadhrat Musleh Maud’s (ra) Friday Serm on 22nd June 1926
        </h3>
        <p>
          A few days ago, I heard that some people say that the Promised Messiah
          (as) has written that even if one Paisa is given within three months,
          it is sufficient. But here, you have been increasing it gradually.
          Donation has been increased to one Aana on each Rupia on the monthly
          income and now even thinking about increasing it further. It is
          correct that the Promised Messiah (as) has said exactly so. And it is
          also correct that those who came after increased the rate of donation.
          But we have to look at why this is done. I have already mentioned
          earlier that the likeness of Moses (as) advances quickly and the
          likeness of Jesus (as) gradually. And because the Promised Messiah
          (as) is the likeness of Jesus (as), therefore, the progress of his
          dispensation will also be gradual. And so just as the progress is
          going to take place gradually, sacrifice will also be gradual like the
          dispensation of Jesus which will continue to increase steadily. It is
          therefore necessary that there is a gradual increase in the financial
          sacrifice. Similar is the portion of Wasiyyat about which it has also
          been said that its ratio is being increased without reason. But one
          should also think about this. If now we do not have the ratio that was
          there at the time of the Promised Messiah (as) and on which people are
          doing Wasiyyat, then this also falls under the same principal that
          this progress would also be gradual. And just as the sacrifices are
          growing, purity and cleanliness is also increasing and the condition
          of faith is progressing, which, in return, becomes the source of even
          further sacrifice. If, for the advancement of the Jama‘at, I am very
          keen to collect funds, then this too increases the prodigiousness of
          our Jama‘at. Because, by advancing in sacrifice, the condition of our
          faith and sincerity becomes apparent to people. The condition of the
          Jama‘at was not at this stage in the time of the Promised Messiah (as)
          on which it is now. And by standing firm on this stage, ordinary
          members can make great sacrifices in an instance. But now as the time
          passes, faith is coming forth in such a way which the Promised Messiah
          (as) wanted to produce. And that is to say, this condition is coming
          about due to sacrifices which is taking place gradually.
        </p>
        <p>
          Hence, when the situation is such, it requires continuously more
          sacrifices. Thus, increasing the rate of Chanda and Wasiyyat signifies
          an increase of one's condition of faith. Even though this progress
          comes as a result of sacrifices, the progress further encourages
          sacrifice. Those who like to talk in vain may say that the rate of
          Chanda is being increased, yet do not realize that their faith is
          being attested in this way. The Promised Messiah (as) has also talked
          about abundance of wealth coming to his Jama'at. If the Jama'at was
          going to stay in the same condition as it was in the time of the
          Promised Messiah (as), would he have said that you will receive
          abundance of wealth. This saying of the Promised Messiah (as) proves
          that Jama'at will continue to gradually progress and will reach to a
          point that financial sacrifices would not hold any value for them.
        </p>
        <div style={{ textAlign: 'center', paddingTop: 12 }}>
          <img src={ExcerptFridaySermonImg} alt="" />
        </div>
      </div>
    ),
  },
];

const howToVideos = [
  {
    key: 'jamaat-usa-finance-portal-introduction',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/L4YtXC-nmYQ',
    thumbnail: 'https://img.youtube.com/vi/L4YtXC-nmYQ/hqdefault.jpg',
    title: 'Jamaat USA Finance Portal Introduction',
    video: true,
  },
  {
    key: 'how-to-make-a-one-time-donation',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/GkCsd6P6qbc',
    thumbnail: 'https://img.youtube.com/vi/GkCsd6P6qbc/hqdefault.jpg',
    title: 'How to make a one time donation',
    video: true,
  },
  {
    key: 'how-to-make-a-recurring-donations',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/bMA7XPz0xRY',
    thumbnail: 'https://img.youtube.com/vi/bMA7XPz0xRY/hqdefault.jpg',
    title: 'How to make a Recurring donations',
    video: true,
  },
  {
    key: 'how-to-make-a-loan-payment',
    icon: <VideoCameraOutlined />,
    url: 'https://www.youtube.com/embed/11G-MxNdhVA',
    thumbnail: 'https://img.youtube.com/vi/11G-MxNdhVA/hqdefault.jpg',
    title: 'How to make a Loan Payment',
    video: true,
  },
];

const Resources = {
  holy_quran,
  books,
  articles,
  videos,
  howToVideos,
};

export default Resources;
