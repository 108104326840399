import React, { useState } from 'react';
import { Button, InputNumber, Select, Radio, Tabs, Form } from 'antd';
import wasiyyat_rates from '../../utils/wasiyyat_rates';
import {
  chanda_jalsa_salana_rate,
  chanda_aam_rate,
  get_chanda_wasiyyat_rate,
} from '../../utils/chandas_ratio';
import { formatNumber } from '../../utils/formats';

const { Option } = Select;
const { TabPane } = Tabs;

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    padding: '0px 25px 25px',
  },
  quoteImages: {
    width: '100%',
    borderRadius: '6px',
  },
  form: {
    padding: '8px 0px',
  },
};

const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 8 },
  lg: { span: 9 },
  xl: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
  xl: { span: 6 },
  xxl: { span: 4 },
};

const tailWrapperCol = {
  sm: {
    span: 24,
    offset: 0,
  },
  md: { offset: 8 },
  lg: { offset: 9 },
  xl: { offset: 10 },
};

function CalculateChanda() {
  const [estimatedChanda, setEstimatedChange] = useState({
    aam: 0,
    jalsa_salana: 0,
  });
  const [activeKey, setActiveKey] = useState('form');
  const [form] = Form.useForm();

  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form: ', values);
        const newEstimatedChanda = {};

        newEstimatedChanda.jalsa_salana =
          Math.round(chanda_jalsa_salana_rate * values.net_income * 100) / 100;

        if (values.is_musi) {
          // User is musi: Chanda Wasiyyat and Chanda Jalsa Salana
          const wasiyyat_rate = get_chanda_wasiyyat_rate(values.wasiyyat_rate);
          newEstimatedChanda.wasiyyat = Math.max(
            Math.round(wasiyyat_rate * values.net_income * 100) / 100,
            300,
          );
        } else {
          // User is not musi: Chanda Aam and Chanda Jalsa Salana
          newEstimatedChanda.aam =
            Math.round(chanda_aam_rate * values.net_income * 100) / 100;
        }

        setActiveKey('results');
        setEstimatedChange(newEstimatedChanda);
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const resetCalculation = () => {
    form.resetFields();
    setEstimatedChange({});
    setActiveKey('form');
  };

  return (
    <Tabs
      activeKey={activeKey}
      tabBarStyle={{ display: 'none' }}
      style={{ minHeight: 300 }}
    >
      <TabPane tab="Chanda Form" key="form">
        <h3 style={{ textAlign: 'center' }}>
          Please provide your information below.
        </h3>
        <Form
          form={form}
          style={styles.form}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          scrollToFirstError
        >
          <Form.Item
            name="net_income"
            label="Net (after-tax) annual income"
            rules={[
              {
                required: true,
                message: 'Please, enter you net (after-tax) income',
              },
            ]}
          >
            <InputNumber
              autoComplete="off"
              min={1}
              step={100}
              style={{ width: 125 }}
            />
          </Form.Item>
          <Form.Item
            name="is_musi"
            label="Are you a Musi?"
            rules={[{ required: true, message: 'Please, select an option' }]}
          >
            <Radio.Group>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              if (form.getFieldValue('is_musi')) {
                return (
                  <Form.Item
                    name="wasiyyat_rate"
                    label="Wasiyyat Rate"
                    rules={[
                      {
                        required: true,
                        message: 'Please, select a Wasiyyat rate',
                      },
                    ]}
                    initialValue={10}
                  >
                    <Select showSearch autoComplete="none">
                      {wasiyyat_rates.map((x) => (
                        <Option key={x.value} value={x.value}>
                          {x.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }
              return null;
            }}
          </Form.Item>
          <Form.Item wrapperCol={tailWrapperCol}>
            <Button htmlType="submit" type="primary" onClick={handleSubmit}>
              How much should I pay?
            </Button>
          </Form.Item>
        </Form>
      </TabPane>
      <TabPane tab="Estimated Chanda" key="results">
        <h3 style={{ textAlign: 'center' }}>
          Based on information you've just provided, you should pay:
        </h3>
        <ul>
          {estimatedChanda.aam >= 0 ? (
            <li>
              <div>
                {`US$ ${formatNumber(estimatedChanda.aam)} for Chanda Aam`}
              </div>
            </li>
          ) : (
            <li>
              <div>
                {`US$ ${formatNumber(
                  estimatedChanda.wasiyyat,
                )} for Chanda Wasiyyat`}
              </div>
            </li>
          )}
          <li>
            <div>
              {`US$ ${formatNumber(
                estimatedChanda.jalsa_salana,
              )} for Jalsa Salana`}
            </div>
          </li>
          {/* eslint-disable max-len */}
          <li>
            <div>
              This does not take into account the auxilliary chandajat. Those
              can be calculated and paid on the respective{' '}
              <a
                href="https://amanat.ansarusa.org/donate"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ansar
              </a>{' '}
              and{' '}
              <a
                href="https://chanda.mkausa.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Khuddam
              </a>{' '}
              chanda portals. Lajna currently does not offer online payments
            </div>
          </li>
        </ul>
        <Form.Item wrapperCol={tailWrapperCol}>
          <Button onClick={resetCalculation}>Calculate again</Button>
        </Form.Item>
      </TabPane>
    </Tabs>
  );
}

export default CalculateChanda;
