export const daftar = [
  {
    value: 1,
    label: 'Daftar I (1934-1944)',
  },
  {
    value: 2,
    label: 'Daftar II (1944-1965)',
  },
  {
    value: 3,
    label: 'Daftar III (1965-1985)',
  },
  {
    value: 4,
    label: 'Daftar IV (1985-2004)',
  },
  {
    value: 5,
    label: 'Daftar V (2004-2023)',
  },
  {
    value: 6,
    label: 'Daftar VI (2023-Present)',
  },
];

export const relationships = [
  {
    value: 1,
    label: 'Father',
  },
  {
    value: 2,
    label: 'Mother',
  },
  {
    value: 3,
    label: 'Grandfather',
  },
  {
    value: 4,
    label: 'Grandmother',
  },
  {
    value: 5,
    label: 'Son',
  },
  {
    value: 6,
    label: 'Daughter',
  },
  {
    value: 7,
    label: 'Father-in-Law',
  },
  {
    value: 8,
    label: 'Mother-in-Law',
  },
  {
    value: 9,
    label: 'Brother',
  },
  {
    value: 10,
    label: 'Sister',
  },
  {
    value: 11,
    label: 'Other',
  },
];

export function getDaftarLabel(id) {
  return daftar.find((x) => x.value === id)?.label ?? '-';
}

export function getRelationLabel(id) {
  return relationships.find((x) => x.value === id)?.label ?? '-';
}
