import { formVariables } from './form';
import { capitalizeFirstLetter } from './formats';

const labels = [
  { property: 'donor_name', label: formVariables.payer_name.label },
  { property: 'donor_jamaat', label: formVariables.jamaat.label },
  { property: 'AMC', label: formVariables.member_code.label },
  { property: 'comment', label: formVariables.comment.label },
  { property: 'category', label: 'Category' },
  { property: 'TOT', label: 'Total amount of donations' },

  {
    property: 'payee_payment_recurrence',
    label: formVariables.payment_recurrence.label,
  },
  {
    property: 'payee_payment_start_date',
    label: formVariables.payment_recurrence_startDate.label,
  },
  {
    property: 'payee_payment_recurrence_frequency',
    label: formVariables.payment_recurrence_frequency.label,
  },
  {
    property: 'payee_payment_end_date',
    label: formVariables.payment_recurrence_endDate.label,
  },

  { property: 'payee_name', label: 'Billing Name' },
  { property: 'donor_email', label: formVariables.email.label },
  { property: 'payee_address', label: formVariables.address.label },
  { property: 'payee_city', label: formVariables.city.label },
  { property: 'payee_state', label: formVariables.state.label },
  { property: 'payee_zip', label: formVariables.zipCode.label },
  { property: 'donor_phone', label: formVariables.phone.label },
  { property: 'payee_payment_type', label: formVariables.payment_type.label },
  { property: 'payee_total_payment', label: 'Total payment' },
  { property: 'payee_transaction_fee', label: 'Transaction fee' },
  { property: 'payee_update_jamaat_address', label: "Update Jama'at Address" },
  {
    property: 'payee_apply_address_to_family',
    label: 'Apply Address to Family',
  },
  { property: 'payee_asume_processing_fee', label: 'Asume Procesing Fee' },
];

/**
 * @param {String} property Property name of the variable
 * @returns Returns the readable label of the property name for the user
 */
export const getVariableLabel = (property) => {
  const foundLabel = labels.find((x) => x.property === property);
  return foundLabel ? foundLabel.label : '';
};

/**
 * @param {String} key property of the field
 * @param {String} value value of the field
 * @returns value of the field parsed depending on the key
 */
export const getSpecialValues = (key, value) => {
  if (
    ['payee_payment_recurrence', 'payee_payment_recurrence_frequency'].indexOf(
      key,
    ) > -1
  ) {
    if (value === 'oneTime') return 'One time';
    return capitalizeFirstLetter(value);
  }
  return value;
};
