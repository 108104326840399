export const matchStringWord = function matchStringWord(typed = '', text = '') {
  const normalizedTypedText = typed
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return (
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .substr(0, normalizedTypedText.length) === normalizedTypedText
  );
};

export const getAutocompleteDataSource = function getAutocompleteDataSource(
  typedText = '',
  data = [],
  disableNewOptions = false,
) {
  const newText = typedText ? typedText.toLowerCase() : '';
  const newData = disableNewOptions
    ? [].concat(data)
    : [...new Set([newText, ...data])];
  return newData.filter((x) => matchStringWord(newText, x));
};

export const sortArrayByString = (array) =>
  array.sort((a, b) => a.localeCompare(b));
