export const chanda_jalsa_salana_rate = 0.008333333;
export const chanda_aam_rate = 0.0625;

/**
 * Function
 *  -> Gets the Wasiyyat ratio by giving the denominator number
 *
 * @param {number} wasiyyat_rate - Wasiyyat rate denominator.
 * Example:
 *  if rate is 1/10,then wasiyyat_rate is 10; if rate is 1/7, then wasiyyat_rate is 7
 *
 * @return {number} 0.1 if no arguments are given
 */
export const get_chanda_wasiyyat_rate = function get_chanda_wasiyyat_rate(
  wasiyyat_rate,
) {
  return wasiyyat_rate ? 1 / wasiyyat_rate : 0.1;
};

/**
 *  - Checks if the chanda ratios are correct
 *  - In case the ratios are incorrect, gives the ideal amounts without changing the total donation
 *
 * @param {Object} chandaPayerDonations
 * - Object containing the form properties and values for the specific chanda donor
 *
 * @return {Object}
 * @return {Boolean} valid_ratios
 * - True if chanda ratios are correct; otherwise, false.
 * @return {Object} corrected_amounts
 * - Object containing the corrected chanda amounts
 * @return {number} corrected_amounts.corrected_jalsa_salana
 * - If valid_ratios is false, then it gives the corrected chanda jalsa salana; otherwise, undefined
 * @return {number} corrected_amounts.corrected_aam
 * - If valid_ratios is false, then it gives the corrected chanda aam amount; otherwise, undefined
 * @return {number} corrected_amounts.corrected_wasiyyat
 * - If valid_ratios is false, then it gives the corrected chanda wasiyyat; otherwise, undefined
 * @return {number} corrected_amounts.jalsa_salana
 * - If valid_ratios is false, then it gives the previous chanda jalsa salana; otherwise, undefined
 * @return {number} corrected_amounts.aam
 * - If valid_ratios is false, then it gives the previous chanda aam amount; otherwise, undefined
 * @return {number} corrected_amounts.wasiyyat
 * - If valid_ratios is false, then it gives the previous chanda wasiyyat; otherwise, undefined
 *
 */
export const checkChandasRatios = function checkChandasRatios(
  chandaPayerDonations,
) {
  let valid_ratios = false;
  const corrected_amounts = {};
  // console.log(chandaPayerDonations);
  const { aam, wasiyyat } = chandaPayerDonations;
  const jalsa_salana = chandaPayerDonations.jalsa_salana || 0;
  // Reminder: only one between aam and wasiyyat should have been filled
  // Don't care about that here, because validation was done before submitting this form
  const jalsa_salana_rate = chanda_jalsa_salana_rate;
  const aam_rate = chanda_aam_rate;
  const wasiyyat_rate = get_chanda_wasiyyat_rate(
    chandaPayerDonations.wasiyyat_rate,
  );
  if (aam || wasiyyat) {
    // Checking ratio between aam (or chanda_wasiyat) and jalsa_salana
    const sensitivity = 0.003333;
    const ideal_ratio = aam
      ? jalsa_salana_rate / aam_rate
      : jalsa_salana_rate / wasiyyat_rate;
    const lower_bound = ideal_ratio - sensitivity;
    const upper_bound = ideal_ratio + sensitivity;
    const actual_ratio = aam ? jalsa_salana / aam : jalsa_salana / wasiyyat;
    // console.log('Lower Bound Ratio', lower_bound);
    // console.log('Upper Bound Ratio', upper_bound);
    // console.log('Actual Ratio', actual_ratio);
    if (lower_bound <= actual_ratio && actual_ratio <= upper_bound) {
      // Ratios are according to the boundary
      valid_ratios = true;
    } else {
      // Ratios are incorrect, we must give the correct amounts without changing the total.
      const sum = aam ? aam + jalsa_salana : wasiyyat + jalsa_salana;

      if (aam) {
        const corrected_aam = sum / (ideal_ratio + 1);
        const corrected_jalsa_salana = sum - corrected_aam;
        corrected_amounts.corrected_aam = Math.round(corrected_aam * 100) / 100;
        corrected_amounts.corrected_jalsa_salana =
          Math.round(corrected_jalsa_salana * 100) / 100;
        // Save previous values to prompt message
        corrected_amounts.aam = aam;
        corrected_amounts.jalsa_salana = jalsa_salana;
      } else {
        // Btw, wasiyyat is defined in this case.
        const corrected_wasiyyat = sum / (ideal_ratio + 1);
        const corrected_jalsa_salana = sum - corrected_wasiyyat;
        corrected_amounts.corrected_wasiyyat =
          Math.round(corrected_wasiyyat * 100) / 100;
        corrected_amounts.corrected_jalsa_salana =
          Math.round(corrected_jalsa_salana * 100) / 100;
        // Save previous values to prompt message
        corrected_amounts.wasiyyat = wasiyyat;
        corrected_amounts.jalsa_salana = jalsa_salana;
      }
    }
  } else if (jalsa_salana) {
    // Jalsa Salana was the only one entered between the three (Aam, Wasiyyat, Jalsa Salana)
    valid_ratios = false;
  } else {
    // Business-as-usual.
    valid_ratios = true;
  }

  return { valid_ratios, corrected_amounts };
};
