import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  InputNumber,
  Select,
  Tooltip,
  Input,
  Row,
  Col,
  Divider,
  Form,
} from 'antd';
import {
  formVariables,
  requiredMessages,
  validateMemberCode,
} from '../../utils/form';
import jamaatList from '../../utils/jamaat';
import { formatNumber } from '../../utils/formats';
import loans from '../../utils/loans';

const { Option } = Select;
const labelCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10 },
};
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 14 },
  lg: { span: 12 },
  xl: { span: 10 },
  xxl: { span: 8 },
};

const loansLabelCol = {
  xs: { span: 24 },
  style: {
    textAlign: 'center',
    whiteSpace: 'inherit',
    textOverflow: 'ellipsis',
  },
};
const loansWrapperCol = {
  xs: { span: 24 },
  style: { textAlign: 'center' },
};

/**
 * - Returns the amount selected for the current loans donor
 *
 * @param {Object} formValues Object containing the form properties and values
 *
 * @return {number} Total donations made for the current loans donor
 */
const getTotalAmount = (formValues) => {
  let totalAmount = 0;
  // Loans
  if (formValues.loans) {
    for (let i = 0; i < Object.keys(formValues.loans).length; i++) {
      const key = Object.keys(formValues.loans)[i];
      if (formValues.loans[key] * 1 > 0) {
        totalAmount += formValues.loans[key];
      }
    }
  }
  return Math.round(totalAmount * 100) / 100;
};

function LoansForm({ handleSubmit, loans_form }) {
  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        // Passing just the selected orders
        const newLoans = {};
        if (values.loans) {
          for (let i = 0; i < Object.keys(values.loans).length; i++) {
            const key = Object.keys(values.loans)[i];
            if (values.loans[key] > 0) {
              newLoans[key] = values.loans[key];
            }
          }
        }
        const formValuesObj = {
          payer_name: values.payer_name,
          jamaat: values.jamaat,
          member_code: values.member_code,
          comment: values.comment,
          loans: newLoans,
        };
        const totalAmount = getTotalAmount(values);

        console.log('Ordered values of form:', formValuesObj);
        if (totalAmount > 0) {
          if (totalAmount > 75000) {
            message.destroy();
            message.error('The maximum total amount is US$ 75,000.00');
            return;
          }
          handleSubmit(formValuesObj, totalAmount);
        } else {
          message.destroy();
          message.error('You have not entered any loans payment');
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  return (
    <Form
      form={form}
      className="chandas-form"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      initialValues={loans_form}
      scrollToFirstError
    >
      <div>
        <Row
          style={{
            padding: 24,
            background: '#fbfbfb',
            border: '1px solid #d9d9d9',
            borderRadius: '6px',
            marginBottom: 12,
          }}
        >
          <Col xs={24}>
            <Row
              type="flex"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Col lg={14} md={18} sm={22} xs={24}>
                <Form.Item
                  name={formVariables.payer_name.property}
                  label={formVariables.payer_name.label}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(
                        formVariables.payer_name.property,
                      ),
                    },
                    { max: 64, message: 'Maximum characters allowed are 64' },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name={formVariables.jamaat.property}
                  label={formVariables.jamaat.label}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(formVariables.jamaat.property),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    autoComplete="none"
                    optionFilterProp="children"
                    showAction={['focus', 'click']}
                  >
                    {jamaatList.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={formVariables.member_code.property}
                  label={formVariables.member_code.label}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: requiredMessages(
                        formVariables.member_code.property,
                      ),
                    },
                    {
                      validator: (rules, values, callback) =>
                        callback(validateMemberCode(values)),
                    },
                  ]}
                >
                  <InputNumber
                    className="input-number-no-stepper"
                    autoComplete="off"
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>
              <Divider />
              <Col xs={24}>
                <div align="center" style={{ paddingBottom: 12 }}>
                  Only make a payment if you have taken out one of these loans
                </div>
              </Col>
              {loans.map((x) => (
                <Col lg={8} md={12} sm={22} xs={24} key={x.name}>
                  <Form.Item
                    labelCol={loansLabelCol}
                    wrapperCol={loansWrapperCol}
                    key={x.name}
                    name={['loans', x.name]}
                    rules={[{ required: false }]}
                    colon={false}
                    label={
                      <span>
                        {x.label}{' '}
                        <Tooltip title={x.description}>
                          <InfoCircleOutlined style={{ color: '#8a8888' }} />
                        </Tooltip>
                      </span>
                    }
                  >
                    <InputNumber autoComplete="off" min={0} step={1} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
      <Row justify="center" style={{ paddingTop: 24 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={8}>
          <Form.Item
            labelCol={loansLabelCol}
            wrapperCol={loansWrapperCol}
            label="Leave a comment (optional)"
            name={formVariables.comment.property}
            rules={[
              { max: 150, message: 'Maximum characters allowed are 150' },
            ]}
            extra={
              <Form.Item
                noStyle
                shouldUpdate={(prev, cur) =>
                  prev[formVariables.comment.property] !==
                  cur[formVariables.comment.property]
                }
              >
                {() => {
                  const commentText = form.getFieldValue(
                    formVariables.comment.property,
                  );
                  return (
                    <div align="end">
                      {`${commentText ? commentText.length : 0} / 150`}
                    </div>
                  );
                }}
              </Form.Item>
            }
          >
            <Input.TextArea
              maxLength={150}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ padding: '12px 24px 0px', textAlign: 'right' }}>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              <div>Total:</div>
              <div
                style={{ padding: '0px 4px', fontWeight: 'bold', fontSize: 16 }}
              >
                {`US$ ${formatNumber(
                  Number(getTotalAmount(form.getFieldsValue())).toFixed(2),
                )}`}
              </div>
            </>
          )}
        </Form.Item>
        <Button htmlType="submit" type="primary" onClick={handleCreate}>
          Next
        </Button>
      </div>
    </Form>
  );
}

export default LoansForm;
