export const TAHRIK_E_JADID = 'tahrik_e_jadid';

const chandas = [
  {
    name: 'zakat',
    label: 'Zakat',
    default: true,
    description:
      'One of the five pillars of Islam, paid on savings held for more than a year above Nisab. Rate is 2.5% of the savings.',
  },
  {
    name: 'aam',
    label: 'Aam',
    default: true,
    description:
      'Chanda Aam is the basic and compulsory Chanda which the Promised Messiah علیہ السلام has made obligatory upon every earning Ahmadi, man and woman, and is to be paid at the rate of 1/16th or 6.25% on the take home income.',
  },
  {
    name: 'wasiyyat',
    label: 'Wasiyyat',
    default: true,
    description:
      'For the members who joined the divine scheme of Wasiyyat set up by Promised Messiah علیہ السلام, depending on the Wasiyyat rate (1/10 to 1/3) to be paid on the take home income.',
  },
  {
    name: 'jalsa_salana',
    label: 'Jalsa Salana',
    default: true,
    description:
      'Mandatory Chanda for all earning members (man & woman) to support Jalsa Salana. Started by Promised Messiah (علیہ السلام). Rate is 1/120th of annual income.',
  },
  {
    name: 'sadqa',
    label: 'Sadqa',
    default: true,
    description: 'Funds given for the assistance of people in need.',
  },
  {
    name: TAHRIK_E_JADID,
    label: 'Tahrik-e-Jadid',
    default: true,
    description:
      'A divine scheme launched by Hadhrat Musleh Maud (رضی اللہ تعالیٰ عنہ) in 1934 for the propagation of Islam across the globe.',
  },
  {
    name: 'waqf_e_jadid',
    label: 'Waqf-e-Jadid',
    default: true,
    description:
      'A divine scheme launched by Hadhrat Musleh Maud (رضی اللہ تعالیٰ عنہ) in 1957 to provide education and training to rural Jama‘ats, as well as to take the message of Tauhid to idol worshippers.',
  },
  {
    name: 'mta_satellite_transmission',
    label: 'MTA - Satellite Transmission',
    default: true,
    description:
      'Supports propagation of the message to all corners of the earth through MTA.',
  },
  {
    name: 'minarat_zion',
    label: 'Minaratul Masih Zion',
    default: true,
    description:
      'For the construction of Minaratul Masih in Zion, USA.',
  },
  // *
  // *
  // *
  // Chandas that are not displayed by default:
  {
    name: 'shart_e_awwal',
    label: 'Chanda Shart-e-Awwal',
    description:
      'The funds to be given by the prospective Musi before joining the blessed scheme of Wasiyyat.',
  },
  {
    name: 'ailan_e_wasiyyat',
    label: 'Chanda Ai‘lan-e-Wasiyyat',
    description:
      'The funds to be given by the prospective Musi before joining the blessed scheme of Wasiyyat for the announcement of his will',
  },
  {
    name: 'aus_mosque',
    label: 'Austin Mosque (Austin Membership)',
    description: 'Austin Mosque (for members of Austin Jamaat only)',
  },
  {
    name: 'bos_mosque',
    label: 'Boston Mosque (Boston Membership)',
    description: 'Boston Mosque (for members of Boston Jamaat only)',
  },
  {
    name: 'brk_mosque',
    label: 'Brooklyn Mosque (Brooklyn Membership)',
    description: 'Brooklyn Mosque (for members of Brooklyn Jamaat only)',
  },
  {
    name: 'cej_mosque',
    label: 'Central Jersey Mosque (Central Membership)',
    description: 'Central Mosque (for members of Central Jamaat only)',
  },
  {
    name: 'buf_mosque',
    label: 'Buffalo Mosque (Buffalo Membership)',
    description: 'Buffalo Mosque (for members of Buffalo Jamaat only)',
  },
  {
    name: 'col_mosque',
    label: 'Columbus Mosque (Columbus Membership)',
    description: 'Columbus Mosque (for members of Columbus Jamaat only)',
  },
  {
    name: 'clv_mosque',
    label: 'Cleveland Mosque (Cleveland Membership)',
    description: 'Cleveland Mosque (for members of Cleveland Jamaat only)',
  },
  {
    name: 'har_mosque',
    label: 'Connecticut Mosque (Connecticut Membership)',
    description: 'Connecticut Mosque (for members of Connecticut Jamaat only)',
  },
  {
    name: 'dal_mosque',
    label: 'Dallas Mosque (Dallas Membership)',
    description: 'Dallas Mosque (for members of Dallas Jamaat only)',
  },
  {
    name: 'det_mosque',
    label: 'Detroit Mosque (Detroit Membership)',
    description: 'Detroit Mosque (for members of Detroit Jamaat only)',
  },
  {
    name: 'fch_mosque',
    label: 'Fitchburg Mosque (Fitchburg Membership)',
    description: 'Fitchburg Mosque (for members of Fitchburg Jamaat only)',
  },
  {
    name: 'geo_mosque',
    label: 'Georgia Mosque (Georgia Membership)',
    description: 'Georgia Mosque (for members of Georgia Jamaat only)',
  },
  {
    name: 'hun_mosque',
    label: 'Houston Mosque (Houston Membership)',
    description: 'Houston Mosque (for members of Houston Jamaat only)',
  },
  {
    name: 'ind_mosque',
    label: 'Indiana Mosque (Indiana Membership)',
    description: 'Indiana Mosque (for members of Indiana Jamaat only)',
  },
  {
    name: 'kac_mosque',
    label: 'Kansas Mosque (Kansas Membership)',
    description: 'Kansas Mosque (for members of Kansas Jamaat only)',
  },
  {
    name: 'knt_mosque',
    label: 'Kentucky Mosque (Kentucky Membership)',
    description: 'Kentucky Mosque (for members of Kentucky Jamaat only)',
  },
  {
    name: 'lis_mosque',
    label: 'Long Island Mosque (LIS Membership)',
    description: 'Long Island Mosque (for members LIS membership only)',
  },
  {
    name: 'lax_property',
    label: 'Los Angeles Property (West Coast Membership)',
    description:
      'Los Angeles Property (for members of West Coast membership only)',
  },
  {
    name: 'mia_mosque',
    label: 'Miami Mosque (Miami Membership)',
    description:
      'Miami Mosque (for members of Miami membership only)',
  },
  {
    name: 'mil_mosque',
    label: 'Milwaukee Mosque (Milwaukee Membership)',
    description: 'Milwaukee Mosque (for members of MIL membership only)',
  },
  {
    name: 'orl_mosque',
    label: 'Orlando Mosque (Orlando Membership)',
    description: 'Orlando Mosque (for members of ORL membership only)',
  },
  {
    name: 'saj_mosque',
    label: 'Silicon Valley Mosque (Silicon Valley Membership)',
    description: 'Silicon Valley Mosque Mosque (for members of SAJ membership only)',
  },
  {
    name: 'res_mosque',
    label: 'RTP Mosque (RTP Membership)',
    description:
      'Research Triangle Park Mosque (for members of RES membership only)',
  },
  {
    name: 'cva_mosque',
    label: 'VA Hall Project (CVA/NVA Membership)',
    description: 'VA Mosque (for members of CVA/NVA Jamaat only)',
  },
  {
    name: 'wil_mosque',
    label: 'Willingboro (Willingboro Membership)',
    description: 'Willingboro Mosque (for members of Willingboro Jamaat only)',
  },
  {
    name: 'laj_center',
    label: 'Lajna National Conference Center',
    description: 'Lajna National Conference Center',
  },
  {
    name: 'boyut_ul_hamd',
    label: 'Boyut-ul-Hamd',
    description:
      'Inaugurated by Hazrat Khalifa tul Masih Rabeh (rh) To provide free accommodation to one hundred poor families.',
  },
  {
    name: 'dar_ul_yatama',
    label: 'Dar-ul-Yatama',
    description: 'To look after the needs of orphans.',
  },
  {
    name: 'darvesh_qadian',
    label: 'Darvesh Qadian',
    description: 'For descendants of original Darvesh families.',
  },
  {
    name: 'eid_fund',
    label: 'Eid Fund',
    description:
      'Set up by Promised Messiah (علیہ السلام) with purpose to support the deserving families on the joyous occasion of Eid ul Fitr.',
  },
  {
    name: 'fazl_e_umar_hospital',
    label: 'Fazl-e-Umar Hospital',
    description:
      'Supports the operation of Fazl-e-Umar hospital in Rabwah which provides low cost and free healthcare to all regardless of their faith.',
  },
  {
    name: 'fidya',
    label: 'Fidya',
    description:
      'For people who are unable to observe their fast in Ramadan because of illness, inability or travel etc., the Quranic injunction is that they should pay Fidya as a recompense for the lost opportunity to observe fast.',
  },
  {
    name: 'fitrana',
    label: 'Fitrana',
    description:
      'Obligatory on all members (including children).  Its purpose is to help the poor brethren of the community so that they can fully enjoy the happiness of Eidul Fitr.',
  },
  {
    name: 'hissa_jaidad',
    label: 'Hissa Jaidad',
    description:
      'Hissa Jaidad is the Chanda which Musian pay after making formal assessment of their property.',
  },
  {
    name: 'tulaba',
    label: 'Imadad Tulaba',
    description: 'Assistance of deserving students',
  },
  {
    name: 'maryam_shadi_fund',
    label: 'Maryam Shadi Fund',
    description:
      'To support deserving families in bearing the cost of marriages of their daughters.',
  },
  {
    name: 'mareezan',
    label: 'Nadaar Mareezan',
    description: 'Assistance for sick patients',
  },
  {
    name: 'national_mosque_fund',
    label: 'National Mosque Fund',
    description:
      'Supports building of various mosques throughout USA. Optional. No set rate.',
  },
  {
    name: 'refugee_fund',
    label: 'Refugee Fund',
    description: 'Fund to help refugees settle in the US',
  },
  {
    name: 'review_of_religions',
    label: 'Review of Religions',
    description: 'Review of Religions (non-subscription)',
  },
  {
    name: 'sadqa_bakra',
    label: 'Sadqa Bakra',
    description:
      'Donation of a goat. Funds sent to Markaz for the donation to be done in the country of their choice.',
  },
  {
    name: 'syedna_bilal_fund',
    label: 'Syedna Bilal Fund',
    description: 'Supports the families of martyrs.',
  },
  {
    name: 'tahir_heart_institute',
    label: 'Tahir Heart Institute',
    description:
      'Supports the Tahir Heart Institute in Rabwah which provides low cost and free cardiac services to all regardless of their faith. Optional. No set rate.',
  },
  {
    name: 'tahir_homeopathic_clinic',
    label: 'Tahir Homeopathic Clinic',
    description:
      'Supports the Tahir Homeopathic hospital in Rabwah which provides low cost and free healthcare to all regardless of their faith. Optional. No set rate.',
  },
  {
    name: 'tic_alumni_usa',
    label: 'TIC Alumni USA',
    description:
      'Membership fee for the Alumni Association of Talimul Islam College.',
  },
  {
    name: 'zion_mosque_for_lajna',
    label: 'Zion Mosque Fund For Lajna',
    description:
      'For the construction of the mosque in Zion, IL by members of Lajna Imaillah USA.',
  },
  {
    name: 'zion_project',
    label: 'Zion Project',
    description: 'For the construction of Zion Project.',
  },
  {
    name: 'funeral',
    label: 'Funeral',
    description: 'Provide Assistance for funeral costs',
  },
  {
    name: 'students_1',
    label: 'Ahmadi Muslim Scientists',
    description: 'Ahmadi Muslim Scientists',
  },
  {
    name: 'awsa_general',
    label: 'Ahmadiyya Women Scientist Association',
    description: 'Ahmadi Women Scientist Association',
  },
];

export const getChandaLabel = (name) => {
  const foundChanda = chandas.find((x) => x.name === name);
  return foundChanda ? foundChanda.label : '';
};

export default chandas;
