const jamaat = [
  { label: 'Alabama/Tennessee, AL', value: 'ALA', id: 1 },
  { label: 'Albany, NY', value: 'ALB', id: 2 },
  { label: 'Austin, TX', value: 'AUS', id: 3 },
  { label: 'Baltimore, MD', value: 'BAL', id: 4 },
  { label: 'Bay Point, CA', value: 'BAP', id: 5 },
  { label: 'Binghamton, NY', value: 'BIG', id: 6 },
  { label: 'Boston, MA', value: 'BOS', id: 7 },
  { label: 'Brooklyn, NY', value: 'BRK', id: 9 },
  { label: 'Buffalo, NY', value: 'BUF', id: 10 },
  { label: 'Central Jersey, NJ', value: 'CEJ', id: 11 },
  { label: 'Charlotte, NC', value: 'CHA', id: 13 },
  { label: 'Chicago, IL', value: 'CHC', id: 15 },
  { label: 'Cleveland, OH', value: 'CLV', id: 16 },
  { label: 'Columbus, OH', value: 'COL', id: 17 },
  { label: 'Connecticut, CT', value: 'HAR', id: 18 },
  { label: 'Dallas, TX', value: 'DAL', id: 19 },
  { label: 'Dayton, OH', value: 'DAY', id: 20 },
  { label: 'Detroit, MI', value: 'DET', id: 21 },
  { label: 'Fitchburg, MA', value: 'FCH', id: 22 },
  { label: 'Fort Worth, TX', value: 'FTW', id: 23 },
  { label: 'Georgia-Carolina, GA', value: 'GEO', id: 24 },
  { label: 'Hawaii, HI', value: 'HWI', id: 25 },
  { label: 'Houston, TX', value: 'HUN', id: 27 },
  { label: 'Indiana, IN', value: 'IND', id: 28 },
  { label: 'Iowa, IA', value: 'IWA', id: 29 },
  { label: 'Kansas City, MO', value: 'KAC', id: 30 },
  { label: 'Kentucky, KY', value: 'KNT', id: 31 },
  { label: 'Las Vegas, NV', value: 'LAV', id: 33 },
  { label: 'Lehigh Valley, PA', value: 'LEI', id: 34 },
  { label: 'Long Island, NY', value: 'LIS', id: 35 },
  { label: 'Los Angeles, CA', value: 'LAX', id: 36 },
  { label: 'Maryland, MD', value: 'MAR', id: 37 },
  { label: 'Miami, FL', value: 'MIA', id: 40 },
  { label: 'Milwaukee, WI', value: 'MIL', id: 42 },
  { label: 'Minnesota, MN', value: 'STP', id: 43 },
  { label: 'Queens, NY', value: 'QNS', id: 45 },
  { label: 'North Jersey, NJ', value: 'NOJ', id: 46 },
  { label: 'North Virginia, VA', value: 'NVA', id: 47 },
  { label: 'Orlando, FL', value: 'ORL', id: 48 },
  { label: 'Oshkosh, WI', value: 'OSK', id: 49 },
  { label: 'Philadelphia PA', value: 'PHI', id: 50 },
  { label: 'Phoenix AZ', value: 'PHX', id: 51 },
  { label: 'Pittsburgh, PA', value: 'PIT', id: 52 },
  { label: 'Portland, OR', value: 'POR', id: 53 },
  { label: 'Puerto Rico, PR', value: 'PRI', id: 54 },
  { label: 'Richmond VA, VA', value: 'RVA', id: 55 },
  { label: 'Rochester, NY', value: 'ROC', id: 56 },
  { label: 'Research Triangle Park, NC', value: 'RES', id: 58 },
  { label: 'Sacramento, CA', value: 'SAC', id: 59 },
  { label: 'San Diego, CA', value: 'SAD', id: 60 },
  { label: 'Seattle, WA', value: 'SEA', id: 61 },
  { label: 'Silicon Valley, CA', value: 'SAJ', id: 62 },
  { label: 'South Virginia, VA', value: 'SVA', id: 63 },
  { label: 'St Louis, MO', value: 'STL', id: 64 },
  { label: 'Syracuse, NY', value: 'SRC', id: 65 },
  { label: 'Tucson, AZ', value: 'TUC', id: 66 },
  { label: 'Tulsa, OK', value: 'TUL', id: 67 },
  { label: 'Willingboro, NJ', value: 'WIL', id: 68 },
  { label: 'Harrisburg, PA', value: 'YRK', id: 69 },
  { label: 'Zion, IL', value: 'ZON', id: 70 },
];

export function getBranchId(value) {
  return jamaat.find((x) => x.value === value).id;
}

export default jamaat;
