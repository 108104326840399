import React from 'react';
import { Card, Avatar, Row, Col, Button, Divider } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import Resources from '../../utils/resources';
import routes from '../../routes';
import './ResourcesMain.css';

const styles = {
  mainContainer: {
    // padding: '0px 25px 25px',
  },
};

const ResourceCard = ({ item, history }) => (
  <Col
    key={item.key}
    xs={24}
    sm={24}
    md={24}
    lg={12}
    xl={8}
    xxl={6}
    className="resource_card_col"
  >
    <div className="resource_card">
      {item.video ? (
        <div className="resource_card_body">
          <div className="resource_card_title">{item.title}</div>
          <div style={{ textAlign: 'center' }}>
            <img
              src={item.thumbnail}
              alt=""
              style={{ width: '100%', borderRadius: 2 }}
            />
          </div>
        </div>
      ) : (
        <div className="resource_card_body">
          <Card.Meta
            avatar={
              <Avatar
                style={{ background: '#545454' }}
                icon={item.icon || <LinkOutlined />}
              />
            }
            title={
              <div style={{ whiteSpace: 'break-spaces' }}>{item.title}</div>
            }
            description={
              item.thumbnail ? (
                <div style={{ textAlign: 'center' }}>
                  <img src={item.thumbnail} alt="" style={{ width: '100%' }} />
                </div>
              ) : (
                item.description
              )
            }
          />
        </div>
      )}
      <div className="resource_card_action">
        {item.url && !item.video ? (
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
          >
            View
          </a>
        ) : (
          <Button
            type="text"
            style={{
              color: 'rgba(0, 0, 0, 0.45)',
              fontSize: 14,
            }}
            onClick={() => history.push(`${routes.resources}/${item.key}`)}
          >
            View
          </Button>
        )}
      </div>
    </div>
  </Col>
);

function ResourcesMain({ history }) {
  return (
    <div style={styles.mainContainer}>
      <h1 align="center">Resources</h1>
      <Row style={{ alignItems: 'stretch' }} gutter={[24, 24]}>
        <Divider>Holy Quran</Divider>
        {Resources.holy_quran.map((item) => (
          <ResourceCard key={item.key} item={item} history={history} />
        ))}

        <Divider>Videos</Divider>
        {Resources.videos.map((item) => (
          <ResourceCard key={item.key} item={item} history={history} />
        ))}

        <Divider>Articles</Divider>
        {Resources.articles.map((item) => (
          <ResourceCard key={item.key} item={item} history={history} />
        ))}

        <Divider>Books</Divider>
        {Resources.books.map((item) => (
          <ResourceCard key={item.key} item={item} history={history} />
        ))}

        <Divider>How To</Divider>
        {Resources.howToVideos.map((item) => (
          <ResourceCard key={item.key} item={item} history={history} />
        ))}
      </Row>
    </div>
  );
}

export default ResourcesMain;
