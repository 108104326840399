import React from 'react';
import { Table, Divider, Row, Col } from 'antd';
import getPayMainRequestBody from '../../utils/request_object';
import { getLoanLabel } from '../../utils/loans';
import { getChandaLabel } from '../../utils/chandas';
import { getOrderLabel, getOrderPrice } from '../../utils/orders';
import {
  getSubscriptionLabel,
  getSubscriptionPrice,
} from '../../utils/subscriptions';
import { getVariableLabel, getSpecialValues } from '../../utils/variables';
import { formatNumber } from '../../utils/formats';

const getItemLabel = (name, category) => {
  if (category === 'Loan Payment') {
    return getLoanLabel(name);
  }
  if (category === 'Subscription') {
    return getSubscriptionLabel(name);
  }
  if (category === 'Order') {
    return getOrderLabel(name);
  }
  return getChandaLabel(name);
};

const mainTableColumns = [
  {
    title: 'Label',
    dataIndex: 'property',
    key: 'property',
  },
  {
    title: 'Details',
    dataIndex: 'value',
    key: 'value',
  },
];

const payersTableColumns = [
  {
    title: 'Donor',
    dataIndex: 'payer_name',
    key: 'payer_name',
  },
  {
    title: "Jama'at",
    dataIndex: 'jamaat',
    key: 'jamaat',
  },
  {
    title: 'Member Code',
    dataIndex: 'member_code',
    key: 'member_code',
  },
  {
    title: 'Subtotal',
    dataIndex: 'payerSubtotal',
    key: 'payerSubtotal',
    align: 'right',
    render: (text) => formatNumber(Number(text).toFixed(2)),
  },
];
const relativesPayersTableColumns = [
  {
    title: 'Donor',
    dataIndex: 'payer_name',
    key: 'payer_name',
  },
  {
    title: 'Member Code',
    dataIndex: 'member_code',
    key: 'member_code',
  },
  {
    title: 'Tahrik-e-Jadid',
    dataIndex: 'payerSubtotal',
    key: 'payerSubtotal',
    align: 'right',
    render: (text) => formatNumber(Number(text).toFixed(2)),
  },
];

const getFilteredProperties = (category) => {
  if (category === 'Subscription' || category === 'Order') {
    return [
      'payee_name',
      'donor_email',
      'payee_address',
      'payee_city',
      'payee_state',
      'payee_zip',
      'donor_phone',
      'comment',
    ];
  }
  return [
    'payee_name',
    'donor_email',
    'payee_address',
    'payee_city',
    'payee_state',
    'payee_zip',
    'donor_phone',
    'payee_payment_recurrence',
    'payee_payment_start_date',
    'payee_payment_recurrence_frequency',
    'payee_payment_end_date',
    'comment',
  ];
};

class ReviewPayment extends React.Component {
  getPayersTable = (payersDetails, category, subtotal, total, fees) => {
    const { subscriptionsAndOrders } = this.props;
    const payersTableData = [];
    for (let i = 0; i < payersDetails.length; i++) {
      const { payer_name, jamaat, member_code, ...items } = payersDetails[i];
      const details = [];
      let payerSubtotal = 0;
      for (let j = 0; j < Object.keys(items).length; j++) {
        const key = Object.keys(items)[j];
        let amount;
        let quantity;
        let frequency;
        if (category === 'Order') {
          quantity = items[key];
          amount = getOrderPrice(key) * items[key];
        } else if (category === 'Subscription') {
          frequency = subscriptionsAndOrders.frequency;
          amount = getSubscriptionPrice(key);
        } else {
          amount = items[key];
        }
        payerSubtotal += amount;
        details.push({
          key: j,
          name: getItemLabel(key, category),
          quantity,
          frequency,
          amount,
          category,
        });
      }
      payersTableData.push({
        key: i,
        payer_name,
        jamaat: i === 0 ? jamaat : payersDetails[0].jamaat,
        member_code,
        payerSubtotal,
        details,
        category,
      });
      console.log('payersTableData', payersTableData);
    }
    if (category === 'Chanda') {
      return (
        <Table
          size="small"
          columns={payersTableColumns}
          dataSource={payersTableData}
          expandedRowRender={(props) => this.getDetailedTable(props)}
          expandRowByClick
          pagination={false}
          footer={() => (
            <Row style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
              <Col>
                {fees > 0 && (
                  <>
                    <div>
                      <strong>Subtotal:</strong>
                    </div>
                    <div>
                      <strong>Fees:</strong>
                    </div>
                  </>
                )}
                <div>
                  <strong>Total:</strong>
                </div>
              </Col>
              <Col style={{ paddingLeft: 12 }}>
                {fees > 0 && (
                  <>
                    <div>
                      {`US$ ${formatNumber(Number(subtotal).toFixed(2))}`}
                    </div>
                    <div>{`US$ ${formatNumber(Number(fees).toFixed(2))}`}</div>
                  </>
                )}
                <div>{`US$ ${formatNumber(Number(total).toFixed(2))}`}</div>
              </Col>
            </Row>
          )}
        />
      );
    }
    if (category === 'Tehrik-e-Jadid') {
      return (
        <Table
          size="small"
          columns={relativesPayersTableColumns}
          dataSource={payersTableData}
          pagination={false}
          footer={() => (
            <Row style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
              <Col>
                {fees > 0 && (
                  <>
                    <div>
                      <strong>Subtotal:</strong>
                    </div>
                    <div>
                      <strong>Fees:</strong>
                    </div>
                  </>
                )}
                <div>
                  <strong>Total:</strong>
                </div>
              </Col>
              <Col style={{ paddingLeft: 12 }}>
                {fees > 0 && (
                  <>
                    <div>
                      {`US$ ${formatNumber(Number(subtotal).toFixed(2))}`}
                    </div>
                    <div>{`US$ ${formatNumber(Number(fees).toFixed(2))}`}</div>
                  </>
                )}
                <div>{`US$ ${formatNumber(Number(total).toFixed(2))}`}</div>
              </Col>
            </Row>
          )}
        />
      );
    }
    if (payersTableData.length > 0) {
      return this.getDetailedTable(
        payersTableData[0],
        true,
        subtotal,
        total,
        fees,
      );
    }
    return null;
  };

  getDetailedTable = (items, displayTotal, subtotal, total, fees) => {
    const { onSubscriptionsAndOrders, subscriptionsAndOrders } = this.props;
    let amountTitle = 'Amount';
    if (
      onSubscriptionsAndOrders &&
      subscriptionsAndOrders.category === 'subscriptions'
    ) {
      amountTitle = 'Price per year';
    }
    if (
      onSubscriptionsAndOrders &&
      subscriptionsAndOrders.category === 'orders'
    ) {
      amountTitle = 'Total price';
    }
    const columns = [
      {
        title: items.category,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: amountTitle,
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (text) => formatNumber(Number(text).toFixed(2)),
      },
    ].filter((x) => {
      if (items.category === 'Order') {
        return true;
      }
      if (items.category === 'Subscription') {
        return x.key !== 'quantity';
      }
      return x.key !== 'quantity';
    });

    return (
      <Table
        size="small"
        columns={columns}
        dataSource={items.details}
        pagination={false}
        footer={
          !displayTotal
            ? null
            : () => (
                <Row style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                  <Col>
                    {fees > 0 && (
                      <>
                        <div>
                          <strong>Subtotal:</strong>
                        </div>
                        <div>
                          <strong>Fees:</strong>
                        </div>
                      </>
                    )}
                    <div>
                      <strong>Total:</strong>
                    </div>
                  </Col>
                  <Col style={{ paddingLeft: 12 }}>
                    {fees > 0 && (
                      <>
                        <div>
                          {`US$ ${formatNumber(Number(subtotal).toFixed(2))}`}
                        </div>
                        <div>
                          {`US$ ${formatNumber(Number(fees).toFixed(2))}`}
                        </div>
                      </>
                    )}
                    <div>{`US$ ${formatNumber(Number(total).toFixed(2))}`}</div>
                  </Col>
                </Row>
              )
        }
      />
    );
  };

  render() {
    const {
      chandaPayers,
      loans,
      relatives_form,
      subscriptionsAndOrders,
      recurrence,
      identification,
      totalAmount,
      onSubscriptionsAndOrders,
      onLoans,
      onTJ,
    } = this.props;
    const body = getPayMainRequestBody(
      {
        chandaPayers,
        subscriptionsAndOrders,
        loans,
        relatives_form,
        totalAmount,
        recurrence,
        identification,
      },
      onSubscriptionsAndOrders,
      onLoans,
      onTJ,
      false,
    );

    let category;
    if (onLoans) {
      category = 'Loan Payment';
    } else if (onTJ) {
      category = 'Tehrik-e-Jadid';
    } else if (
      onSubscriptionsAndOrders &&
      subscriptionsAndOrders.category === 'subscriptions'
    ) {
      category = 'Subscription';
    } else if (
      onSubscriptionsAndOrders &&
      subscriptionsAndOrders.category === 'orders'
    ) {
      category = 'Order';
    } else {
      category = 'Chanda';
    }

    const data = [];
    const filteredBody = Object.keys(body).filter(
      (x) => getFilteredProperties(category).indexOf(x) > -1,
    );
    for (let i = 0; i < filteredBody.length; i++) {
      const key = filteredBody[i];
      if (key !== 'payersDetails') {
        if (body[key] !== undefined && body[key] !== '') {
          let newValue = body[key];
          if (typeof body[key] === 'boolean') {
            newValue = body[key] ? 'Yes' : 'No';
          }
          data.push({
            key: i,
            property: getVariableLabel(key),
            value: getSpecialValues(key, newValue),
          });
        }
      }
    }
    const {
      payersDetails,
      TOT: subtotal,
      payee_total_payment: total,
      payee_transaction_fee: fees,
    } = body;

    return (
      <div>
        <Divider>Information</Divider>
        <Table
          size="small"
          columns={mainTableColumns}
          dataSource={data}
          pagination={false}
        />
        <Divider>Details</Divider>
        {this.getPayersTable(payersDetails, category, subtotal, total, fees)}
      </div>
    );
  }
}

export default ReviewPayment;
