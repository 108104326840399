import React from 'react';
import { Modal } from 'antd';
import apiCall from './api';
import jamaatList from '../utils/jamaat';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_HOST_URL
    : 'http://localhost:8082';

const apiUrl = {
  memberValidation: {
    method: 'POST',
    path: `${baseUrl}/api/check-member`,
  },
  payChanda: {
    method: 'POST',
    path: `${baseUrl}/api/donation`,
  },
  getMetalsPrice: {
    method: 'GET',
    path: `${baseUrl}/api/metals-price`,
  },
  getRecurringDonationStatus: {
    method: 'GET',
    path: `${baseUrl}/api/recurring-donation`,
  },
  modifyRecurringDonationStatus: {
    method: 'POST',
    path: `${baseUrl}/api/recurring-donation`,
  },
  getPaymentStatus: {
    method: 'POST',
    path: `${baseUrl}/api/payment-status`,
  },
  contactUs: {
    method: 'POST',
    path: `${baseUrl}/api/contact-form`,
  },
  getMemberRelatives: {
    method: 'GET',
    path: `${baseUrl}/api/relatives`,
  },
  updateMemberRelatives: {
    method: 'PATCH',
    path: `${baseUrl}/api/relatives`,
  },
};

/**
 * @param {Object} body - Objects (members info) containing the following properties:
 * @property {string} name - Name
 * @property {string} jamaat - Jama'at (branch or chapter)
 * @property {string} member_code - Member's Code
 * @property {string} city - City
 * @property {string} state - State
 * @property {string} zipCode - Zip code
 * @property {Array} arrayOfMembers - Used to send mutiple times the member_code;
 */
export const verifyMember = function verifyMember({
  name,
  jamaat,
  member_code,
  city,
  state,
  zipCode,
  arrayOfMembers,
}) {
  return new Promise((resolve) => {
    const { path, method } = apiUrl.memberValidation;
    const body = {};
    if (arrayOfMembers) {
      body.members = arrayOfMembers.map((x) => ({
        ...x,
        jamaat: jamaatList.find((y) => y.value === x.jamaat)?.id,
      }));
    } else {
      body.members = [
        {
          name,
          jamaat: jamaatList.find((x) => x.value === jamaat)?.id,
          member_code,
          city,
          state,
          zipCode,
        },
      ];
    }
    body.members = body.members.filter((x) => x.member_code !== 999);
    if (body.members.length === 0) {
      return resolve(true);
    }
    return apiCall(method, path, body)
      .then((res) => {
        console.log(res);
        const response = res;
        if (res.score !== undefined) {
          if (res.score > 5) {
            response.status = 'success';
          } else if (res.score > 0) {
            response.status = 'warning';
            response.title = 'Please confirm';
            response.content = (
              <div>
                According to our Tajneed records, some of the information you
                entered does not match.
                <br />
                <br />
                {/* eslint-disable max-len */}
                If you believe the information is correct, please continue by
                clicking "Proceed with the donation".
              </div>
            );
          } else {
            response.status = 'error';
            response.title = 'Sorry, not able to process this donation';
            // eslint-disable-next-line react/jsx-one-expression-per-line
            response.content = (
              <div>
                Please contact National Finance Department (
                <a href="mailto:finance@ahmadiyya.us">finance@ahmadiyya.us</a>).
              </div>
            );
          }
        } else {
          response.status = 'error';
          response.title =
            'There was a problem validating your information, please check your data and try again later';
        }
        if (response.status === 'success') {
          resolve(true);
        } else if (response.status === 'error') {
          Modal.destroyAll();
          Modal.error({
            title: response.title,
            content: response.content,
            onOk: () => resolve(response.status === 'success'),
          });
        } else {
          Modal.destroyAll();
          Modal.confirm({
            title: response.title,
            content: response.content,
            onOk: () => resolve(false),
            okText: 'Let me fix it',
            onCancel: () => resolve(true),
            cancelText: 'Proceed with the donation',
            width: 500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Modal.destroyAll();
        Modal.error({
          title: "Something happened! We couldn't proceeded your validation",
          content: 'Please, try again later.',
          onOk: () => resolve(false),
          onCancel: () => resolve(false),
        });
      });
  });
};

/**
 * Retrieves the list of relatives for a member
 * @param {String} abi_code Member code
 * @param {String} pin 4 digit PIN code
 * @param {Boolean} onRegister Boolean if we are on the register page
 * @returns List of relatives
 */
export const getMemberRelatives = function getMemberRelatives(
  abi_code,
  pin,
  onRegister = false,
) {
  return new Promise((resolve) => {
    const { path, method } = apiUrl.getMemberRelatives;
    return apiCall(method, `${path}?ami_code=${abi_code}&pin=${pin}`)
      .then((res) => {
        console.log(res);
        if (
          !onRegister &&
          res.members.filter((x) => !!x.obo_code).length === 0
        ) {
          Modal.info({
            title: 'Sorry, not able to process this donation',
            content: (
              <div>
                You have recently registered relatives. Please wait until we
                validate the records and assign an OBO codes. For questions,
                contact National Finance Department (
                <a href="mailto:finance@ahmadiyya.us">finance@ahmadiyya.us</a>).
              </div>
            ),
            onOk: () => resolve(false),
            onCancel: () => resolve(false),
          });
        }
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        Modal.destroyAll();
        if (err && err.error) {
          if (onRegister && err.error.status === 404) {
            resolve(null);
          } else {
            Modal.error({
              title: err.error.message,
              content:
                'There was a problem validating your information, please check your data and try again',
              onOk: () => resolve(false),
              onCancel: () => resolve(false),
            });
          }
        } else {
          Modal.error({
            title: "Something happened! We couldn't proceeded your validation",
            content: 'Please, try again later.',
            onOk: () => resolve(false),
            onCancel: () => resolve(false),
          });
        }
      });
  });
};

export const updateMemberRelatives = function updateMemberRelatives(body) {
  return new Promise((resolve) => {
    const { path, method } = apiUrl.updateMemberRelatives;
    return apiCall(method, path, {
      id: body.id,
      ami_code: body.ami_code,
      branch: body.branch,
      email: body.email,
      pin: body.pin,
      members: body.members,
      firstTimeAddingRelatives: body.firstTimeAddingRelatives,
    })
      .then((res) => {
        console.log('updateMemberRelatives', res);
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        Modal.destroyAll();
        if (err && err.error) {
          Modal.error({
            title: err.error.message,
            content:
              'There was a problem validating your information, please check your data and try again',
            onOk: () => resolve(false),
            onCancel: () => resolve(false),
          });
        } else {
          Modal.error({
            title: "Something happened! We couldn't proceeded your validation",
            content: 'Please, try again later.',
            onOk: () => resolve(false),
            onCancel: () => resolve(false),
          });
        }
      });
  });
};

/**
 *
 * @param {object} body Contains all the values from forms:
 * - ChandasForm.js,
 * - RecurrenceForm.js and
 * - IdentificationForm.js
 *
 */
export const payChanda = function payChanda(body) {
  return new Promise((resolve, reject) => {
    const { path, method } = apiUrl.payChanda;
    apiCall(method, path, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * - Retrieves the recurring donation status in order for the user to make certain actions
 * - Possible actions depend on the status of the plan: cancel, pause or resume
 * @param {String} body.customer_token Recurrent Member ID
 * @param {String} body.reference_id Transaction ID
 */
export const getRecurringDonationStatus = function getRecurringDonationStatus({
  customer_token,
  reference_id,
}) {
  return new Promise((resolve, reject) => {
    const { path, method } = apiUrl.getRecurringDonationStatus;
    apiCall(method, `${path}/${reference_id || customer_token}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * - Modifies the recurring donation status
 * @param {String} reference_id Transaction ID
 * @param {String} action Action
 */
export const modifyRecurringDonationStatus =
  function modifyRecurringDonationStatus(reference_id, action) {
    return new Promise((resolve, reject) => {
      const { path, method } = apiUrl.modifyRecurringDonationStatus;
      apiCall(method, `${path}/${reference_id}`, { action })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

/**
 * - Gets the Payment Status from the server
 * @param {String} body.amiCode Member's Code
 * @param {String} body.email Email
 * @param {Boolean} body.includeFamily Send the record the the entire family unit
 * @param {Boolean} body.currentYtd Current Fiscal Year
 * @param {Boolean} body.prev_fy Previous Fiscal Year
 * @param {Boolean} body.current_cal Current Calendar Year
 * @param {Boolean} body.prev_cal Previous Calendar Year
 * @param {Boolean} body.tj Current Year Tehrik-e-Jadid
 * @param {Boolean} body.wj Current Year Waqf-e-Jadid
 * @param {Boolean} body.taxStatement 3-Year Tax Statement
 * @param {Boolean} body.tajnid Tajnid Statement
 */
export const getPaymentStatus = function getPaymentStatus(body) {
  return new Promise((resolve, reject) => {
    const { method, path } = apiUrl.getPaymentStatus;
    apiCall(method, path, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * - Sends the contact form to the server
 * @param {string} member_name Name
 * @param {string} branch Jama'at (branch or chapter)
 * @param {string} ami_code Member's Code
 * @param {string} email Email
 * @param {string} mobile Phone
 * @param {string} reference_number Transaction or Receipt Number
 * @param {string} message Custom message
 */
export const sendContactForm = function sendContactForm(
  member_name = '',
  branch = '',
  ami_code = '',
  email = '',
  mobile = '',
  reference_number = '',
  message = '',
) {
  return new Promise((resolve, reject) => {
    const { path, method } = apiUrl.contactUs;
    const body = {
      ami_code,
      member_name,
      email,
      branch,
      mobile,
      reference_number,
      message,
    };
    apiCall(method, path, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMetalsPrice = function getMetalsPrice() {
  return new Promise((resolve, reject) => {
    const { path, method } = apiUrl.getMetalsPrice;
    apiCall(method, path)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
