/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  Select,
  Divider,
  Radio,
  DatePicker,
  Form,
  InputNumber,
} from 'antd';
import moment from 'moment';
import {
  formVariables,
  getDaysFromTimeUnit,
  getScheduleQuantity,
  requiredMessages,
  validateRecurrenceDate,
} from '../../utils/form';

const { Option } = Select;

const labelCol = {
  xs: { span: 24 },
  style: {
    textAlign: 'center',
    whiteSpace: 'inherit',
    textOverflow: 'ellipsis',
  },
};

function RecurrenceForm({ handleSubmit, recurrence_form }) {
  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form: ', values);
        handleSubmit(values);
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const handleScheduleQuantity = () => {
    const schedule_quantity = getScheduleQuantity(
      form.getFieldValue(formVariables.payment_recurrence_startDate.property),
      form.getFieldValue(formVariables.payment_recurrence_endDate.property),
      form.getFieldValue(formVariables.payment_recurrence_frequency.property),
    );
    form.setFieldsValue({ schedule_quantity });
  };

  const payment_recurrence = formVariables.payment_recurrence.property;
  return (
    <Form form={form} layout="vertical" scrollToFirstError>
      <div align="center">
        <Divider>Recurrence plan details</Divider>
        <Form.Item
          labelCol={labelCol}
          name={payment_recurrence}
          label={formVariables.payment_recurrence.label}
          initialValue={recurrence_form[payment_recurrence] || 'oneTime'}
          rules={[
            { required: true, message: requiredMessages(payment_recurrence) },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="oneTime">Make one time payment</Radio.Button>
            <Radio.Button value="recurring">
              Make a recurring payment
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, current) =>
            prev[payment_recurrence] !== current[payment_recurrence]
          }
        >
          {() => {
            if (form.getFieldValue(payment_recurrence) === 'recurring') {
              return (
                <div>
                  <Form.Item
                    labelCol={labelCol}
                    name={formVariables.payment_recurrence_frequency.property}
                    label={formVariables.payment_recurrence_frequency.label}
                    initialValue={
                      recurrence_form[
                        formVariables.payment_recurrence_frequency.property
                      ] || 'monthly'
                    }
                    rules={[
                      {
                        required: true,
                        message: requiredMessages(
                          formVariables.payment_recurrence_frequency.property,
                        ),
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 150 }}
                      onChange={handleScheduleQuantity}
                    >
                      <Option value="weekly">Weekly</Option>
                      <Option value="bi_weekly">Biweekly</Option>
                      <Option value="semi_monthly">Twice-a-Month</Option>
                      <Option value="monthly">Monthly</Option>
                      <Option value="quarterly">Quarterly</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelCol={labelCol}
                    name={formVariables.payment_recurrence_startDate.property}
                    label={formVariables.payment_recurrence_startDate.label}
                    initialValue={
                      recurrence_form[
                        formVariables.payment_recurrence_startDate.property
                      ]
                        ? moment(
                            recurrence_form[
                              formVariables.payment_recurrence_startDate
                                .property
                            ],
                          )
                        : moment()
                    }
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        message: requiredMessages(
                          formVariables.payment_recurrence_startDate.property,
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      allowClear={false}
                      disabledDate={(current) =>
                        current && current < moment().startOf('day')
                      }
                      onChange={handleScheduleQuantity}
                    />
                  </Form.Item>
                  <Form.Item
                    labelCol={labelCol}
                    name={formVariables.payment_recurrence_expires.property}
                    label={formVariables.payment_recurrence_expires.label}
                    initialValue={
                      recurrence_form[
                        formVariables.payment_recurrence_expires.property
                      ] || false
                    }
                    rules={[
                      {
                        required: true,
                        message: requiredMessages(
                          formVariables.payment_recurrence_expires.property,
                        ),
                      },
                    ]}
                  >
                    <Radio.Group onChange={handleScheduleQuantity}>
                      <Radio value={false}>Until I cancel the plan</Radio>
                      <Radio value>Until a specific date</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    <Form.Item name="schedule_quantity" hidden>
                      <InputNumber />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      if (
                        form.getFieldValue(
                          formVariables.payment_recurrence_expires.property,
                        )
                      ) {
                        const schedule_quantity =
                          form.getFieldValue('schedule_quantity');
                        return (
                          <Form.Item
                            labelCol={labelCol}
                            name={
                              formVariables.payment_recurrence_endDate.property
                            }
                            label={
                              formVariables.payment_recurrence_endDate.label
                            }
                            initialValue={
                              recurrence_form[
                                formVariables.payment_recurrence_endDate
                                  .property
                              ]
                                ? moment(
                                    recurrence_form[
                                      formVariables.payment_recurrence_endDate
                                        .property
                                    ],
                                  )
                                : null
                            }
                            rules={[
                              {
                                type: 'object',
                                required: true,
                                message: requiredMessages(
                                  formVariables.payment_recurrence_endDate
                                    .property,
                                ),
                              },
                              {
                                validator: (rules, values, callback) =>
                                  callback(
                                    validateRecurrenceDate(
                                      form.getFieldValue(
                                        formVariables
                                          .payment_recurrence_startDate
                                          .property,
                                      ),
                                      values,
                                      form.getFieldValue(
                                        formVariables
                                          .payment_recurrence_frequency
                                          .property,
                                      ),
                                    ),
                                  ),
                              },
                            ]}
                            extra={
                              schedule_quantity > 0 &&
                              form.getFieldValue(
                                formVariables.payment_recurrence_endDate
                                  .property,
                              ) ? (
                                <div>
                                  <div>
                                    {`${schedule_quantity} period${
                                      schedule_quantity === 1 ? '' : 's'
                                    }`}
                                  </div>
                                  {Array(schedule_quantity)
                                    .fill(0)
                                    .map((x, i) => {
                                      const startDate = moment(
                                        form.getFieldValue(
                                          formVariables
                                            .payment_recurrence_startDate
                                            .property,
                                        ),
                                      );
                                      const frequency = form.getFieldValue(
                                        formVariables
                                          .payment_recurrence_frequency
                                          .property,
                                      );
                                      let paymentDate = moment(startDate)
                                        .add(
                                          i *
                                            getDaysFromTimeUnit(
                                              form.getFieldValue(
                                                formVariables
                                                  .payment_recurrence_frequency
                                                  .property,
                                              ),
                                            ),
                                          'days',
                                        )
                                        .format('MM/DD/YYYY');
                                      if (
                                        [
                                          'weekly',
                                          'monthly',
                                          'annually',
                                        ].indexOf(frequency) > -1
                                      ) {
                                        // eslint-disable-next-line no-nested-ternary
                                        const format =
                                          frequency === 'weekly'
                                            ? 'weeks'
                                            : frequency === 'monthly'
                                            ? 'months'
                                            : 'years';
                                        paymentDate = moment(startDate)
                                          .add(i, format)
                                          .format('MM/DD/YYYY');
                                      }
                                      return (
                                        <div key={paymentDate}>
                                          {`#${i + 1}: ${paymentDate}`}
                                        </div>
                                      );
                                    })}
                                </div>
                              ) : (
                                ''
                              )
                            }
                          >
                            <DatePicker
                              format="MM/DD/YYYY"
                              placeholder="MM/DD/YYYY"
                              allowClear={false}
                              disabledDate={(current) =>
                                current && current < moment().startOf('day')
                              }
                              onChange={handleScheduleQuantity}
                              showToday={false}
                            />
                          </Form.Item>
                        );
                      }
                      return null;
                    }}
                  </Form.Item>
                </div>
              );
            }
            return null;
          }}
        </Form.Item>
      </div>
      <div align="right">
        <Button htmlType="submit" type="primary" onClick={handleCreate}>
          Next
        </Button>
      </div>
    </Form>
  );
}

export default RecurrenceForm;
