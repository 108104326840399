/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Form,
  Row,
  Col,
  Alert,
} from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import {
  getRecurringDonationStatus,
  modifyRecurringDonationStatus,
} from '../../services/actions';
import { formatNumber } from '../../utils/formats';

const styles = {
  spreadItems: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    // padding: '0px 25px 25px',
  },
};

const formItemLayout = {
  style: {
    padding: '8px 0px',
  },
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 },
    lg: { span: 9 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 10 },
    xl: { span: 6 },
    xxl: { span: 4 },
  },
};

// eslint-disable-next-line arrow-body-style
const getPlanStatusColor = (status) => {
  return status === 'active'
    ? 'blue'
    : status === 'suspended'
    ? 'orange'
    : status === 'deleted'
    ? 'red'
    : undefined;
};

const ModifyRecurringStatusMain = () => {
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(null);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [response, setResponse] = useState({});
  const [form] = Form.useForm();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const value = query.get('reference_id');
    if (value) {
      form.setFieldsValue({ reference_id: value });
    }
    return () => {};
  }, [search]);

  const verifyRecaptcha = (success) => {
    if (success) {
      setCaptchaValidated(true);
    } else {
      setCaptchaValidated(false);
    }
  };

  const parseStatusRes = ({
    customerId,
    referenceId,
    scheduleAmount,
    scheduleStartDate,
    scheduleEndDate,
    status,
  }) => {
    const res = {
      customerId,
      referenceId,
      scheduleAmount,
      scheduleStartDate,
      scheduleEndDate,
      status,
    };
    if (status.status === 'active') {
      res.actions = [
        { label: 'Pause Plan', value: 'pause' },
        { label: 'Cancel Plan', value: 'cancel' },
      ];
    } else if (status.status === 'suspended') {
      res.actions = [
        { label: 'Resume Plan', value: 'resume' },
        { label: 'Cancel Plan', value: 'cancel' },
      ];
    } else {
      res.actions = [];
    }
    res.key = referenceId;
    return res;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Received values of form: ', values);
        const { reference_id } = values;
        if (!reference_id) {
          return Modal.warning({
            title: 'Please, enter your Transaction ID',
            content: 'You may find this information on your donation receipt.',
          });
        }
        setLoading(true);
        return getRecurringDonationStatus({ reference_id })
          .then((res) => {
            setLoading(false);
            return setPlan(parseStatusRes(res));
          })
          .catch((err) => {
            form.resetFields();
            setLoading(false);
            if (err.response && err.response.status === 404) {
              // Handle 404 error
              Modal.error({
                title: 'Invalid reference',
                content: 'The reference id is not a recurring donation.',
              });
            } else {
              Modal.error({
                title: 'Something went wrong',
                content: err.error
                  ? err.error.message
                  : 'Please, reload the site and try again.',
              });
            }
          });
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const handlePlanAction = (action) => {
    if (!plan.referenceId) {
      message.destroy();
      return message.error('Something went wrong. Please, try again.');
    }
    return Modal.confirm({
      title: `Do you want to ${action} your plan?`,
      onOk: () =>
        new Promise((resolve) => {
          modifyRecurringDonationStatus(plan.referenceId, action)
            .then((res) => {
              setPlan(parseStatusRes(res));
              setResponse({
                message: 'Your modification was submitted',
                description:
                  action === 'resume'
                    ? 'Your request to resume your plan was submitted successfully.'
                    : `
                  We have submitted this ${action} request to the vendor. 
                  If you get charged for this cycle, let us know and we'll make a refund.
                `,
              });
              resolve();
            })
            .catch(() => {
              form.resetFields();
              setPlan(null);
              setResponse(null);
              resolve();
              Modal.error({
                title: 'Something went wrong',
                content: 'Please, reload the site and try again.',
              });
            });
        }),
    });
  };

  const resetForm = () => {
    setPlan(null);
    setResponse(null);
  };

  return (
    <div style={styles.mainContainer}>
      {plan ? (
        <>
          <h1 align="center">Modify Your Recurring Donation Status</h1>
          <h3 align="center">We've found your recurring donation plan.</h3>
          {plan.status.status === 'deleted' ? (
            <h4 align="center">
              You have previously deleted your recurring plan. In case you want
              to renew it, you will have to create a new one again.
            </h4>
          ) : plan.status.status === 'completed' ? (
            <h4 align="center">Your recurring plan has already finished.</h4>
          ) : null}
          <Table
            pagination={false}
            dataSource={[plan]}
            bordered
            scroll={{ x: 'auto' }}
            columns={[
              {
                title: 'Reference ID',
                dataIndex: 'referenceId',
                key: 'referenceId',
                align: 'center',
              },
              {
                title: 'Plan Amount',
                dataIndex: 'scheduleAmount',
                key: 'scheduleAmount',
                align: 'center',
                render: (text) => `$ ${formatNumber(Number(text).toFixed(2))}`,
              },
              {
                title: 'Plan Start Date',
                dataIndex: 'scheduleStartDate',
                key: 'scheduleStartDate',
                align: 'center',
              },
              {
                title: 'Plan End Date',
                dataIndex: 'scheduleEndDate',
                key: 'scheduleEndDate',
                align: 'center',
              },
              {
                title: 'Plan Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: ({ status }) => (
                  <Tag color={getPlanStatusColor(status)}>
                    {status.toUpperCase()}
                  </Tag>
                ),
              },
              {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (actions) => (
                  <Space direction="vertical">
                    {actions.map(({ value, label }) => (
                      <Button
                        type={
                          value === 'cancel'
                            ? 'danger'
                            : value === 'resume'
                            ? 'primary'
                            : 'default'
                        }
                        ghost={value === 'cancel'}
                        key={value}
                        onClick={() => handlePlanAction(value)}
                        block
                      >
                        {label}
                      </Button>
                    ))}
                  </Space>
                ),
              },
            ].slice(
              0,
              ['deleted', 'completed'].indexOf(plan.status.status) === -1
                ? 6
                : 5,
            )}
          />
          {response && response.message && (
            <Alert
              message={response.message}
              description={response.description}
              type="success"
              showIcon
              style={{ marginTop: 24 }}
            />
          )}
          <div align="center" style={{ paddingTop: 12 }}>
            <Button onClick={resetForm} type="link">
              Go back
            </Button>
          </div>
        </>
      ) : (
        <div style={styles.mainContainer}>
          <h1 align="center">Modify Your Recurring Donation Status</h1>
          <h3 style={{ textAlign: 'center' }}>
            Please provide any Transaction ID associated with your recurring
            donation (found on your receipt).
          </h3>
          <Form
            form={form}
            style={formItemLayout.style}
            labelCol={formItemLayout.labelCol}
            wrapperCol={formItemLayout.wrapperCol}
            scrollToFirstError
          >
            <Form.Item
              name="reference_id"
              label="Transaction ID"
              wrapperCol={formItemLayout.wrapperCol}
              rules={[]}
            >
              <Input disabled={loading} autoComplete="off" />
            </Form.Item>
            <div
              id="google-recaptcha-wrapper"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                onChange={verifyRecaptcha}
              />
            </div>
            <Row
              type="flex"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Col style={{ padding: '8px 0px' }}>
                <Button
                  htmlType="submit"
                  loading={loading}
                  disabled={!captchaValidated}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ModifyRecurringStatusMain;
