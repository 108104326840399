/* eslint-disable max-len */
import React from 'react';
import { Table } from 'antd';

const styles = {
  reference: {
    color: '#949090',
  },
};

export default function ZakatCalculatorFAQ() {
  const columns = [
    {
      title: 'Variable',
      dataIndex: 'variable',
      key: 'variable',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
  ];

  const data = [
    { variable: 'Rate of Zakat', rate: '2.5%' },
    {
      variable: 'Gold Price (22k)',
      rate: (
        <div>
          Sourced daily for 22k Gold from{' '}
          <a
            href="https://metals.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Metals.Dev
          </a>
        </div>
      ),
    },
    {
      variable: 'Silver Price (22k)',
      rate: (
        <div>
          Sourced daily for 22k Silver from{' '}
          <a
            href="https://metals.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Metals.Dev
          </a>
        </div>
      ),
    },
    { variable: 'Gold Nisab', rate: '7.5 Tolas' },
    { variable: 'Silver Nisab', rate: '52.5 Tolas' },
    { variable: 'Grams per Tola', rate: '11.7' },
  ];
  return (
    <div>
      <h1 align="center">Zakat Calculator</h1>
      <h3 align="center">Guidance & Calculation Notes</h3>

      <h2>Definition of Zakat</h2>
      <p>The Promised Messiah (as) says:</p>
      <p>
        "What is Zakat? It is taken from the rich and given to the poor. It
        teaches the highest level of human sympathy. Thus by coming together of
        the rich and the poor, the Muslims gain strength. It is a must for the
        rich to help the poor, and even if it were not so, it is a demand of
        human sympathy to do so. But nowadays, I see that people care the least
        even if their neighbour is starving to death, so engrossed are they with
        their own comfort and enjoyment. I can’t hold back what Allah has told
        me. Sympathy is a very precious gem that a man has. Allah Almighty says:
      </p>
      <p style={{ fontWeight: 'bold' }}>
        "You cannot achieve virtue until you spend out of that which you love."
      </p>
      <p>
        This is not the Way to please God, for instance a Hindu's cow becomes
        ill and he presents it as Mans. There are many who give to the poor
        rotten crumbs which are of no use to anyone, and they imagine that they
        have given charity. Allah does not accept such things, nor is such
        charity acceptable. He clearly says that you cannot achieve virtue until
        you spend out of that which you love. Virtue cannot be called virtue
        until you spend for the propagation of the faith and the sympathy for
        mankind out of your possessions which you love."
      </p>
      <p style={styles.reference}>
        (Introduction to Financial Sacrifice, Page 81)
      </p>
      <br />
      <h2>Calculation Principles</h2>
      <p>
        "Zakat is not levied on all the items as a whole, rather it is levied on
        each item separately. Whenever an item is equal to or more than the
        Nisab, 52.5 Tolas of Silver (614.25 grams), Zakat will be levied on it.
      </p>
      <p>
        Zakat will be levied on the total amount. It is not sufficient to pay
        Zakat on that which exceeds the Nisab. For instance, if someone has 55
        Tolas of Silver, he shall pay Zakat on 55 Tolas and not just on 2.5
        Tolas [= 55 - 52.5]."
      </p>
      <p style={styles.reference}>
        (Introduction to Financial Sacrifice, Page 88)
      </p>
      <p>
        "The Nisab for silver and the Nisab for gold will be the same as was
        prescribed by the Holy Prophet (saw) himself in his own time. As far as
        the Nisab for cash is concerned most of the world has now adopted gold
        as a monetary standard, therefore, the Nisab of gold will be considered
        as standard for Zakat on cash."
      </p>
      <p style={styles.reference}>
        (Letter to Sadr Majlis Ifta'a, Dated 25th May 2014 as quoted in
        Ahmadiyya Bulletin, March 2016, Page 38)
      </p>
      <br />
      <strong>Gold</strong>
      <p>
        If the amount preserved is equal to or greater than the Nisab, then
        Zakat will become payable upon it after the completion of one year
      </p>
      <p>
        The Promised Messiah (as) says: "According to some people’s Zakat is not
        payable on gold and silver which is worn and is sometimes given to poor
        women for use. And it is perfected that Zakat should be paid on
        jewellery which is worn but is not given to the poor. This is the
        practice of my family also. They pay Zakat on their jewellery every
        year. Furthermore, there is no disagreement about the payment of Zakat
        on jewellery which is kept safe like money."
      </p>
      <p style={styles.reference}>
        (An Introduction to Financial Sacrifice, Page 82)
      </p>
      <strong>Silver</strong>
      <p>
        If the amount preserved is equal to or greater than the Nisab, then
        Zakat will become payable upon it after the completion of one year.
      </p>
      <strong>Cash</strong>
      <p>
        If the amount preserved is equal to or greater than the Nisab, then
        Zakat will become payable upon it after the completion of one year.
      </p>
      <strong>Rental Income</strong>
      <p>
        In response to a query by a person, the Promised Messiah (as) said: "No
        matter what the value of a house, Zakat is not payable on it. But if you
        have given it on rent, the Zakat will be payable on the rental income
        you receive."
      </p>
      <p style={styles.reference}>
        (An Introduction to Financial Sacrifice, Page 83)
      </p>
      <p>
        For simplicity, the Zakat Calculator does not separately ask for Rental
        Income. To include Rental Income as part of your Zakat Calculation,
        please add it onto the amount you enter in the box for the "Cash" amount
        preserved for 1 year.
      </p>
      <br />
      <h2>Rates underlying Zakat Calculation</h2>
      <Table
        rowKey={(x) => x.variable}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
}
