/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Divider,
  AutoComplete,
  Radio,
  Modal,
  Checkbox,
  Form,
} from 'antd';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import { USA_cities_asArray, USA_states_asArray } from '../../utils/USA_states';
import { formVariables, customValidations } from '../../utils/form';
import {
  getTransactionFee,
  getTransactionFeeFormula,
} from '../../utils/transaction_rates';
import card_gateway from '../../utils/card_gateway';
import { formatNumber } from '../../utils/formats';
import routes from '../../routes';
import helpBankAccountImg from '../../assets/images/help_bank_account.png';
import helpCVVImg from '../../assets/images/help_cvv.png';
import getEmailSuggestions from '../../utils/email_suggestions';
import PaymentCostModal from '../../components/PaymentCostModal';
import getBankName from '../../services/routingNumbers';
import isValidRoutingNumber from '../../utils/routing_number_validator';

const labelCol = {
  xs: { span: 24 },
  sm: { span: 20, offset: 2 },
  md: { span: 9, offset: 0 },
  lg: { span: 8 },
  xl: { span: 9 },
};

const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 20, offset: 2 },
  md: { span: 12, offset: 0 },
  lg: { span: 10 },
  xl: { span: 8 },
  xxlg: { span: 6 },
};

const paymentTypeWrapperCol = {
  xs: { span: 24 },
  sm: { span: 22, offset: 2 },
  md: { span: 15, offset: 0 },
  lg: { span: 16 },
  xl: { span: 15 },
};

const checkboxesWrapperCol = {
  md: { span: 24 },
  lg: { span: 16, offset: 8 },
  xl: { span: 10 },
  xxlg: { span: 8 },
};

const showBankAccountHelp = () => {
  Modal.info({
    title: 'Where to get the information of my bank account?',
    content: (
      <div style={{ paddingTop: 12 }}>
        <img alt="" src={helpBankAccountImg} style={{ width: '100%' }} />
        <p>Also usually available in your online banking website.</p>
      </div>
    ),
  });
};

const showCVVHelp = () => {
  Modal.info({
    title: 'Where to get the CVV of my debit/credit card?',
    content: (
      <div style={{ paddingTop: 12 }}>
        <img alt="" src={helpCVVImg} style={{ width: '100%' }} />
      </div>
    ),
  });
};

const filterAutocomplete = (inputValue, option, min = 0) => {
  if (!inputValue || inputValue.length < min) return false;
  return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
};

function IdentificationForm({
  handleSubmit,
  identification_form,
  totalAmount,
}) {
  const [bankInfo, setBankInfo] = useState('');
  const [emailSuggestion, setEmailSuggesetion] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      const {
        update_jamaat_address,
        apply_address_to_family,
        asume_processing_fee,
      } = identification_form;
      form.setFieldsValue({
        update_jamaat_address,
        apply_address_to_family,
        asume_processing_fee,
      });
    }, 500);
    return () => {};
  }, []);

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        // Max transaction limits validations
        const maxACH = 75000;
        const maxCard = 15000;
        // eslint-disable-next-line max-len
        const transactionFee = getTransactionFee(
          totalAmount,
          values.payment_type,
          values[formVariables.card_gateway_brand.property],
        );
        const total_payment = form.getFieldValue('asume_processing_fee')
          ? totalAmount + transactionFee
          : totalAmount;
        if (values.payment_type === 'ach' && total_payment > maxACH) {
          Modal.warning({
            title: `Maximum donation allowed through ACH is $ ${formatNumber(
              maxACH,
            )}`,
            content:
              'Please change your donation amount. You can split your donation into multiple donations under this limit.',
          });
        } else if (values.payment_type !== 'ach' && total_payment > maxCard) {
          Modal.warning({
            title: `Maximum donation allowed through ${
              values.payment_type
            } is $ ${formatNumber(maxCard)}`,
            content:
              'Please change your donation amount or change your payment method to ACH. You can split your donation into multiple donations under this limit.',
          });
        } else {
          handleSubmit(values);
        }
      })
      .catch((err) => {
        console.log('Form errors', err);
      });
  };

  const onEmailBlur = (e) => {
    if (e.target.value) {
      const newEmailSuggestion = getEmailSuggestions(e.target.value);
      setEmailSuggesetion(newEmailSuggestion);
      return;
    }
    setEmailSuggesetion('');
  };

  const changeEmailForSuggestion = (email) => {
    form.setFieldsValue({ email });
    setEmailSuggesetion('');
  };

  const handleCreditCardGatewayIcon = (card_gateway_brand) => {
    form.setFieldsValue({ card_gateway_brand });
  };

  const handleBankInfo = (clear) => {
    if (clear) {
      if (bankInfo) {
        setBankInfo(null);
      }
    } else {
      const routing_number = form.getFieldValue(
        formVariables.routing_number.property,
      );
      if (!routing_number) {
        setBankInfo('');
      } else if (
        !(bankInfo && bankInfo.rn === routing_number) &&
        isValidRoutingNumber(routing_number)
      ) {
        getBankName(routing_number)
          .then((res) => {
            console.log('getBankName success:', res);
            setBankInfo(res);
          })
          .catch((err) => {
            console.log('getBankName error:', err);
            setBankInfo('');
          });
      }
    }
  };

  const payment_type_property = formVariables.payment_type.property;
  return (
    <div>
      <Form
        form={form}
        wrapperCol={wrapperCol}
        labelCol={labelCol}
        scrollToFirstError
      >
        <Divider>Billing Details</Divider>
        <Form.Item
          name={formVariables.firstName.property}
          label={formVariables.firstName.label}
          initialValue={identification_form[formVariables.firstName.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={formVariables.lastName.property}
          label={formVariables.lastName.label}
          initialValue={identification_form[formVariables.lastName.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={formVariables.email.label}
          name={formVariables.email.property}
          extra={
            emailSuggestion && (
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => changeEmailForSuggestion(emailSuggestion)}
              >
                {`Did you mean ${emailSuggestion}`}
              </Button>
            )
          }
          initialValue={identification_form[formVariables.email.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Input onBlur={onEmailBlur} />
        </Form.Item>
        <Form.Item
          name={formVariables.address.property}
          label={formVariables.address.label}
          initialValue={identification_form[formVariables.address.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={formVariables.city.property}
          label={formVariables.city.label}
          initialValue={identification_form[formVariables.city.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <AutoComplete
            options={USA_cities_asArray}
            filterOption={(inputValue, option) =>
              filterAutocomplete(inputValue, option, 3)
            }
          >
            <Input name="city" />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          name={formVariables.state.property}
          label={formVariables.state.label}
          initialValue={identification_form[formVariables.state.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <select
            className="ant-input select__state"
            name={formVariables.state.property}
          >
            <option />
            {USA_states_asArray.map((x) => (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            ))}
          </select>
        </Form.Item>
        <Form.Item
          name={formVariables.zipCode.property}
          label={formVariables.zipCode.label}
          initialValue={identification_form[formVariables.zipCode.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={formVariables.phone.property}
          label={formVariables.phone.label}
          initialValue={identification_form[formVariables.phone.property]}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Cleave
            className="ant-input"
            options={{ phone: true, phoneRegionCode: 'US' }}
          />
        </Form.Item>
        <Form.Item
          labelCol={labelCol}
          wrapperCol={paymentTypeWrapperCol}
          name={payment_type_property}
          label={formVariables.payment_type.label}
          extra={
            <Form.Item
              noStyle
              shouldUpdate={(prev, current) =>
                prev[payment_type_property] !== current[payment_type_property]
              }
            >
              <PaymentCostModal
                paymentType={form.getFieldValue(payment_type_property)}
                totalAmount={totalAmount}
              />
            </Form.Item>
          }
          initialValue={identification_form[payment_type_property] || 'ach'}
          rules={[{ required: true, validator: customValidations }]}
        >
          <Radio.Group style={{ display: 'flex', alignItems: 'stretch' }}>
            <Radio.Button
              value="ach"
              style={{
                minHeight: 75,
                display: 'flex',
                alignItems: 'center',
                padding: 12,
              }}
            >
              <div align="center" style={{ display: 'inline-block' }}>
                <div style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 16 }}>
                  Bank Account
                </div>
                <h5 style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: 0 }}>
                  Best Option
                </h5>
              </div>
            </Radio.Button>
            <Radio.Button
              value="debit"
              style={{
                minHeight: 75,
                display: 'flex',
                alignItems: 'center',
                padding: 12,
              }}
            >
              <div align="center" style={{ display: 'inline-block' }}>
                <div style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 16 }}>
                  Debit Card
                </div>
                <h5 style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: 0 }}>
                  Good Option
                </h5>
              </div>
            </Radio.Button>
            <Radio.Button
              value="credit"
              style={{
                minHeight: 75,
                display: 'flex',
                alignItems: 'center',
                padding: 12,
              }}
            >
              <div align="center" style={{ display: 'inline-block' }}>
                <div style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 16 }}>
                  Credit Card
                </div>
                <h5 style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: 0 }}>
                  Ok!
                </h5>
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev[payment_type_property] !== current[payment_type_property]
          }
        >
          {() => {
            const payment_type = form.getFieldValue(payment_type_property);
            console.log('IdentificationForm.js | payment_type', payment_type);
            if (payment_type === 'ach') {
              return (
                <>
                  <Divider>Bank Account</Divider>
                  <Form.Item
                    name={formVariables.bank_account_type.property}
                    label={formVariables.bank_account_type.label}
                    initialValue={
                      identification_form[
                        formVariables.bank_account_type.property
                      ]
                    }
                    rules={[{ required: true, validator: customValidations }]}
                  >
                    <Radio.Group>
                      <Radio value="c">Checking</Radio>
                      <Radio value="s">Savings</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={formVariables.routing_number.label}
                    extra={
                      bankInfo ? (
                        bankInfo.customer_name
                      ) : (
                        <Button
                          onClick={showBankAccountHelp}
                          style={{ padding: 0, height: 24, fontSize: 14 }}
                          type="link"
                        >
                          Where can I find my Routing Number?
                        </Button>
                      )
                    }
                    name={formVariables.routing_number.property}
                    initialValue={
                      identification_form[formVariables.routing_number.property]
                    }
                    rules={[{ required: true, validator: customValidations }]}
                  >
                    <Input
                      onBlur={() => handleBankInfo(false)}
                      onChange={() => handleBankInfo(true)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={formVariables.bank_account_number.label}
                    extra={
                      <Button
                        onClick={showBankAccountHelp}
                        style={{ padding: 0, height: 24, fontSize: 14 }}
                        type="link"
                      >
                        Where can I find my Account Number?
                      </Button>
                    }
                    name={formVariables.bank_account_number.property}
                    initialValue={
                      identification_form[
                        formVariables.bank_account_number.property
                      ]
                    }
                    rules={[{ required: true, validator: customValidations }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={formVariables.bank_account_number_2.label}
                    name={formVariables.bank_account_number_2.property}
                    initialValue={
                      identification_form[
                        formVariables.bank_account_number_2.property
                      ]
                    }
                    rules={[
                      {
                        required: true,
                        validator: (rules, values, callback) =>
                          customValidations(rules, values, callback, {
                            bank_account_number: form.getFieldValue(
                              'bank_account_number',
                            ),
                          }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              );
            }
            if (['credit', 'debit'].indexOf(payment_type) > -1) {
              return (
                <>
                  <Divider>
                    {payment_type === 'credit' ? 'Credit' : 'Debit'} Card
                  </Divider>
                  <Form.Item
                    noStyle
                    hidden
                    name={formVariables.card_gateway_brand.property}
                    initialValue={identification_form.card_gateway_brand}
                  >
                    <Input style={{ display: 'none' }} />
                  </Form.Item>
                  <Form.Item
                    label={
                      payment_type === 'credit'
                        ? formVariables.credit_card_number.label
                        : formVariables.debit_card_number.label
                    }
                  >
                    <div style={{ width: 212 }}>
                      <Form.Item
                        noStyle
                        name={
                          payment_type === 'credit'
                            ? formVariables.credit_card_number.property
                            : formVariables.debit_card_number.property
                        }
                        initialValue={
                          identification_form[
                            payment_type === 'credit'
                              ? formVariables.credit_card_number.property
                              : formVariables.debit_card_number.property
                          ]
                        }
                        rules={[
                          { required: true, validator: customValidations },
                        ]}
                      >
                        <Cleave
                          className="ant-input"
                          options={{
                            creditCard: true,
                            prefix: 'PREFIX',
                            onCreditCardTypeChanged:
                              handleCreditCardGatewayIcon,
                          }}
                        />
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate>
                        {() => (
                          <div style={{ height: 0, textAlign: 'right' }}>
                            <img
                              src={
                                card_gateway[
                                  form.getFieldValue(
                                    formVariables.card_gateway_brand.property,
                                  )
                                ] || card_gateway.placeholder
                              }
                              alt="Card Gateway"
                              width={30}
                              style={{
                                position: 'relative',
                                top: -33,
                                right: 8,
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={formVariables.expiry_date.label}
                    name={formVariables.expiry_date.property}
                    initialValue={
                      identification_form[formVariables.expiry_date.property]
                    }
                    rules={[{ required: true, validator: customValidations }]}
                  >
                    <Cleave
                      className="ant-input"
                      options={{ date: true, datePattern: ['m', 'Y'] }}
                      placeholder="MM/YYYY"
                      style={{ width: 90 }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={formVariables.cvv.label}
                    extra={
                      <Button
                        onClick={showCVVHelp}
                        style={{ padding: 0, height: 24, fontSize: 14 }}
                        type="link"
                      >
                        Where can I find my CVV?
                      </Button>
                    }
                    name={formVariables.cvv.property}
                    initialValue={
                      identification_form[formVariables.cvv.property]
                    }
                    rules={[{ required: true, validator: customValidations }]}
                  >
                    <Input style={{ width: 90 }} />
                  </Form.Item>
                </>
              );
            }
            return null;
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const payment_type =
              form.getFieldValue(payment_type_property) ||
              identification_form[payment_type_property] ||
              'ach';
            const card_gateway_brand =
              form.getFieldValue(formVariables.card_gateway_brand.property) ||
              identification_form.card_gateway_brand;
            const transactionFee = getTransactionFee(
              totalAmount,
              payment_type,
              card_gateway_brand,
            );
            const transactionFeeFormula = getTransactionFeeFormula(
              payment_type,
              card_gateway_brand,
            );
            const total_payment = form.getFieldValue('asume_processing_fee')
              ? totalAmount + transactionFee
              : totalAmount;
            return (
              <>
                <Form.Item wrapperCol={checkboxesWrapperCol}>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Form.Item
                      name={formVariables.asume_processing_fee.property}
                      initialValue={identification_form.asume_processing_fee}
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                    <div style={{ lineHeight: '20px', paddingLeft: 12 }}>
                      {formVariables.asume_processing_fee.label}
                      {transactionFee ? (
                        <>
                          {(payment_type !== 'credit' ||
                            card_gateway_brand) && (
                            <div>
                              Total fee: US${' '}
                              {formatNumber(Number(transactionFee).toFixed(2))}
                              {payment_type === 'ach'
                                ? null
                                : ` (${transactionFeeFormula})`}
                            </div>
                          )}
                          {payment_type === 'credit' && card_gateway_brand && (
                            <ul>
                              <li>
                                If you use debit card: US$
                                {formatNumber(
                                  Number(
                                    getTransactionFee(totalAmount, 'debit'),
                                  ).toFixed(2),
                                )}{' '}
                                (We could save US$
                                {Math.round(
                                  (transactionFee -
                                    getTransactionFee(totalAmount, 'debit')) *
                                    100,
                                ) / 100}
                                )
                              </li>
                              <li>
                                If you use bank transfer: US$
                                {formatNumber(
                                  Number(
                                    getTransactionFee(totalAmount, 'ach'),
                                  ).toFixed(2),
                                )}{' '}
                                (We could save US$
                                {Math.round(
                                  (transactionFee -
                                    getTransactionFee(totalAmount, 'ach')) *
                                    100,
                                ) / 100}
                                )
                              </li>
                            </ul>
                          )}
                          {payment_type === 'debit' && (
                            <ul>
                              <li>
                                If you use bank transfer: US$
                                {formatNumber(
                                  Number(
                                    getTransactionFee(totalAmount, 'ach'),
                                  ).toFixed(2),
                                )}{' '}
                                (We could save US$
                                {Math.round(
                                  (transactionFee -
                                    getTransactionFee(totalAmount, 'ach')) *
                                    100,
                                ) / 100}
                                )
                              </li>
                            </ul>
                          )}
                        </>
                      ) : (
                        <div>
                          Total fee: US${' '}
                          {formatNumber(Number(transactionFee).toFixed(2))}
                        </div>
                      )}
                    </div>
                  </div>
                </Form.Item>
                <h2 align="center">
                  Total donation: US${' '}
                  {formatNumber(Number(total_payment).toFixed(2))}
                </h2>
              </>
            );
          }}
        </Form.Item>
        <div align="center">
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleCreate}
            size="large"
          >
            Next
          </Button>
        </div>
      </Form>
      <div style={{ fontSize: 12, paddingTop: 32, textAlign: 'center' }}>
        {/* eslint-disable max-len */}
        <div>
          - Ahmadiyya Movement In Islam does not receive or store your bank /
          debit / credit card info. All payments are processed through Forte
          Payment Systems.
        </div>
        <div>
          - Recurring payments can be paused or canceled using the self-service
          form{' '}
          <a
            href={routes.modify_recurring_donation}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </div>
      </div>
    </div>
  );
}

export default IdentificationForm;
